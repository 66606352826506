import React from "react";

// Customizable Area Start

import { Link } from 'react-router-dom';
import {
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
    OutlinedInput,
    FormControlLabel,
   
    Box
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { imgLingoamo, imgMale, imgError, imgStudent, eyeClose, eyeOpen } from "./assets";
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";

// Customizable Area End

import TeacherSignupController, {
    Props,
} from "./TeacherSignupController";

export class TeacherSignupBlock extends TeacherSignupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showAlertBlock = (isResend:any) => {
        if(isResend){
            const {isResendEmail} = this.state
            return (
                <Box style={{
                    background: isResendEmail? "rgba(220, 251, 207, 1)": "#ffbdcb",
                    padding: 9,
                    textAlign: "center",
                    width: "70%",
                    borderRadius: 7,
                    margin: "30 auto 30px",
                }}
                >
                    <Typography style={{
                        color: isResendEmail? "rgba(40, 135, 0, 1)" : "#d1486a" ,
                        fontSize: 16,
                        fontFamily: "Open sans",
                        fontWeight: 600
                    }}> {isResendEmail? (this.props.t("Account verification e-mail has been resent, please check your email."))
                    : (this.props.t(this.state.errorMsg))
                    }
                    </Typography>
                </Box>
            )

        }
    }
    getShowPassword = (showPassword:any)=>{
        if(showPassword){
            return <img style={{width: '20px'}} src={eyeOpen} />
        }else{
            return <img style={{width: '20px'}} src={eyeClose} />
        }
    }
    getConfirmPasswordError = (isTrue:any,t:any)=>{
        if(isTrue){
            return <Typography variant="subtitle1" component="div">
            <ErrorWrapperSection>
                <ErrorImgSection src={imgError} /><ErrorTextSection>{t("Password do not match")}</ErrorTextSection>
            </ErrorWrapperSection>
        </Typography>
        }
    }
    getImage = (isTrue:any)=>{
       return isTrue ? imgMale : imgStudent
    }
    getReferralCode = (url:string)=>url.includes("StudentSignup")&&<InputFieldWrapperSection>
    <FieldLabel>
    {this.props.t("Referral code")}
    </FieldLabel>
    <OutlineInput
        type='text'
        name='referral'
        placeholder={this.props.t('Enter referral code')}
        onChange={this.handleFormData}
        value={ this.state.formData.referral }
    />
</InputFieldWrapperSection>
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t, i18n} = this.props
        
        return (
            <>
             <CustomLoader isLoading={this.state.isLoading}/>
                <MainWrapperSection>
                    <LeftMainWrapperSectionSection>
                        <LogoWrapperSection>
                            <LogoImgSection src={imgLingoamo} alt="lingoamo_logo" />
                        </LogoWrapperSection>

                        {this.state.isSubmitSuccess ?
                        <FormBodyDivisionWrapper>
                            { 
                                this.showAlertBlock(this.state.isResendEmail)
                            }
                            <div style={{display: 'flex', flexDirection: 'column', gap: '20px', height: '300px', justifyContent: 'center', width: '65%', textAlign: 'center', marginTop: this.state.isResendEmail ? "18px" : '120px'}}>
                            <FormHeaderTextSection>{t("You are almost there")}!</FormHeaderTextSection>
                            <NormalTextSection>{t("Please check your email to verify and complete your sign up. If you don't see it, you may need to check your spam folder. Still can't find the email")}? 
                                <span onClick={() => this.handleResendEmail()} 
                                style={{ color: '#122BFF', 
                                    fontSize: '20px', 
                                    textDecoration: 'none', 
                                    fontFamily: 'Open sans, regular', 
                                    fontWeight: 500, 
                                    cursor: 'pointer' 
                                }}> {t("Resend Email")}</span>
                                </NormalTextSection>
                            </div> 
                        </FormBodyDivisionWrapper>
                        :
                        <FormWrapperSection>
                            <FormHeaderWrapSection>
                                <FormHeaderTextSection>{t("Sign up")}</FormHeaderTextSection>
                                <NormalTextSection>{t("Create your account")} {this.state.isTeacherPage ? t("as a teacher") : ""}</NormalTextSection>
                            {this.showAlertBlock(this.state.isShowError)}
                            </FormHeaderWrapSection>
                            <FormBodySection>
                                <FormFieldsWraperSection>

                                        <InputFormFieldWrapperSection>
                                            <InputFieldWrapperSection>
                                                <FieldLabel style={{ color: this.handleErrorColor("firstName") }} variant="subtitle1" >
                                                    {t("First Name")}
                                                </FieldLabel>
                                                <OutlineInput
                                                    type='text'
                                                    name='firstName'
                                                    style={{ color: 'rgb(0,0,0)',}}
                                                    placeholder={t('Enter first name *')}
                                                    className={this.handlePlaceHolderError(this.state?.formErrorData?.['firstName'])}
                                                    onChange={this.handleFormData}
                                                    value={this.state.formData?.firstName}
                                                    disabled={this.state.userFromInvited}
                                                />
                                            </InputFieldWrapperSection>


                                            <InputFieldWrapperSection>
                                                <FieldLabel style={{ color: this.handleErrorColor("lastName")}} variant="subtitle1" >
                                                    {t("Last Name")}
                                                </FieldLabel>
                                                <OutlineInput
                                                    type='text'
                                                    name='lastName'
                                                    placeholder={t('Enter last name *')}
                                                    style={{ color: 'rgb(0,0,0)'}}
                                                    className={ this.handlePlaceHolderError(this.state?.formErrorData?.['lastName'])}
                                                    onChange={this.handleFormData}
                                                    value={this.state.formData?.lastName}
                                                    disabled={this.state.userFromInvited}
                                                />
                                            </InputFieldWrapperSection>
                                        </InputFormFieldWrapperSection>

                                        <InputFieldWrapperSection>
                                            <FieldLabel style={{ color: this.handleErrorColor("email") }} variant="subtitle1" >
                                                {t("Email")}
                                            </FieldLabel>
                                            <OutlineInput
                                                type='email'
                                                name='email'
                                                style={{ color: 'rgb(0,0,0)'}}
                                                placeholder={t('Enter email *')}
                                                className={ this.handlePlaceHolderError(this.state?.formErrorData?.['email'])}
                                                onChange={this.handleFormData}
                                                value={this.state.formData?.email}
                                                disabled={this.state.userFromInvited}
                                            />
                                        </InputFieldWrapperSection>

                                        {!this.state.isTeacherPage &&
                                        <InputFieldWrapperSection>
                                            <FieldLabel>
                                                {t("Company")}
                                            </FieldLabel>
                                            <OutlineInput
                                                type='text'
                                                name='company'
                                                placeholder={t("Enter company")}
                                                onChange={this.handleFormData}
                                                value={this.state.formData?.company}
                                            />
                                        </InputFieldWrapperSection>
                                        }
                                        {this.getReferralCode(this.props.navigation.history.location.pathname)}
                                        <InputFieldWrapperSection>
                                            <FieldLabel variant="subtitle1" >
                                                {t("Create Password")}
                                            </FieldLabel>
                                            <OutlineInput
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                placeholder={t('Enter password *')}
                                                name='password'
                                                value={this.state.formData?.password}
                                                onChange={this.handleFormData}
                                                inputProps={{ maxLength: 30 }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            id="toggleButton"
                                                            aria-label="toggle password visibility"
                                                            onClick={() => { this.handleToggle() }}
                                                            edge="end"
                                                        >
                                                            {this.getShowPassword(this.state.showPassword)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <Box>
                                                <FormControlLabel
                                                    disabled
                                                    control={
                                                        <Checkbox
                                                            checked={this.handleChecked("minimumChar")}
                                                            style={{ color: this.handleColor(this.state.formErrorData?.password?.minimumChar), fontSize: '16px' }}
                                                            value="white"
                                                        />
                                                    }
                                                    label={<CheckboxLebel variant="h6" style={{ color:  this.handleErrorLabelColor("minimumChar"), fontFamily: 'Open sans, regular'}}>{t("Minimum 8 characters")}</CheckboxLebel>}
                                                />

                                                <FormControlLabel
                                                    disabled
                                                    control={
                                                        <Checkbox
                                                            checked={this.handleChecked("capitalChar")}
                                                            style={{ color: this.handleColor(this.state.formErrorData?.password?.capitalChar), fontSize: '16px' }}
                                                            value="white"
                                                        />
                                                    }
                                                    label={<CheckboxLebel variant="h6" style={{ color: this.handleErrorLabelColor("capitalChar"), fontFamily: 'Open sans, regular' }}>{t("One capital letter")}</CheckboxLebel>}
                                                />

                                                <FormControlLabel
                                                    disabled
                                                    control={
                                                        <Checkbox
                                                            checked={this.handleChecked("specialSign")}
                                                            style={{ color: this.handleColor(this.state.formErrorData?.password?.specialSign), fontSize: '16px' }}
                                                            value="white"
                                                        />
                                                    }
                                                    label={<CheckboxLebel variant="h6" style={{ color: this.handleErrorLabelColor("specialSign"), fontFamily: 'Open sans, regular' }}>{t("Special sign")}</CheckboxLebel>}
                                                />

                                                <FormControlLabel
                                                    disabled
                                                    control={
                                                        <Checkbox
                                                            checked={this.handleChecked("number")}
                                                            style={{ color: this.handleColor(this.state.formErrorData?.password?.number), fontSize: '16px' }}
                                                            value="white"
                                                        />
                                                    }
                                                    label={<CheckboxLebel variant="h6" style={{ color: this.handleErrorLabelColor("number"), fontFamily: 'Open sans, regular' }}>{t("One number")}</CheckboxLebel>}
                                                />
                                            </Box>
                                        </InputFieldWrapperSection>

                                        <InputFieldWrapperSection>
                                            <FieldLabel variant="subtitle1" >
                                                {t("Re-enter Password")}
                                            </FieldLabel>
                                            <OutlineInput
                                                type={'password'}
                                                name='confirmPassword'
                                                onChange={this.handleFormData}
                                                placeholder={t('Re-enter password *')}
                                                value={this.state.formData?.confirmPassword}
                                                inputProps={{ maxLength: 30 }}
                                            />
                                            {this.getConfirmPasswordError(this.state.formErrorData?.confirmPassword,t)}
                                        </InputFieldWrapperSection>

                                        <InputFieldWrapperSection style={{ flexDirection: "row", alignItems: "center", gap: "2px",flexWrap:'wrap',fontSize:'20px'}}>
                                            <FormControlLabel
                                                name="terms"
                                                style={{marginRight: "4px"}}
                                                control={
                                                    <Checkbox
                                                        checked={this.state.formData.isTerms}
                                                        style={{ color: this.handleTermsColor() }}
                                                        onChange={() => this.handleTermsChecked()}
                                                    />
                                                }
                                                label={<TandCLable data-test-id='t&c' style={{ textDecoration: 'none',color:this.state.formErrorData.isTerms?'rgb(214, 62, 95)':'black' }} variant="subtitle1" >
                                                    {t("I agree to")}
                                                </TandCLable>}
                                            />
                                            <a data-test-id="terms-button" style={{ textDecoration: 'underline', cursor: 'pointer', color:this.state.formErrorData.isTerms?'rgb(214, 62, 95)':'black' }}
                                                target="_blank" href="https://lingoamo.ai/terms-and-conditions/">
                                                {t("terms and conditions")}</a>


                                        </InputFieldWrapperSection>



                                        <InputFieldWrapperSection>
                                            <SignUpButtonSection data-test-id='signUpButton' name='signUpButton' onClick={this.handleSubmit}>{t("Sign Up")}</SignUpButtonSection>

                                            <Typography id="testText" style={{ color: 'rgb(36, 36, 36)', fontFamily: 'Open sans, regular', fontSize: '20px', textAlign: 'center', fontWeight: 'normal' }} variant="subtitle1" component="div">
                                                <span>{t("Do you have an account")}?</span> &nbsp;
                                                <Link 
                                                    onClick={()=>this.removeLocalStorageDataWhenClickOnLoginBtn()}
                                                    to={this.handleRoute(this.state.isTeacherPage)}
                                                    style={{ color: '#122BFF', fontSize: '20px', textDecoration: 'none', fontFamily: 'Open sans, regular', fontWeight: 'bold' }}>{t("Login")}</Link>
                                            </Typography>
                                        </InputFieldWrapperSection>

                                    </FormFieldsWraperSection>

                                </FormBodySection>
                            </FormWrapperSection>
                        }

                    </LeftMainWrapperSectionSection>
                    <RightMainWrapperSectionSection>
                        <MaleImgSection src={this.getImage(this.state.isTeacherPage)} alt="male_image" />
                        {/* <MaleImgSection src={this.state.isTeacherPage ? imgMale : imgStudent} alt="male_image" /> */}
                        <RightTextWrapperSection>
                            {this.state.isTeacherPage ? <span style={{ display: "flex", width: "500px"}} >
                                {t("Language shapes the way we think, and determines what we can think about")}</span> : 
                            <span style={{ display: "flex", width: "500px"}}>{t("Learn a new language and enter a new universe")}!</span>}
                        </RightTextWrapperSection>
                    </RightMainWrapperSectionSection>
                    <SmallScreenLogo>
                        <img src={imgLingoamo} alt="lingoamo_logo" />
                    </SmallScreenLogo>
                </MainWrapperSection>
            </>
        )
        // Customizable Area End
    }
}

export default withTranslation()(TeacherSignupBlock)

// Customizable Area Start
const MainWrapperSection = styled('div')({
    display: 'flex',
    width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    flexWrap:'wrap',
    "@media only screen and (max-width: 1024px)":{
        display:'flex',
        flexDirection:'column-reverse',
        width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    },
})

const FormBodyDivisionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

const FieldLabel = styled(Typography)({
    "@media only screen and (max-width: 1024px)":{
        display:'none'
    }
})

const InputFormFieldWrapperSection = styled('div')({
    display: 'flex',
    gap: '20px',
    '& >div': {
        width: '50%'
    },
    "@media only screen and (max-width: 767px)":{
        display:'flex',
        flexDirection:'column',
        '& >div': {
            width: '100%'
        },
    }
})

const LeftMainWrapperSectionSection = styled('div')({
    width: "50%",
    fontSize:'20px',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
        fontSize:'large'
    }
})

const RightMainWrapperSectionSection = styled('div')({
    width: "50%",
    position: 'relative',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
        display:'flex',
        justifyContent:'center'
    }
})

const LogoWrapperSection = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: "50px 0px"
})

const SignUpButtonSection = styled('button')({
    fontFamily: "Open sans, semibold",
    width: '100%',
    backgroundColor: '#6d5996',
    color: '#FFFFFF',
    height: '50px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer'
})

const LogoImgSection = styled('img')({
    '@media only screen and (max-width: 1024px)':{
        display:'none'
    },
    width: "180px",
})

const SmallScreenLogo = styled('div')({
    display:'none',
    '@media only screen and (max-width: 1024px)':{
        display:'block',
        "& img":{
            height:'auto',
            width:'12%',
            marginLeft:'5%',
            margin: "20px"

        }
    }
})

const ErrorWrapperSection = styled('div')({
    display: 'flex',
    gap: '6px',
    alignItems: 'center'
})

const ErrorImgSection = styled('img')({
    height: '20px'
})

const ErrorTextSection = styled('span')({
    fontSize: '14px',
    color: '#d23d69'
})

const MaleImgSection = styled('img')({
    filter:'brightness(70%)',
    width: 'calc(60% - 3px)',
    height: 'calc(100% - 2px)',
    position: 'fixed',
    top: 0,
    left: '50%',
    border: '2px solid #b7b7b7',
    objectFit:'cover',
    '@media only screen and (max-width: 768px)':{
        position: 'unset',
        width:'90%',
        height:'450px',
        maxHeight:'70vh',
        borderRadius:'10px',
        border:'none',
        objectFit:'cover',
        objectPosition:'top'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)':{
        position: 'unset',
        width:'90%',
        height:'calc(100vw * 1/2)',
        // maxHeight:'70vh',
        borderRadius:'10px',
        border:'none',
        objectFit:'cover',
        objectPosition:'top'
    },
})

const FormWrapperSection = styled('div')({
    margin: '0 10% 80px',
    border: '1px solid #d5d5d5',
    borderRadius: '24px',
    padding: '7%',
    '@media only screen and (min-width: 768px) and (max-width: 1024px)':{
        // border:'none',
        padding:'50px',
        margin:'30px 10%'
    },
    '@media only screen and (max-width: 767px)':{
        border:'none',
        padding:'0px',
        margin:'10px 10%'
    }
})

const InputFieldWrapperSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .error-input': {
        '& .MuiInputBase-input::placeholder': {
            color: 'rgb(214, 62, 95)',
            opacity: '1'
        },
    },
    '& .valid-input': {
        '& .MuiInputBase-input::placeholder': {
            color: 'rgb(137, 137, 137)',
            opacity: '1'
        },
    }
})

const FormFieldsWraperSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
})

const RightTextWrapperSection = styled('div')({
    top: '45px',
    left: '50%',
    color: 'white',
    width: '50%',
    position: 'fixed',
    fontSize:  '35px',
    textAlign: 'center',
    fontFamily: 'Open sans',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center",
    '@media only screen and (max-width: 1024px)':{
      top: '40px',
    left: '14%',
    color: 'white',
    width: '70%',
    position: 'absolute',
    fontSize: '30px',
    textAlign: 'center',
    fontFamily: 'Open sans',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center",
    alignItems:'center',
    }
})

const FormHeaderWrapSection = styled('div')({
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '30px',
})

const FormHeaderTextSection = styled('div')({
    fontSize: "28px",
    color: "#242424",
    fontFamily: 'Open sans, Bold',
    fontWeight: 'bold'
})

const NormalTextSection = styled('div')({
    fontSize: '20px',
    color: '#242424',
    fontFamily: 'Open sans, regular'
})

const FormBodySection = styled('div')({
    '& .MuiOutlinedInput-input': {
        padding: '14px 14px',
        width: '100%',
        borderRadius: '8px',
    },
    '& .MuiTypography-subtitle1': {
        fontFamily: 'Open sans, regular !important',
        fontSize: '20px',
        color: '#242424',
    }
})

const OutlineInput = styled(OutlinedInput)({
    borderRadius: '8px !important',
    
})


const CheckboxLebel = styled(Typography)({
    fontSize:'15px',
    lineHeight:'unset',
    '@media only screen and (max-width: 1024px)':{
        fontSize:'small',

    }
    
})

const TandCLable = styled(Typography)({
    fontSize:'large',
    '@media only screen and (max-width: 1045px)':{
        fontSize:'14px !important',
    }
})

const SpanTag = styled('span')({})
// Customizable Area End
