import React from "react";

// Customizable Area Start
// import { Link } from 'react-router-dom';
import {
    OutlinedInput,
    Divider,
    Radio,
    Checkbox,
    Button,
    Box,
    MenuItem,
    Select,
    CircularProgress
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { BackArrow, LockImage, dropDown, CreditCard, PaymentSuccessImg, PaymentUpdateImg, InfoTip, InfoTipError, AppliedPromo } from "./assets";
import { loadStripe } from "@stripe/stripe-js";
import { withTranslation } from "react-i18next";
import { Elements, CardNumberElement, ElementsConsumer,CardCvcElement,CardExpiryElement } from "@stripe/react-stripe-js";


export const getStripeKey = (evnUrl: string) => {
    if(evnUrl.includes("dev") || evnUrl.includes("stage") || evnUrl.includes("localhost")){
      return "pk_test_51NQYFwEp36RwVXKvQoTSGDz9g3mUvcOT77TyOfHskB8bOcb8hjyYkRlvHZ0j1Lc0ma5Mo4UkueAMJIOYypIS0mgj00XAjqn8f2"
    } return "pk_live_51NQYFwEp36RwVXKvQfsAr1XMU9tdUNAjPqHce0FOzkudFwW0bCmGXQExVEqciahOC9JndLVfbWWexW0inKiCxws300WAKxqqOb"
}


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// Customizable Area End

import PaymentController, {
    Props,
    // configJson
} from "./PaymentConroller.web";

export class PaymentBlock extends PaymentController {
    constructor(props: Props) {
        super(props);
        this.cardElement = null;
    }

    // Customizable Area Start
    /* istanbul ignore next */
    handleCheckout = async (event: any, stripe: any, elements: any) => {

        if(!this.state.isCardOpen){
            this.setState({isCardOpen:true})
        }

        const { formData, formErrorData } = this.state;
        const { firstName, lastName, street, number, postalCode, city, country, cardDate, cardNumber, cardName, cvv } = formData

        const newFormErrorData = {
            ...formErrorData,
            firstName: !(firstName),
            lastName: !(lastName),
            street: !(street),
            number: !(number),
            postalCode: !(postalCode),
            city: !(city),
            country: !(country),
            cardDate: !(cardDate),
            cardNumber: !(cardNumber),
            cvv: !(cvv),
            cardName: !(cardName),
            isChecked:!(this.state.isChecked)
        }

        const type = sessionStorage.getItem("type") || localStorage.getItem("type")

        const allData:any = {...newFormErrorData}
        delete allData['companyName']
        delete allData['cardDate']
        delete allData['cardNumber']
        delete allData['cardName']
        delete allData['cvv']

        if(type!="buy_membership") delete allData['isChecked']


        this.setState({ formData, formErrorData: { ...formErrorData, ...newFormErrorData,cvv:this.state.formErrorData.cvv } });
        if(Object.values(allData).every(v=>!v)){
            try {
                event.preventDefault();
                if (!stripe || !elements) {
                    // Stripe.js has not loaded yet. Make sure to disable
                    // form submission until Stripe.js has loaded.
                    return;
                }
                const cardElement = this.cardElement;
                const { token, error } = await stripe.createToken(cardElement,{name:this.state.formData.cardName});
                if (token) {
                    
                    this.setState((prevState) => ({
                        formData: {
                            ...prevState.formData,
                            payment_token: token.id
                        },
                        formErrorData:{...prevState.formErrorData,cvv:false}
                    }), () => {
                        this.paymentApi();
                    });
                }
                else if(error){
                    this.setState({toasterMessage:error.message,openToaster:true})
                }
    
            } catch (error) {
    
            }
        }
        

    }

    alert = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined) => {
        return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
        {this.state.toasterMessage || "Payment Failed!"}</MuiAlert>
      }


    getClass = (inputName: 'firstName' | 'lastName' | 'companyName' | 'street' | 'number' | 'postalCode' | 'cardNumber' | 'cardName' | 'cardDate' | 'cvv' | 'country' | 'city' | "isChecked") => {
        /* istanbul ignore next */
        if (inputName === 'companyName') {
            return 'valid-input input-fieds'
        } else if (this.state.formErrorData[inputName]) {
            return 'error-input input-fieds'
        } else {
            return 'valid-input input-fieds'
        }
    }
    getInputBox = (inputName: 'firstName' | 'lastName' | 'companyName' | 'street' | 'number' | 'postalCode' | 'cardNumber' | 'cardName' | 'cardDate' | 'cvv' | 'country' | 'city', inputPlaceholder: string, inputType: string, inputWidth: string) => (
        <InputFieldWrap style={{ width: inputWidth }}>
            <OutlineInput
                className={this.getClass(inputName)}
                data-test-id={`${inputName}-id`}
                // type={inputType}
                onChange={(event: any) => this.handleInputFields(inputName, event, inputType)}
                name={inputName}
                placeholder={this.props.t(inputPlaceholder)}
                value={this.state?.formData[inputName]}
                endAdornment={(inputName === 'cvv') ? <img src={this.state.formErrorData.cvv ? InfoTipError : InfoTip} height="20px" /> : ''}
            />
        </InputFieldWrap>

    )

    getSrc = (isError:boolean)=>isError?InfoTipError:InfoTip

    getClassForNameField = (isError:boolean)=>isError?"error-field":""

    getCheckbox = ()=><CheckBoxWrapper><Checkbox data-test-id="agree-checkbox" 
        className={this.getClass("isChecked")} color="primary" 
        onChange={(event) => this.handleChecked(event.target.checked)} />
        <CheckText style={{ color: this.hanldleErrorTextColor(this.state.formErrorData.isChecked && 
            this.state.paymentType=="buy_membership") }}>
                {this.props.t("I agree to save the card as my primary card for subscription")}
        </CheckText>
    </CheckBoxWrapper>

    getPaymentSummaryHeader = (type:string)=>type=='buy_membership'? this.props.t("Subscription Price"): this.props.t("Lingos Price")

    getPlaceHolderColor = (isError:boolean)=>isError?"rgb(214, 62, 95)":"rgb(137, 137, 137)"

    getFontSize = (isSmall:boolean)=>{
        return isSmall?'16px':'20px'
    }

    GetCardField = () => {
        const getOptions = (field:'cardNumber' | 'cardDate' | 'cvv')=>{
            const options = {
            hidePostalCode: true,
            style: {
                base: {
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontSize:this.getFontSize(this.state.smallScreen),
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: this.getPlaceHolderColor(this.state.formErrorData[field]),
                    }
                },
                invalid: {
                    color: "rgb(214, 62, 95)"
                }
            }
                    }

                    return options
            }
        
        return (
            <Elements stripe={this.state.stripe}
            >
                <div
                    className="cardInputs"
                >
                <CardNumberElement
                data-test-id='card-number-field'
                onReady={(element) => (this.cardElement = element)}
                options={getOptions('cardNumber')} />

                </div>
                <div >
                    <input style={{width:'100%',padding:'12px'}} data-test-id = 'name-on-card-field' 
                        className={`cardInputs ${this.getClassForNameField(this.state.formErrorData.cardName)}`} 
                        placeholder={this.props.t("Name on Card")} value={this.state.formData.cardName} 
                        onChange={(e)=>this.handleInput('cardName',e.target.value,'text')} />
                </div>
                <div style={{display:'flex',gap:'10px'}}>
                    <div className="cardInputs" style={{flexGrow:1.5}}>
                        <CardExpiryElement data-test-id='expiry-date-field' options={getOptions('cardDate')} 
                            onChange={(e:any)=> {this.handleCardFieldChange(e,'cardDate')}} /></div>
                    <div className="cardInputs" style={{flexGrow:1}}>
                        <div style={{display:'flex'}}>
                            <div style={{flexGrow:3}}>
                                <CardCvcElement options={getOptions('cvv')} onChange={(e:any)=> this.handleCardFieldChange(e,'cvv')} />
                            </div>
                            <div style={{flexGrow:1,display:'flex',justifyContent:'end'}}>
                            <img style={{objectFit:'contain'}} src={this.getSrc(this.state.formErrorData.cvv)} />
                                {/* {this.state.formErrorData.cvv?:<img src={InfoTip} />} */}
                            </div>
                        </div>
                    </div>
                </div>
                
            </Elements>

        )
    }

    getPageContent = (heading: string, underText: string, image: string, buttonText: string, type: 'primary' | 'secondary', handleClick: () => void) => {
        /* istanbul ignore next */
        const {t} = this.props
        return (
            <PageContainer>
                <LeftContainer>
                    <HeadText>{t(heading)}</HeadText>
                    <UnderText>{t(underText)}</UnderText>
                    <ButtonContainer>

                    </ButtonContainer>
                    <PageButton onClick={handleClick} style={{ color: type === 'primary' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)', background: type === 'primary' ? 'rgba(108, 90, 151, 1)' : '#ffaf00', width: type === 'primary' ? '235px' : '320px', textDecoration: 'none' }} data-test-id='pageButton' >
                        <span>{t(buttonText)}</span>
                    </PageButton>
                </LeftContainer>
                <RightContainer>
                    <img style={{ width: '100%' }} src={image} />
                </RightContainer>

            </PageContainer>
        )
    }


    CustomDropDown = (name: 'country' | 'city', label: string, options: any, width: string) => {
        /* istanbul ignore next */
        return (
            <>
                <InputField style={{ width: width }}>
                    <div style={{ width: '100%', height: '50px' }}>
                        {<div className="select_field" style={{
                            borderRadius: this.state.enableFieldName !== name ? '8px' : '8px 8px 0px 0px',
                        }}>
                            <button
                                data-test-id={`${name}-id`}
                                className="choose__label"
                                style={this.state.formData[name] ? { justifyContent: 'space-between', color: '#242424' } : {}}
                                onClick={() => this.setEnableField(name)}
                            >
                                {this.state.formData[name]}
                                <img src={dropDown} />
                            </button>
                            {this.state.enableFieldName === name && (
                                <ul className="choose__options">
                                    {options.filter((e: any) => {
                                        if (this.state.enableFieldName) {
                                            return e != this.state.formData[name];
                                        } else {
                                            return true;
                                        }
                                    }).map((option: any, index: number) => (
                                        <li key={`${option.key}-${index + 0}`} style={index == 0 ? {} : { borderTop: "1px solid #d1d1d1" }}>
                                            <button data-test-id='select button' className="choose_option2" onClick={() => this.setProfileValue(name, option)}><span className="select_label_item">{option}</span></button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>}
                    </div>

                </InputField>

            </>
        )
    }

    getCardBorderColor = (isOpen:boolean) => isOpen ? 'grey' : '#f7f7f7'

    getlanguage = () => {
        return window.localStorage.getItem('LearningLanguage') || window.sessionStorage.getItem('LearningLanguage')
    }

    getamount = () => {
        return window.localStorage.getItem('amount') || window.sessionStorage.getItem('amount') || ''
    }

    getlingosDescription = () => {
        return window.localStorage.getItem('lingos') || window.sessionStorage.getItem('lingos');
    }

    getPageHeading = (type:string)=>{
        if(type=='buy_lingos'){
            return "Buy Lingos"
        }else if(type=='donate_lingos'){
            return "Donate Lingos"
        }else{
            return "Checkout"
        }
    }


    getRemoveButton = (isTrue:boolean)=>isTrue?<Box data-test-id='romove-promo-btn' onClick={this.removePromocode} style={{ cursor: 'pointer', color: 'rgba(214, 62, 95, 1)' }}>Remove</Box>:""

    getOrderDetials = (type:string)=>{
        if(type=='buy_membership'){
            return <OrderContainer>
            <div>{this.props.t("Language")} - {this.getlanguage()}</div>
            <div>{this.props.t("Plan detail")} - <span>{this.getlingosDescription()}</span></div>
            <div>{this.props.t("Amount")} -  <span>{this.getamount()}.00 Eur</span></div>
        </OrderContainer>
        }else{
            return <OrderContainer>
            <div>{this.props.t("Number of Lingos")} - <span>{this.getlingosDescription()}</span></div>
            <div>{this.props.t("Amount")} -  <span>{this.getamount()}.00 Eur</span></div>
        </OrderContainer>
        }
    }

    getContent = () => {
        const {t} = this.props
        const options =
        {
            hidePostalCode: true,
            style: {
                base: {
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }


        if (this.state.isSuccessPage) {
            return <>{this.getPageContent('Your payment is successful', 
            "Your subscription plan is now active. Thank you for choosing Lingoamo to be your companion on your language learning journey.", 
            PaymentSuccessImg, 'Take Your Level Test', 'secondary', this.handleTakeTest)}</>
        } else if (this.state.isModifiedPage) {
            return <>{this.getPageContent('Membership Updated Successfully', 
            "You can book more classes now and continue your journey", PaymentUpdateImg, 
            'Book Classes', 'primary', this.handleBookClasses)}</>
        } else if (this.state.isPaymentPage) {
            return (<PaymentContainer>
                <Elements stripe={this.state.stripe}>
                    <ElementsConsumer>
                        {({ stripe, elements }) => (
                            <>

                                <LeftPanel>
                                    <LeftPanelWrapper>
                                        <LeftPanelWrap>
                                            <HeaderWrapper>
                                                <BackWrapper data-test-id="back-button-id" onClick={this.handleBack} >
                                                    <img src={BackArrow} height={22} />
                                                    <BackText>{t("Back")}</BackText>
                                                </BackWrapper>
                                                <HeadingText>{this.props.t(this.getPageHeading(this.state.paymentType))}</HeadingText>
                                            </HeaderWrapper>
                                        </LeftPanelWrap>
                                        <BillingWrapper>
                                            <SummaryHead>{t("Billing Address")}</SummaryHead>
                                            <FormItem>
                                                <FormNamesItems>
                                                    {this.getInputBox('firstName', 'First Name *', 'text', '50%')}
                                                    {this.getInputBox('lastName', 'Last Name *', 'text', '50%')}
                                                </FormNamesItems>
                                                {this.getInputBox('companyName', 'Company Name', 'text', '100%')}
                                                <FormItems>
                                                    {this.getInputBox('street', 'Street *', 'text', '50%')}
                                                    {this.getInputBox('number', 'Number *', 'text', '50%')}
                                                </FormItems>
                                                <FormItems>
                                                    {this.getInputBox('postalCode', 'Postal Code *', 'text', '50%')}
                                                    {this.getInputBox('city', 'City *', 'text', 'calc(50% - 10px)')}
                                                </FormItems>
                                                    {this.getCountryContent()}
                                            </FormItem>

                                        </BillingWrapper>
                                        <PaymentMethodWrapper>
                                            <PaymentMethodHeader>
                                                <SummaryHead style={{ marginBottom: '0' }}>{t("Payment Method")}</SummaryHead>
                                                <InfoText><span>{t("Secured connection")}</span><img src={LockImage} style={{ marginLeft: '8px' }} /></InfoText>
                                            </PaymentMethodHeader>
                                            <OrderContainerWrapper data-test-id='data-367' 
                                                style={{ borderColor: this.getCardBorderColor(this.state.isCardOpen) }}>
                                                <Radio data-test-id='radio-btn' checked={this.state.isCardOpen} 
                                                    style={{ width: 'min-content' }} color="default" size="medium" 
                                                    onChange={(event: any) => this.handleCardOpen(event.target.checked)} />
                                                <HeaderText><img src={CreditCard} style={{ height: 'min-content' }} />
                                                <CardText>{t("Credit/Debit card")}</CardText></HeaderText>
                                            </OrderContainerWrapper>
                                            {/* <Elements stripe={stripe}>
                                                {this.state.isCardOpen && <CardWrapper>

                                                    <CardElement
                                                        options={options}
                                                        onReady={(element) => (this.cardElement = element)}
                                                        onChange={event => {
                                                            this.handleCardErrorMessage(event)
                                                        }}

                                                    />
                                                    <p style={{ color: 'red', margin: '15px' }}>{this.state.cardErrorMsg}</p>

                                                    <CheckBoxWrapper>
                                                        <Checkbox data-test-id="agree-checkbox" className={this.getClass("isChecked")} color="primary" onChange={(event) => this.handleChecked(event.target.checked)} /><CheckText style={{ color: this.hanldleErrorTextColor(this.state.formErrorData.isChecked && this.state.paymentType=="buy_membership") }}>I agree to save the card as my primary card for subscription</CheckText>
                                                    </CheckBoxWrapper>
                                                </CardWrapper>
                                                }
                                            </Elements> */}
                                            

                                            {this.state.isCardOpen && <CardWrapper data-test-id='data-390'>
                                                {this.GetCardField()}
                                                {this.getCheckbox()}
                                            </CardWrapper>}
                                        
                                        </PaymentMethodWrapper>
                                        <OrderWrapper>
                                            <SummaryHead>{t("Order Details")}</SummaryHead>
                                            {this.getOrderDetials(this.state.paymentType)}
                                        </OrderWrapper>
                                    </LeftPanelWrapper>
                                </LeftPanel>
                                <RightPanel>
                                    <RightPanelWrapper>
                                        <SummanyWrapper>
                                            <SummaryHead>{t("Payment Summary")}</SummaryHead>
                                            <PaymentWrapper>
                                                <PaymentInfoWrapper>
                                                    <div>{this.getPaymentSummaryHeader(this.state.paymentType)}</div>
                                                    <div> {this.getamount()}.00 Eur</div>
                                                </PaymentInfoWrapper>
                                                <PaymentInfoWrapper><div>{t("Tax")}</div>
                                                    <div> {this.state.subscritionBill.tax}.00 Eur</div>
                                                </PaymentInfoWrapper>
                                                {!this.state.isVisible && <PaymentInfoWrapper>
                                                    <div>{t("Promo discount")}</div>
                                                    <div>- {this.state.subscritionBill.promocode_price}.00 Eur</div>
                                                </PaymentInfoWrapper>}
                                                <Divider style={{ width: '100%' }} />
                                                <PaymentInfoWrapper>
                                                    <HeavyText style={{ fontWeight: 'bold' }} >{t("Total Amount")}</HeavyText>
                                                    <HeavyText style={{ fontWeight: 'bold' }}>{this.getamount()}.00 Eur</HeavyText>
                                                </PaymentInfoWrapper>
                                                <PromoWrapper>
                                                    <PromoText>{t("Do you have a Promo code?")}</PromoText>
                                                    <PromoContent>
                                                        <div style={{ display: 'flex', gap: '8px' }}>
                                                            <OutlineInput
                                                                startAdornment={!this.state.isVisible && <img src={AppliedPromo} />}
                                                                endAdornment={this.getRemoveButton(!this.state.isVisible)}
                                                                style={{ width: '100%' }}
                                                                className={'input-fieds'}
                                                                data-test-id={`promo-id`}
                                                                type={'text'}
                                                                onChange={(event: any) => this.handlePromocode(event)}
                                                                name='promocode'
                                                                placeholder={t("Promo code")}
                                                                value={this.state?.formData?.promocode}
                                                                disabled={!this.state.isVisible}
                                                            />
                                                            {this.state.isVisible && <ApplyButton data-test-id="apply-promo" onClick={this.handlePromoApply} style={{ backgroundColor: this.handleApplyButtonColor(this.state.formData.promocode), borderColor: this.handleApplyButtonColor(this.state.formData.promocode) }}>
                                                                {t("Apply")}
                                                            </ApplyButton>}

                                                        </div>

                                                        {this.state?.formErrorData?.promocode &&
                                                            <ErrorTextSection>{t("Invalid Promo code")}</ErrorTextSection>
                                                        }
                                                    </PromoContent>
                                                </PromoWrapper>
                                            </PaymentWrapper>
                                            <PolicyWrapper>
                                                <div>{t("By completing your purchase you agree to these")} 
                                                    <a target="_blank" href="https://lingoamo.ai/terms-and-conditions/" style={{textDecoration: "none", color: "rgba(70, 95, 235, 1)"}}>
                                                        {t("Terms of Service")}.</a>
                                                        </div>
                                                <PurchaseButton data-test-id='logInButton' disabled={!stripe}

                                                    onClick={(event) => this.handleCheckout(event, stripe, elements)} style={{ textDecoration: 'none' }}>
                                                   {this.state.loading ? <CircularProgress color="inherit" /> : t("Confirm Purchase")}
                                                </PurchaseButton>


                                            </PolicyWrapper>
                                        </SummanyWrapper>
                                    </RightPanelWrapper>
                                </RightPanel>
                            </>
                        )}
                    </ElementsConsumer>
                </Elements>

            </PaymentContainer>)
        }
    }


    getCountryContent = () => {
        return (
            <>
                <SelectCountry style={{color:this.getColor(this.state.formErrorData,this.state.formData,this.state.paymentType),borderRadius:'8px'}} data-test-id="country-id" placeholder="Country *" name='country' value={this.state.formData.country ? this.state.formData.country : "Country *"} variant="outlined" labelId="demo-simple-select-helper-label"
                    onChange={(e: any) => this.handleChange(e)}
                    IconComponent={({...rest}) => <img style={{ height: '8px', width: '16px', paddingRight: '20px', cursor: 'pointer',pointerEvents: 'none',
                    position: 'absolute',
                    right: '10px',paddingTop:'8px' }} {...rest} src={dropDown} />}
                >
                    <MenuItem disabled value="Country *" >{this.props.t("Country *")}</MenuItem>
                    {this.state.formData.countryList.map((ele: any) => {
                        return <MenuItem key={ele.name} value={ele.name}>{ele.name}</MenuItem>
                    })}
                </SelectCountry>
            </>
        )
    }

    renderWaitBox = () => {
        return (
            <WaitBox>
                <TextWait>Please wait</TextWait>
                <p>We are verifying your details</p>
            </WaitBox>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
            {
                !this.state.isPresent
                ?
                <div>
                <CustomLoader isLoading={this.state.isLoading} />
                <HeaderBox data-test-id='left-panel'>
                    <CustomStudentNavigationLayout params="" screenName="Payment" navigation={this.props.navigation} />
                </HeaderBox>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.openToaster}
                    onClose={this.closeToaster}
                    key={"top" + "center"}
                    autoHideDuration={3000}
                >
                {this.alert(this.closeToaster,'error')}
                </Snackbar>
                {this.getContent()}
                </div>
                :
                <>
                <CustomLoader isLoading={this.state.loading} />
                {this.renderWaitBox()}
                </>
            }
                </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(PaymentBlock)
const SelectCountry = styled(Select)({
    fontSize: '20px',
    "@media only screen and (min-width:768px) and (max-width:1024px)": {
        fontSize: "16px",
    },
    "@media only screen and (max-width:767px)": {
        fontSize: '14px'
    },
    "& .MuiOutlinedInput-input": {
        padding: '13px '
    },
    width: '50%'

})

const PaymentContainer = styled('div')({
    width: '100%',
    display: 'flex',
    "@media only screen and (max-width:768px)": {
        flexDirection: 'column'
    }
})

const HeaderText = styled('div')({
    display: 'flex',
    gap: '14px',
    alignItems: 'center'
})

const FormItem = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    "@media only screen and (max-width:1024px)": {
        "& div": {
            "& input": {
                fontSize: '16px !important'
            }
        }
    }
})

const PageContainer = styled('div')({
    marginTop: '120px',
    padding: '10% 170px',
    display: 'flex',
    alignItems: 'center'
})

const OrderContainerWrapper = styled('div')({
    display: 'flex',
    gap: '8px',
    padding: '24px 16px',
    backgroundColor: '#f7f7f7',
    border: '1px solid #f7f7f7'
})

const HeaderBox = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    background: '#fff'
})

const CheckBoxWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
})

const CardText = styled('div')({
    color: "rgba(67, 67, 67, 1)",
    fontWeight: 'bold',
    fontFamily: "Open sans, bold",
    fontSize: '22px',
    "@media only screen and (max-width:1024px)": {
        fontSize: '20px'
    }
})

const CardWrapper = styled('div')({
    border: '1px solid grey',
    display: 'flex',
    padding: '28px 20px',
    gap: '24px',
    paddingRight:'20%',
    flexDirection: 'column',
    "& .cardInputs":{
        border:"1px solid #d1d1d1",
        borderRadius:'8px',
        padding:'14px',
        fontSize:'20px'
    },
        "& .error-field::placeholder":{
            fontSize:'20px',
            color: 'rgb(214, 62, 95)',
            opacity: '1'
        
    },
    "& .InputElement":{
        color:'red !important'
    },
    "@media only screen and (max-width:1024px)": {
        "& div": {
            "& input": {
                fontSize: '16px !important',
            },
            "& div": {
                "& input": {
                    fontSize: '16px !important',
                }

            }
        }
    },
    "@media only screen and (max-width:768px)":{
        padding:'20px'
    }
})

const CheckText = styled('span')({
    fontFamily: 'Open sans, regular',
    fontSize: '16px',
    "@media only screen and (max-width:1024px)": {
        fontSize: '14px !important'
    }
})

const PromoText = styled('div')({
    marginBottom: '8px',
    "@media only screen and (max-width:1024px)": {
        fontSize: '16px !important'
    }
})

const InputField = styled('div')({
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    '& .select_label_item': {
        fontFamily: "'Open sans', semibold",
    },
    "input::-webkit-datetime-edit": { color: "transparent" },
    "input:focus::-webkit-datetime-edit": { color: "#000" },
    '& .select_field': {
        width: '100%',
        minHeight: '46px',
        border: '1px solid #d1d1d1',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: ' center',
        padding: 0,
        position: 'relative',
        height: '100%',
        "&:hover": {
            border: '1px solid #d1d1d1',
        },
        "&:focus": {
            border: '1px solid black'
        }
    },
    '& .choose__label': {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        height: '40px',
        font: 'inherit',
        background: 'none',
        border: 0,
        width: '100%',
        textAlign: 'left',
        padding: '0 13px',
        fontSize: '20px',
        fontFamily: 'Open sans, regular',
        cursor:'pointer'
    },
    '& .choose__options': {
        margin: 0,
        padding: 0,
        listSyle: 'none',
        position: 'absolute',
        left: -1,
        top: '50px',
        width: '100%',
        border: '1px solid #d1d1d1',
        listStyle: 'none',
        borderRadius: '0px 0px 8px 8px',
        zIndex: 2,
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'none'
    },
    '& .choose__options::-webkit-scrollbar': {
        display: 'none'
    },
    ' & .choose_option2 ': {
        background: 'transparent',
        width: '100%',
        border: 0,
        padding: '10px 13px',
        textAlign: 'left',
        font: 'inherit',
        backgroundColor: 'white',
        fontSize: '20px',
        fontFamily: 'Open sans, regular',

    }

});

const BackText = styled('div')({
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginLeft: '8px',
    "@media only screen and (max-width: 768px)": {
        display: 'none'
    }
})

const ButtonContainer = styled('div')({
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
})

const LeftContainer = styled('div')({
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
})

const RightContainer = styled('div')({
    width: '40%',
})

const HeavyText = styled('div')({
    fontFamily: 'Open sans, bold !important',
})

const OrderContainer = styled('div')({
    padding: '24px 16px',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& div': {
        color: "rgba(67, 67, 67, 1)",
        fontFamily: "Open sans, semibold",
        fontSize: '22px',
        "@media only screen and (max-width:1024px)": {
            fontSize: '16px'
        }
    }
})

const InfoText = styled('div')({
    color: "rgba(137, 137, 137, 1)",
    fontFamily: "Open sans, regular",
    fontSize: '16px',
    "@media only screen and (max-width:768px)": {
        display: 'none'
    }
})

const FormNamesItems = styled('div')({
    display: 'flex',
    gap: '20px',
    "@media only screen and (max-width: 1024px)": {
        flexWrap: 'wrap',
        "& div": {
            "& input": {
                fontSize: '16px !important',
                width: '100% !important'
            },
            width: '100% !important'
        }
    }
})

const FormItems = styled('div')({
    display: 'flex',
    gap: '20px',
    "@media only screen and (max-width: 1024px)": {
        "& div": {
            "& input": {
                fontSize: '16px !important',
                width: '100% !important'
            },
            width: '100% !important'
        }
    }

})

const LeftPanel = styled('div')({
    width: '60%',
    marginTop: '112px',
    "@media only screen and (max-width:768px)": {
        width: '100%'
    }
})

const LeftPanelWrapper = styled('div')({
    padding: '5% 8%',
})

const BillingWrapper = styled('div')({
    marginTop: '50px'
})

const OrderWrapper = styled('div')({
    marginTop: '50px'
})

const PaymentMethodWrapper = styled('div')({
    marginTop: '50px'
})

const PaymentMethodHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '16px'
})

const RightPanel = styled('div')({
    width: '40%',
    // position: 'fixed',
    // top: '112px',
    background: '#f7f7f7',
    // right: '0',
    paddingTop: '215px',
    fontFamily: 'Open sans, regular',
    fontWeight: 'bold',
    // height: 'calc(100vh - 112px)',
    "@media only screen and (max-width:768px)": {
        width: '100%',
        paddingTop: '20px',
        background: 'white'
    }
})

const RightPanelWrapper = styled('div')({
    paddingLeft: '13%',
    paddingRight: '25%',
    "@media only screen and (max-width: 768px)": {
        padding: '1% 8%'
    },
    "@media only screen and (min-width: 769px) and (max-width:1024px)": {
        paddingLeft: '13%',
        paddingRight: '13%',
    }
})

const HeadingText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
    width: '100%',
    "@media only screen and (max-width: 768px)": {
        fontSize: '20px',
    }
})

const HeadText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
})

const UnderText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: "Open sans, semibold",
    width: '100%'
})

const BackWrapper = styled('div')({
    display: 'flex',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer'
})

const SummanyWrapper = styled('div')({
    width: '100%'
})

const OutlineInput = styled(OutlinedInput)({

})

const InputFieldWrap = styled('div')({
    '& .input-fieds': {
        borderRadius: '8px !important',
        width: '100%',
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            fontSize: '20px',
            fontFamily: "Open sans, regular",
            color: "rgba(36, 36, 36, 1)",
        },
    },
    '& .error-input': {
        '& .MuiInputBase-input::placeholder': {
            color: 'rgb(214, 62, 95)',
            opacity: '1'
        },
    },
    '& .valid-input': {
        '& .MuiInputBase-input::placeholder': {
            color: 'rgb(137, 137, 137)',
            opacity: '1'
        },
    }

})

const LeftPanelWrap = styled('div')({
    marginLeft: '10px'
})

const SummaryHead = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    fontSize: '28px',
    fontFamily: 'Open sans, bold',
    marginBottom: '16px',
    fontWeight: 'bold',
    "@media only screen and (max-width: 1024px)": {
        fontSize: '16px'
    }

})

const PaymentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
})

const ErrorTextSection = styled('div')({
    color: "rgba(214, 62, 95, 1)",
    fontFamily: "Open sans, regular",
    fontSize: '16px',
    fontWeight: 'normal'
})

const PromoContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .input-fieds': {
        borderRadius: '8px !important',
        width: '100%',

        '& .MuiOutlinedInput-input': {
            padding: '14px',
            fontSize: '20px',
            fontFamily: "Open sans, regular",
            color: "rgba(36, 36, 36, 1)",
            "@media only screen and (max-width:1024px)": {
                fontSize: '16px !important',
            },
        },
    },
})

const HeaderWrapper = styled('div')({
    display: 'flex'
})

const PaymentInfoWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    '& >div': {
        color: "rgba(67, 67, 67, 1)",
        fontSize: '22px',
        fontFamily: "Open sans, semibold",
        fontWeight: 'normal',
        "@media only screen and (max-width:1024px)": {
            fontSize: '16px'
        }
    }

})

const ApplyButton = styled('button')({
    width: '130px',
    color: 'white',
    height: '56px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    fontFamily: "Open sans, semibold",
    fontWeight: 'normal',
    "@media only screen and (max-width:768px)": {
        height: '45px'
    }
})

const PurchaseButton = styled('button')({
    width: '100%',
    backgroundColor: '#6d5996',
    marginTop: '20px',
    color: 'white',
    height: '50px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontFamily: "Open sans, semibold",
    fontWeight: 'normal',
    "@media only screen and (max-width:768px)": {
        fontSize: '16px'
    }
})

const PageButton = styled(Button)({
    margin: 0,
    textTransform: 'capitalize',
    marginTop: '20px',
    height: '50px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontFamily: "Open sans, semibold",
    fontWeight: 'normal'
})

const PromoWrapper = styled('div')({
    marginTop: '40px',
    "& >div": {
        color: "rgba(36, 36, 36, 1)",
        fontFamily: "Open sans, regular",
        fontSize: '20px',
        fontWeight: 'normal'
    }
})

const PolicyWrapper = styled('div')({
    marginTop: '40px',
    "& >div": {
        color: "rgba(67, 67, 67, 1)",
        fontFamily: "Open sans, regular",
        fontSize: '16px',
        fontWeight: 'normal'
    }
});

const WaitBox = styled("div")({
   display: "flex", 
   alignItems: "center",
   justifyContent: "center",
   width: "100%", 
   height: "100%", 
   flexDirection: "column"
});

const TextWait = styled("p")({
    fontFamily: "Open sans, regular",
    fontSize: '16px',
    marginBottom: '0px'
});
// Customizable Area End