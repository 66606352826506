import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import { ChangeEvent } from "react";
import { getFromLocal, sendAPIRequest, checkToken } from "../../../components/src/utils";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import { Country } from 'country-state-city';
import i18n from "i18next"
import { loadStripe } from "@stripe/stripe-js";
// Customizable Area End

export const configJson = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any;
  // Customizable Area End
}

// Customizable Area Start
interface FormErrorData {
  promocode: boolean
  firstName: boolean
  lastName: boolean
  street: boolean
  number: boolean
  city: boolean
  country: boolean
  postalCode: boolean
  cardNumber: boolean
  cardName: boolean
  cardDate: boolean
  cvv: boolean
  isChecked: boolean,
}

interface PaymentData {
  requires_action: boolean;
  payment_intent_client_secret: string;
  next_action_url: string;
  client_secret: string;
  type:string;
  subscription_stripe_id:string,
  subscription_plan_id:string,
  status:string,

}

interface FormData {
  promocode: string
  firstName: string
  lastName: string
  companyName: string
  street: string
  number: string
  postalCode: string
  city: string
  country: string
  cardNumber: string
  cardName: string
  cardDate: string
  cvv: string
  countryList: any
  payment_token: string
}

interface SubscriptionData {
  subscription_price: string
  tax: string
  promocode_price: string
  total_price: string
}
interface AppearanceInterface {
  theam: string
}
interface StripeElementsOptions {
  clientSecret: string,
  appearance: AppearanceInterface,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  formData: FormData;
  formErrorData: FormErrorData;
  isVisible: boolean;
  enableFieldName: string;
  isCardOpen: boolean;
  isPaymentPage: boolean;
  isSuccessPage: boolean;
  isModifiedPage: boolean;
  isLingosPurchased: boolean;
  subscriptionPlan: any;
  isChecked: boolean;
  subscritionBill: SubscriptionData;
  options: StripeElementsOptions
  cardErrorMsg: string;
  isLoading: boolean,
  loading: boolean,
  paymentType:any
  smallScreen:boolean,
  openToaster:boolean,
  toasterMessage:string,
  isPresent: boolean;
  intentId: string | null;
  stripe:any |null;
  subcriptionDetails :any |null;
  // Customizable Area End,
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLanguageCallId: string = "";
  getSubscriptionPlansCallId: string = "";
  cardElement: any = null;
  paymentApiCallId: string = '';
  createLingosCallId: string = '';
  donateLingosCallId: string = '';
  updateSubscriptionCallId: string = '';
  GetPaymentDetails: string = '';
  postPaymentApiCallid: string = "";
  static contextType = ToasterContext

  // Customizable Area End

  constructor(props: Props) {
    super(props);


    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formData: {
        promocode: '',
        firstName: '',
        lastName: '',
        companyName: '',
        street: '',
        number: '',
        postalCode: '',
        city: '',
        country: '',
        cardNumber: '',
        countryList: Country.getAllCountries(),
        cardName: '',
        cardDate: '',
        cvv: '',
        payment_token: '',

      },
      subscritionBill: {
        subscription_price: '',
        tax: '0',
        promocode_price: '',
        total_price: ''
      },
      enableFieldName: '',
      formErrorData: {
        promocode: false,
        firstName: false,
        lastName: false,
        street: false,
        number: false,
        postalCode: false,
        city: false,
        country: false,
        cardNumber: false,
        cardName: false,
        cardDate: false,
        cvv: false,
        isChecked: false,
      },
      isVisible: true,
      isCardOpen: false,
      isPaymentPage: true,
      isSuccessPage: false,
      isModifiedPage: false,
      isLingosPurchased: false,
      subscriptionPlan: {},
      isChecked: false,
      options: {
        clientSecret: 'pi_3NzwOtSBHuvtUOM606f9BjaZ_secret_IXKB3PIArMKIK3b4Se2VUJ63J',
        appearance: {
          theam: 'stripe'
        },
      },
      cardErrorMsg: '',
      isLoading: false,
      loading: false,
      paymentType:'',
      smallScreen:window.innerWidth < 768,
      openToaster:false,
      toasterMessage:'',
      isPresent: false,
      intentId: "",
      stripe:null,
      subcriptionDetails:null,
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(

      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      this.responseCall(apiRequestCallId, responseJson, errorReponse);
    }
    // Customizable Area End
  }
  getStripeKey = (evnUrl: string) => {
    if(evnUrl.includes("dev") || evnUrl.includes("stage") || evnUrl.includes("localhost")){
      return "pk_test_51NQYFwEp36RwVXKvQoTSGDz9g3mUvcOT77TyOfHskB8bOcb8hjyYkRlvHZ0j1Lc0ma5Mo4UkueAMJIOYypIS0mgj00XAjqn8f2"
    } return "pk_live_51NQYFwEp36RwVXKvQfsAr1XMU9tdUNAjPqHce0FOzkudFwW0bCmGXQExVEqciahOC9JndLVfbWWexW0inKiCxws300WAKxqqOb"
}


  // Customizable Area Start
  async componentDidMount() {
    const stripePromise = loadStripe(this.getStripeKey(window.location.host));
    const stripe = await stripePromise
    this.setState({stripe:stripe})

    this.handleAuthentication();
    const userStorageString = window.localStorage.getItem('user');
    const type = sessionStorage.getItem('type') || localStorage.getItem('type')

    this.setState({paymentType:type})

    this.handleUserStorage(userStorageString)
    if (this.props.navigation.history.location.state && this.props.navigation.history.location.state.subscriptionPlan) {
      let subscriptionPlanData = this.props.navigation.history.location.state.subscriptionPlan;
      this.setState({ ...this.state, subscriptionPlan: subscriptionPlanData, subscritionBill: { ...this.state.subscritionBill, subscription_price: subscriptionPlanData?.attributes?.price_per_month } })
    }
    this.handlePaymentDetails()
    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
  }

  handleAuthentication = () => {
    const getUrl = window.location
    const urlParams = new URLSearchParams(getUrl.search);
    const isRequired = urlParams.get("fe_required_boolean");
    const paymentIntentId = urlParams.get("payment_intent");
    this.setState({intentId: paymentIntentId})
    if(isRequired){
      this.setState({isPresent: true} , () => {
        this.setState({loading: true});
        this.handlePaymentSuccessApi();
      });
    }
  };

  handleUserStorage(userStorageString: any) {

    if (userStorageString) {

      const userStorage = JSON.parse(userStorageString);

      this.setState({
        formData: {
          ...this.state.formData,
          firstName: userStorage.attributes.first_name,
          lastName: userStorage.attributes.last_name,
          city: userStorage.attributes.city,
          country: userStorage.attributes.country,
        }
      });

    }
  }

  handleCardErrorMessage(event: any) {

    if (event.error && event.error.message) {

      this.setState({ cardErrorMsg: event.error.message })
    } else {
      this.setState({ cardErrorMsg: '' })
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.subscritionBill !== this.state.subscritionBill) {
      if ((prevState.subscritionBill.total_price === this.state.subscritionBill.total_price)) {
        let total_price = 0;
        if (Number(this.state.subscritionBill.promocode_price)) {
          total_price -= Number(this.state.subscritionBill.promocode_price)
        }
        if (Number(this.state.subscritionBill.tax)) {
          total_price += Number(this.state.subscritionBill.tax)
        }
        if (Number(this.state.subscritionBill.subscription_price)) {
          total_price += Number(this.state.subscritionBill.subscription_price)
        }
        this.setState({ ... this.state, subscritionBill: { ...this.state.subscritionBill, total_price: total_price.toString() } })
      }
    }
  }

  async componentWillUnmount() {
    localStorage.removeItem('amount')
    localStorage.removeItem('lingos')
    localStorage.removeItem('subscription_id')
    localStorage.removeItem('LearningLanguage')
    sessionStorage.removeItem('amount')
    sessionStorage.removeItem('lingos')
    sessionStorage.removeItem('LearningLanguage')
  }

  responseCall = (
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: any
  ) => {
    if (apiRequestCallId === this.createLingosCallId) {
      this.handlCreateLingosResponse(responseJson)
    } else if (apiRequestCallId === this.donateLingosCallId) {
      this.handlDonateLingosResponse(responseJson)
    } else if (apiRequestCallId === this.paymentApiCallId) {
       this.handleCreatePayment(responseJson)
    }
    else if (apiRequestCallId === this.updateSubscriptionCallId) {
      this.handleUpdateSubscriptionResponse(responseJson)
    } else if (apiRequestCallId === this.GetPaymentDetails) {
            this.setState({
        formData: {
          ...this.state.formData,
          firstName: responseJson.billing_details.first_name,
          lastName: responseJson.billing_details.last_name,
          city: responseJson.billing_details.address.city,
          country: responseJson.billing_details.address.country,
          street: responseJson.billing_details.address.street,
          postalCode: responseJson.billing_details.address.postal_code,
          number: responseJson.billing_details.address.number
        }
      });
    }
    else if (apiRequestCallId === this.postPaymentApiCallid) {
      this.handlePaymentSuccessCallBack(responseJson)
    } 
  };

  handlDonateLingosResponse = (responseJson: any) => {
    if (responseJson && responseJson.message && responseJson.message.includes("you have successfully donated")) {
      /* istanbul ignore next */
      sessionStorage.removeItem("amount")
      sessionStorage.removeItem("lingos")
      sessionStorage.removeItem("type")
      this.setState({ isPresent: false, loading: false});
      this.props.navigation.history.replace({ pathname: "/LingosDonated" })
    } else {
      this.setState({openToaster:true})
      this.setState({ isPresent: false, loading: false});
      this.parseApiErrorResponse(responseJson)
    }
  }

  handlePayment = (responseJson: any,type:string) => {
    if (responseJson && responseJson.status == 'succeeded') {
      if (type == "buy_lingos") {
        this.handleCreateLingos()
      } else if (type == "donate_lingos") {
        this.handleDonateLingos()
      }else if(type=='buy_membership'){
        this.setState({ isSuccessPage: true })

      }
      this.setState({loading: false})
      this.setState({ isLoading: false })
    }
    else {
      this.handleResponseError(responseJson)
      this.setState({ isLoading: false,openToaster:true })
    }
  }

  handleResponseError = (responseJson: any) => {
    this.onTokenError(responseJson, "")
    this.setState({openToaster:true})
    this.parseApiErrorResponse(responseJson);
  }

  closeToaster = () => {
    this.setState({openToaster: false})
  }

  onTokenError = (responseJson: any, AlertBodyMessage: any) => {
    const userData: any = JSON.parse(localStorage.getItem('user') || '')
    const toasterFunction = {
      toggleToaster: this.context?.toggleToaster,
      handleToaster: this.context?.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, userData?.type)
  }

  hanldleErrorTextColor = (error: boolean) => error ? 'rgba(214, 62, 95, 1)' : 'rgba(67, 67, 67, 1)';

  handlePromocode = (event: any) => {
    this.setState({ ...this.state, formData: { ...this.state.formData, promocode: event.target.value } })
  }

  handleCardOpen = (value: boolean) => {
    this.setState({ ...this.state, isCardOpen: value })
  }

  handleCardFieldChange = (e:any,fieldName:'cardDate'|'cvv')=>{
    if(e.complete){
      if(fieldName=='cardDate'){
        this.setState({formErrorData:{...this.state.formErrorData,cardDate:false}})

      }else{
        this.setState({formErrorData:{...this.state.formErrorData,cvv:false}})

      }
  }else{
    if(fieldName=='cardDate'){

      this.setState({formErrorData:{...this.state.formErrorData,cardDate:true}})
    }else{
      this.setState({formErrorData:{...this.state.formErrorData,cvv:true}})

    }
  }
  }

  handleChecked = (value: boolean) => {
    this.setState({ ...this.state, isChecked: value,formErrorData:{...this.state.formErrorData,isChecked:!value} })
  }

  handleApplyButtonColor = (promo: string) => promo ? '#6d5996' : '#d1d1d1'

  applyPromocode = () => {
    this.setState({ ...this.state, isVisible: false, formErrorData: { ...this.state.formErrorData, promocode: (this.state.formData.promocode) ? false : true } })
  }

  getColor = (formErrorData:any,formData:any,type:any)=>{
      if(!formErrorData.country && !formData.country){
          return "rgb(137, 137, 137)"
      }else if(!formData.country && formErrorData.country){
          return "rgb(214, 62, 95)"
      }else {
          return ""
      }
}

  setEnableField = (value: string) => {
    this.setState({ enableFieldName: this.state.enableFieldName == value ? '' : value });
  };

  setProfileValue = (name: string, text: any) => {
    let form = { ...this.state.formData, [name]: text }
    this.setState({ ...this.state, enableFieldName: '', formData: form });
  };

  handlePromoApply = () => this.setState({ ...this.state, isVisible: false, subscritionBill: { ...this.state.subscritionBill, promocode_price: '5' } })

  removePromocode = () => this.setState({ ...this.state, formData: { ...this.state.formData, promocode: '' }, isVisible: true })

  handleTakeTest = () => { this.props.navigation.history.push( "/AssessmentTest",{state: { message: "Payment is Successful!" } }) }

  handleBookClasses = () => { this.props.navigation.navigate('BookClasses') }

  handleBack = () => { this.props.navigation.goBack() }

  handleHasError = (array: any) => {
    /* istanbul ignore next */
    let formArray = Object.values(array)
    let tempFormArr: any = [];
    formArray.forEach((item: any) => {
      if (typeof item === 'object' && item !== null) {
        let tempObj = Object.values(item)
        tempFormArr.push(...tempObj)
      } else {
        tempFormArr.push(item)
      }
    })
    if (tempFormArr.includes('true') || tempFormArr.includes(true)) {
      return true
    } else {
      return false
    }

  }

  handleCVV = (name: string, event: any) => {
    let value = event.target.value;
    if (value.length <= 4) {
      this.setState({ ...this.state, formData: { ...this.state.formData, [name]: value } })
    }

  }


  handleCardDate = (name: string, event: any) => {
    let value = event.target.value;
    let values = () => {
      if (parseInt(value) <= 12 && !this.state.formData.cardDate.includes('/')) {
        return value + '/';
      } else if (this.state.formData.cardDate.includes('/')) {
        return this.state.formData.cardDate.substring(0, this.state.formData.cardDate.length - 1)
      } else {
        return this.state.formData.cardDate
      }
    }
    if (value.length <= 5) {
      if (value.length == 2) {
        this.setState({ ...this.state, formData: { ...this.state.formData, [name]: values() } })
      } else {
        this.setState({ ...this.state, formData: { ...this.state.formData, [name]: value } })
      }
    }
  }

  handleInput = (name: string, value: any, inputType: string) => {
    if(name=="street"|| name=="cardName") {
      if(configJson.checkAlphabets.test(value))
      this.setState({ ...this.state, formData: { ...this.state.formData, [name]: value } })
    }
    else if ((inputType !== 'number') || (inputType === 'number' && (parseInt(value) || value === ""))) {
      this.setState({ ...this.state, formData: { ...this.state.formData, [name]: value } })
    }
  }

  handleNumber = (name: string, event: any) => {
    let value = event.target.value;
    if (/^[\d]*$/.test(value) &&value.length <= 10) {
      this.setState({ ...this.state, formData: { ...this.state.formData, [name]: value } })
    } 
  }

  handleCardNumber = (name: string, event: any) => {
    let value = event.target.value;
    if (Number(value) && value.length <= 16) {
      this.setState({ ...this.state, formData: { ...this.state.formData, [name]: value } })
    }
  }

  handleInputFields = (inputName: string, event: ChangeEvent<HTMLInputElement>, inputType: string) => {
    /*istanbul ignore next*/
    if (inputType === 'cvv') {
      this.handleCVV(inputName, event)
    } else if (inputName === 'number') {
      this.handleNumber(inputName, event)
    }  else if (inputType === 'cardDate') {
      this.handleCardDate(inputName, event)
    } else if (inputType === 'cardNumber') {
      this.handleCardNumber(inputName, event)
    } else {
      this.handleInput(inputName, event.target.value, inputType)
    }
  }

  handlCreateLingosResponse = (responseJson: any) => {
    if (responseJson.message && responseJson.message.includes("successfully purchased")) {
      /* istanbul ignore next */
      sessionStorage.removeItem("amount")
      sessionStorage.removeItem("lingos")
      sessionStorage.removeItem("type")
      this.setState({ isPresent: false, loading: false});
      this.props.navigation.history.replace({ pathname: "/LingosPurchased" })
    } else {
      this.setState({openToaster:true})
      this.parseApiErrorResponse(responseJson)
      this.setState({ isPresent: false, loading: false});
    }
  }

  handleCreateLingos = () => {
    const amount: any = sessionStorage.getItem("amount")
    const lingosCount:any = window.sessionStorage.getItem('lingos') || window.localStorage.getItem('lingos')
    if (+amount) {
      this.createLingosCallId = sendAPIRequest(`${configJson.createLingosEndPoint}?lingos_quantity=${+lingosCount}&purchase_price=${amount}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: getFromLocal("authToken")
        },
      });

    }
  }

  handleDonateLingos = () => {
    const amount: any = sessionStorage.getItem("amount")
    if (+amount) {
      this.donateLingosCallId = sendAPIRequest(`${configJson.donateLingosEndPoint}?donation_amount=${amount}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: getFromLocal("authToken")
        },
      });

    }
  }
  updateSubscription() {

    this.setState({ isLoading: true })

    const requestBody = {
      "subscription_plan_id": this.props.navigation.history.location.state.subscriptionId,
      "payment_method_id": "test",
      "company_name": this.state.formData.companyName,
      "street": this.state.formData.street,
      "number": this.state.formData.number,
      "postal_code": this.state.formData.postalCode,
      "city": this.state.formData.city,
      "country": this.state.formData.country
    }
    this.updateSubscriptionCallId = sendAPIRequest(
      configJson.updateSubscriptionEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/json",
          token: getFromLocal("authToken")
        },
        body: requestBody
      }
    );
  }

  handleUpdateSubscriptionResponse(responseJson: any) {
    this.setState({ isLoading: false })
  }

  handleChange(e: any) {
    this.setState({ ...this.state, formData: { ...this.state.formData, country: e.target.value } })
  }

  paymentApi() {
    this.setState({ isLoading: true })
    this.setState({ loading : true})
    const headers = {
      'Content-Type': "application/json",
      token: getFromLocal("authToken")
    }
    let params = {
      "amount": window.sessionStorage.getItem('amount') || window.localStorage.getItem('amount'),
      "first_name": this.state.formData.firstName,
      "last_name": this.state.formData.lastName,
      "payment_token": this.state.formData.payment_token,
      'primary_card': this.state.isChecked,
      "plan_details": window.sessionStorage.getItem('lingos') || window.localStorage.getItem('lingos'),
      "address": {
        "city": this.state.formData.city,
        "postal_code": this.state.formData.postalCode,
        "country": this.state.formData.country,
        "street": this.state.formData.street,
        'number': this.state.formData.number
      }
    }

    const subscriptionId = localStorage.getItem("subscription_id")

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.paymentApiCallId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_payments/payment_created',
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    )

    if (subscriptionId) {
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          ...params,
          subscription_plan_id: subscriptionId,
          type: 'Buy Subscription'
        }),
      )

    } else {
      const typeLingo = sessionStorage.getItem("type")
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          ...params,
          type: typeLingo == "buy_lingos" ? "Buy Lingo" : "Donate Lingo"
        }),
      )
    }


    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

  }

  handlePaymentDetails = () => {
    const header = {
      'Content-Type': "application/json",
      token: getFromLocal("authToken")
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetPaymentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_payments/get_details_of_user`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = getFromLocal("authToken");
    let header = {
      "Content-Type": contentType,
      token,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handlePaymentSuccessApi = async () => {
    let apiBody= {
      payment_intent: this.state.intentId,
      type:"",
      subscription_plan_id:"",
      subscription_stripe_id:""
    };
    const subscriptionDetails = this.state.subcriptionDetails;
    if(subscriptionDetails){
      apiBody = {
        payment_intent: this.state.intentId,
        type : subscriptionDetails?.type || "",
        subscription_plan_id:subscriptionDetails?.subscription_plan_id ||"",
        subscription_stripe_id: subscriptionDetails?.subscription_stripe_id ||"",
      };
    }

    this.postPaymentApiCallid = await this.apiCall({
      contentType: configJson.apiContentType,
      method: configJson.confirmPaymentMethod,
      endPoint: configJson.postPaymentEndpoint,
      body: JSON.stringify(apiBody),
    });
  };
   
  handlePaymentSuccessCallBack = (response: {status: string}) => {
    this.setState({
      isPresent: false,
      loading: false
    } , () => {
      this.handlePayment(response,this.state.paymentType) 
    });
  };


  handleCreatePayment = async (response: PaymentData | null | undefined) => {
    try {
      this.setState({ loading: false, isLoading: false });
  
      if (!response) return;
  
      if (response.type === "subscription") {
        await this.handleSubscriptionPayment(response);
      } else if (response.type === "lingo") {
         this.handleLingoPayment(response);
      }
    } catch (error) {
      this.redirectToPayment();
    }
  };
  
  handleSubscriptionPayment = async (response: PaymentData) => {
    if (response.status === "succeeded") {
      this.setState({ isSuccessPage: true });
    } else if (response.status === "failed") {
      this.redirectToPayment();
    } else {
      await this.confirmSubscriptionPayment(response);
    }
  };
  
  confirmSubscriptionPayment = async (response: PaymentData) => {
    const subcriptionDetails = {
      type: "subscription",
      subscription_stripe_id: response.subscription_stripe_id,
      subscription_plan_id: response.subscription_plan_id,
    };
  
    this.setState({ subcriptionDetails });
  
    const stripe = this.state.stripe;
    const cardElement = this.cardElement;
    const clientSecret = response.payment_intent_client_secret;
  
    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
  
      if (error || paymentIntent?.status !== "succeeded") {
        this.redirectToPayment();
      } else {
        this.setState({ intentId: paymentIntent.id });
        this.handlePaymentSuccessApi();
      }
    } catch (err) {
      this.redirectToPayment();
    }
  };
  
  handleLingoPayment = (response: PaymentData) => {
    if (response.status === "succeeded") {
      this.handleCreateLingos();
      this.props.navigation.history.replace({ pathname: "/LingosPurchased" });
    } else if (response.status === "failed") {
      this.redirectToPayment();
    } else if (response.next_action_url) {
      const correctedUrl = response.next_action_url.replace(/\\u0026/g, "&");
      window.location.href = correctedUrl;
    }
  };
  
  redirectToPayment = () => {
    window.location.href = "/payment?fe_required_boolean=true&payment_intent=";
  };
  

  // Customizable Area End
}
