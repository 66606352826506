// Customizable Area Start
import React from "react";
import CalenderSelection from "./CalenderSelection.web";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Box,
  styled,
  withStyles,
} from "@material-ui/core";
import CustomDate from "../../../components/src/CustomDate.web";
import { addLessonIcon, dob, downArrow2 } from "./assets";
import UpdateAvailConfirmationModal from "./UpdateAvailConfirmationModal.web";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import CustomCalendarModal1 from "../../../components/src/CustomCalendarModal1.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import UpdateAvailabilityController from "./UpdateAvailabilityController.web";
import { DeleteBlue } from "../../appointmentmanagement/src/assets";
import { withTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  startDate: Yup.string().required("Start date is required"),
  weeks: Yup.array().of(
    Yup.object().shape({
      initials: Yup.string().required("Initials are required"),
      week: Yup.string().required("Week is required"),
      isSelected: Yup.boolean().required("Selection is required"),
    })
  ),
  selectedDay: Yup.array(),
  ends: Yup.string().required("end date is required"),
});

const styles = (theme: any) => ({
  customSelect: {
    paddingRight: "14px !important", // or paddingRight: 'initial',
    [theme.breakpoints.down("sm")]: {
      padding: "13px 13px",
    },
  },
});

export class UpdateAvailability extends UpdateAvailabilityController {
  initialValues = {
    startDate: new Date(),
    weeks: [{ initials: "Mon", week: "monday",  isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false }] },
    { initials: "Tue", week: "tuesday",  isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false }] },
    { initials: "Wed", week: "wednesday",  isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false }] },
    { initials: "Thu", week: "thursday", isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false }] },
    { initials: "Fri", week: "friday",  isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false }] },
    { initials: "Sat", week: "saturday",  isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false }] },
    { initials: "Sun", week: "sunday",  isSelected: false, fromToArr: [{ id: "0", from: "", to: "", isOpen: false}] }],
    selectedDay: [],
    ends: "One year",
  };
  IconComponent = ({type,index,pIndex}:any,{...rest}) => (
    <img
    data-test-id='img'
    {...rest}
      style={{cursor:'pointer',paddingTop:'8px'}}
      onClick={()=>this.setState((prevState)=>({ dropFour: {...prevState.dropFour,isOpen:true,index:index,type:type,pIndex:pIndex} }))}
      src={downArrow2}
      className="time_dropdown_icon"
    />
  );

  getErrorMessage = (msg:string) => msg && <p style={{color:'red',textAlign:'center'}}>{msg}</p>

  render() {
    const { classes, t } = this.props;
    if (this.state.loading1) {
      return <div></div>;
    }
    return (
      <UpdateAvailabilityWrapper>
        <CustomTeacherNavigationLayout
          profileUrl={""}
          screenName={"UpdateAvailability"}
          params={""}
          navigation={this.props.navigation}
        />
        <div
          className="main_title_wrapper"
        >
          <div
            className="main_title"
          >
            {t("Update Availability")}
          </div>

        </div>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <span
              className="subtitle"
            >
              {t("Update your general time availablity to give lessons. Students can book then 1-on-1 classes with you and your availability will also be visible to our team.")}
            </span>
          </div>
          
        </div>
        <div
            data-test-id='viewSavedAvailability'
            className="viewAvailabiltyText"
            onClick={() => {
             
              this.props.navigation.navigate('SavedAvailability')
            }}
          >
            <div style={{marginRight:'10%'}}>
            {t("View saved availability")}
            </div>
          </div>
        <Container style={{ marginTop: "25px",maxWidth:'1380px' }} className="main_container">
          <Formik
            initialValues={this.initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            data-test-id="formik"
          >
            {({ values, handleChange, setFieldValue }) => (
              <>
                <Form>
                  <MainContainer >
                    <LeftContainer className="calender_item">
                      <CalenderSelection
                        navigation={undefined}
                        id={""}
                        open={this.state.toggle}
                        closeModal={undefined}
                        data-test-id="calenderselection"
                        handleUpdateAVilaibilityDateSelection={
                          this.handleUpdateAVilaibilityDateSelection
                        }
                        handleCalendarDateWithDay={() =>
                          this.handleCalendarDateWithDay(values, setFieldValue)
                        }
                        classes={undefined}
                        calendarFunction={()=>{}}
                        calendarHighlightedDays={""}
                        t={t}
                        i18n={this.props.i18n}
                      />
                    </LeftContainer>
                    <RightContainer className="bottom_container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="updateAvailabiltyText">
                          {t("Select time slot for the selected date")}
                        </div>
                      </div>

                      <div style={{ margin: "25px 0px",width:'200px' }}>
                        <CustomDate wrapperClass="hide_date_icon" value={this.state.dateSelectedByTeacher}
                        name='From' disabled={false}
                        selctDate={(name: string, value: any) => this.handleDateChage(value, setFieldValue, values)}
                        inputProps={{ 'min': new Date().toISOString().split('T')[0] }} t={t} open={this.state.open} openState={this.calenderClose} calenderOpen={this.calenderOpen}/>
                        
                        <div style={{color:'red'}}>{t(this.state.errorForStartDate)}</div>
                      </div>

                      <div>
                        {values.weeks.map((day: any, index: any) => {
                          return (
                            <div style={{ display: "flex" }}>
                              <div
                                data-test-id="weekInitials"
                                onClick={() => {
                                  this.handleSelect1(
                                    day,
                                    values,
                                    setFieldValue
                                  );
                                }}
                                key={day.week}
                                style={{
                                  ...webStyles.weeks,
                                  borderRadius: this.checkIndex1(index),
                                  borderLeft: this.conditionForBorderLeft1(
                                    index,
                                    day
                                  ),
                                }}
                                className={
                                  this.handleClassName(day.isSelected)
                                }
                              >
                                {t(day.initials)}
                              </div>
                              <Grid
                                container
                                spacing={2}
                                className="time_select"
                              >
                                <FieldArray name={`weeks[${index}].fromToArr`}>
                                  {({
                                    push,remove
                                  }: {
                                    push: (value: any) => void;
                                    remove: (value: any) => void;
                                  }) => (
                                    <>
                                      {day.fromToArr.map(
                                        (item: any, idx: any) => {
                                          return (
                                            <>
                                              <Grid
                                                className="first_time_select"
                                                item
                                              >
                                                <Select
                                                  variant="outlined"
                                                  data-test-id="from"
                                                  open={this.state.dropFour.isOpen && this.state.dropFour.type=="from_time" && this.state.dropFour.index==idx && this.state.dropFour.pIndex==index}
                                                  IconComponent={({...rest})=>this.IconComponent({type:'from_time',index:idx,pIndex:index},{...rest})}
                                                  onOpen={() => this.setState((prevState)=>({ dropFour: {...prevState.dropFour,isOpen:true,index:idx,type:'from_time',pIndex:index} }))}
                                                  onClose={() => this.setState((prevState)=>({ dropFour: {...prevState.dropFour,isOpen:false,index:"",type:'',pIndex:''} }))}

                                                  classes={{
                                                    outlined:
                                                      classes.customSelect,
                                                  }}
                                                  name={`weeks[${index}].fromToArr[${idx}].from`}
                                                  value={item.from}
                                                  style={{

                                                  }}
                                                  className='select_time'
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `weeks[${index}].fromToArr[${idx}].from`,
                                                      e.target.value
                                                    );
                                                    setFieldValue(`weeks[${index}].fromToArr[${idx}].to`,"")
                                                  }}
                                                  MenuProps={{
                                                    PaperProps: {
                                                      style: {
                                                        maxHeight: 200, // Set the max height of the menu container
                                                        overflowY: "auto", // Enable vertical scrollingv
                                                        width: "130px",
                                                        border:
                                                          "1px solid #D1D1D1",
                                                        boxShadow: "none",
                                                        top: "284px",
                                                        marginTop: "-4px",
                                                        borderRadius:
                                                          "0px 0px 10px 10px",
                                                      },
                                                    },
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                    },

                                                    getContentAnchorEl: null,
                                                    disableScrollLock: true,
                                                  }}
                                                  displayEmpty
                                                >
                                                  <MenuItem value="">
                                                    <span
                                                      style={{
                                                        color: "#898989",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {t("From")}
                                                    </span>
                                                  </MenuItem>
                                                  {this.getFromTimeList(index,idx).map(
                                                    (time: any, idx1: any) => {
                                                      return (
                                                        <MenuItem
                                                        data-test-id='fromTime'
                                                          key={idx1}

                                                          onClick={() => {
                                                            this.setState({ fromTime: time.time })
                                                            let obj = {
                                                              from: time.time, to: ''
                                                            }

                                                            this.handleFromToTime(index, obj)
                                                          }}
                                                          style={{
                                                            borderBottom:
                                                              "1px solid #D1D1D1",
                                                          }}
                                                          value={time.time}
                                                        >
                                                          {time.time}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                                <Select
                                                  variant="outlined"
                                                  data-test-id="to"
                                                  open={this.state.dropFour.isOpen && this.state.dropFour.type=="to_time" && this.state.dropFour.index==idx && this.state.dropFour.pIndex==index}
                                                  IconComponent={({...rest})=>this.IconComponent({type:'to_time',index:idx,pIndex:index},{...rest})}
                                                  onOpen={() => {
                                                    const newData = this.handleFunctionForOpenToTime(index,idx)
                                                    
                                                   this.handleToTime(index,newData)
                                                  
                                                    this.setState((prevState)=>({ dropFour: {...prevState.dropFour,isOpen:true,index:idx,type:'to_time',pIndex:index} }))
                                                  }}
                                                  onClose={() => this.setState((prevState)=>({ dropFour: {...prevState.dropFour,isOpen:false,index:"",type:'',pIndex:''} }))}

                                                  name={`weeks[${index}].fromToArr[${idx}].to`}
                                                  value={item.to}
                                                  className='select_time second'
                                                  onChange={handleChange}
                                                  classes={{
                                                    outlined:
                                                      classes.customSelect,
                                                  }}
                                                  MenuProps={{
                                                    PaperProps: {
                                                      style: {
                                                        maxHeight: 200, // Set the max height of the menu container
                                                        overflowY: "auto", // Enable vertical scrollingv
                                                        width: "130px",
                                                        border:
                                                          "1px solid #D1D1D1",
                                                        boxShadow: "none",
                                                        top: "284px",
                                                        marginTop: "-4px",
                                                        borderRadius:
                                                          "0px 0px 10px 10px",
                                                      },
                                                    },
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                    },

                                                    getContentAnchorEl: null,
                                                  }}
                                                  displayEmpty
                                                >
                                                  <MenuItem value="">
                                                    <span
                                                      style={{
                                                        color: "#898989",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {t("To")}
                                                    </span>
                                                  </MenuItem>
                                                  
                                                  {this.getToTimeList(index).map(
                                                    (time: any, idx2: any) => {
                                                      return (
                                                        <MenuItem
                                                        data-test-id='toTime'
                                                          key={idx2}
                                                          className="time_menu"
                                                          disabled={
                                                            this.fromArr.indexOf(item.from)+2>idx2
                                                            
                                                          }
                                                          value={time.time}
                                                          onClick={() => {
                                                            
                                                            const tempArr = this.getSelectedArray(index)||[]
                                                            const updatedArr = [...tempArr];
                                                            updatedArr[idx] = {
                                                              ...updatedArr[idx],
                                                              to: time.time
                                                            };
                                                            this.handleToTime(index, updatedArr)
                                                          }}
                                                        >
                                                          {time.time}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </Grid>

                                              {item.id ===
                                                day.fromToArr[
                                                  day.fromToArr.length - 1
                                                ].id && (
                                                  <>
                                                  <Grid
                                                    className="add_more_icon_wrapper" item lg={4} md={12} sm={12}
                                                    data-test-id="addMoreTimes"
                                                    onClick={() =>
                                                      this.handleMoreTime(
                                                        day,
                                                        push,
                                                        values, index,idx
                                                      )
                                                    }
                                                  >
                                                    <div className="icon_wrapper">
                                                      <img className="select_time_icon" src={addLessonIcon} width={"22px"} height={"22px"} />
                                                    </div>
                                                    <div>
                                                      <span className="add_more_time">
                                                        {t("Add more times")}
                                                      </span>
                                                    </div>
                                                  </Grid>
                                                    &nbsp;&nbsp;
                                                  {day.fromToArr.length>1&&<div
                                                   className="add_more_icon_wrapper"
                                                  data-test-id='deleteButton'
                                                   onClick={()=>{remove(idx)}}>
                                                    <div
                                                      className="icon_wrapper"
                                                    >
                                                      <img
                                                        className="select_time_icon"
                                                        src={DeleteBlue}
                                                        width={"22px"}
                                                        height={"22px"}
                                                      />
                                                    </div>
                                                    <div><span className="add_more_time">{t("Delete")}</span></div>
                                                    </div>}
                                                </>)}</>
                                          );})}
                                    </>
                                  )}
                                </FieldArray>
                              </Grid>
                            </div>
                          );
                        })}
                      </div>
                      <FormLabel className="end_text"> {t("Ends")}</FormLabel>
                      <div className="end_date">
                        <FormControl className="end_date_wrapper">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="ends"
                            defaultValue={"One year"}
                          >
                            <FormControlLabel
                              value="One year"
                              control={<StyledRadio onChange={handleChange} />}
                              checked={values.ends=='One year'}
                              label= {t("One year")}
                            />
                            <FormControlLabel
                              value="On"
                              control={<StyledRadio onChange={handleChange} />}
                              checked={values.ends=='On'||values.ends!=='One year'}
                              label={t("On")}
                            />
                          </RadioGroup>
                        </FormControl>

                        <StyledDateButton
                          data-test-id="dateButton"
                          onClick={() => {
                            if(this.state.dateSelectedByTeacher){this.setState({ isMonth: false });}
                            else{
                              this.setState({errorForStartDate: t('Please select start date')})
                            }
                          }}
                          disabled={this.isButtonDisabled(values)}
                        >
                          {t(this.getValueForEndsOn(values))}
                        </StyledDateButton>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0px",
                        }}
                      >
                        <StyledButton disabled={this.isSaveButtonDisabled(values)} data-test-id="saveUpdates" type="submit">
                          {t("Save My Updates")}
                        </StyledButton>
                      </div>
                        {this.getErrorMessage(this.state.errorMsg)}
                    </RightContainer>
                  </MainContainer>
                </Form>
                <CustomCalendarModal1
                  openCalendarModal={this.state.isMonth}
                  setEndDate={setFieldValue}
                  closeCalendarModal={this.closeCalendarModal}
                  dateSelectedByTeacher={this.state.dateSelectedByTeacher}
                  
                />
              </>
            )}
          </Formik>
          
        </Container>
        <UpdateAvailConfirmationModal
          navigation={this.props.navigation}
          id={""}
          open={this.state.openUpdateAvailModal}
          closeModal={this.closeModal}
          handleUpdateAVilaibilityDateSelection={undefined}
          classes={undefined}
          handleCalendarDateWithDay={undefined}
          calendarFunction={undefined}
          calendarHighlightedDays={undefined}
          t={t}
          i18n={this.props.i18n}
        />
      </UpdateAvailabilityWrapper>
    );
  }
}

const UpdateAvailabilityWrapper = styled(Box)({

  '& .main_title_wrapper': {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",

    "@media(max-width:600px)": {
      marginTop: '28px'
    },
  },

  '& .main_title': {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#434343",
    fontFamily: "Open sans",

    "@media(max-width:600px)": {
      fontSize: '20px'
    },
  },

  '& .viewAvailabiltyText': {
    // position: "absolute",
    // bottom: '-40px',
    // left: "79%",
    width:'100%',
    display:'flex',
    justifyContent:'end',
    cursor: 'pointer',
    "@media(max-width:600px)": {
      fontSize: '16px',
      // bottom: '16px',
      // right: '16px',
      // left: 'auto'
    },
  },

  '& .subtitle': {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#434343",
    fontFamily: "Open sans",
    width: '81%',
    "@media(max-width:600px)": {
      padding: '10px 20px 56px',
    },
    "@media(max-width:900px)": {
      fontSize: '16px',
      width: '100%',
      padding: '10px 20px 56px',
      textAlign: 'justify'
    },
  },
  '& .main_container':{
    "@media(max-width:320px)": {
      paddingRight:'5px',
      paddingLeft:'5px'
    }
  },

  '& .calender_item': {
    "@media(max-width:600px)": {
      padding: '0',
      width: '100%'
    },
    "@media(min-width:1320px)": {
      paddingRight:'40px'
    }
  },

  '& .bottom_container': {
      width: '100%',
    "@media(max-width:600px)": {
      width: '100%',
    },
    "@media(max-width:360px)": {
      padding: 'unset',
    },
    "@media(max-width:1024px)": {
      marginTop:'20px',
    },

    '& .react-date-picker__wrapper': {
      "@media(max-width:600px)": {
        width: '153px',
        height: "40px",
        fontSize: '14px',
        fontWeight: 500
      },
    },

    '& .calendar_icon': {
      "@media(max-width:600px)": {
        width: '18px',
        height: '20px'
      },
    }
  },

  '& .updateAvailabiltyText': {
    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  },

  '& .selectedClass': {
    backgroundColor: "#6D5B96",
    color: "white",
    borderRight: "1px solid #6D5B96",
    borderTop: "1px solid #6D5B96",
    borderBottom: "1px solid #6D5B96",

    "@media(max-width:600px)": {
      width: '66px',
      minHeight: '46px',
      minWidth: '0 !important'
    },
  },

  '& .nonSelectedClass': {
    backgroundColor: "white",
    color: "black",
    borderRight: "1px solid #D1D1D1",
    borderTop: "1px solid #D1D1D1",
    borderBottom: "1px solid #D1D1D1",

    "@media(max-width:600px)": {
      width: '66px',
      minHeight: '46px',
      minWidth: '0 !important'
    },
    "@media(max-width:350px)": {
     fontSize: "14px"
    },
  },

  "& .time_select": {
    margin: "0px 0px 0px 20px",
    "@media(max-width:600px)": {
      display: 'flex',
      justifyContent: 'space-between',
      margin: "0px 0px 0px 12px",
      flexWrap: "wrap"

    },
    "@media(max-width:1024px)":{
      flexWrap: "wrap"
    },

    "@media(max-width:375px)": {
      margin: "0px 0px 0px 5px",

    },
  },

  "& .first_time_select": {
    display: 'flex',
    "@media(max-width:600px)": {
      flex: '3 1 auto',
      padding: '5px',
      columnGap: '5%'
    },
  },

  '& .add_more_time': {
    color: "#0078a7",
    fontSize: "16px",
    fontWeight: 600,
    "@media(max-width:630px)": {
      display: 'none'
    },
  },

  '& .select_time': {
    width: "160px",
    height: "48px",
    border: "0px solid #D1D1D1",
    borderRadius: "5px",
    "@media only screen and (min-width: 1025px) and (max-width:1088px)":{
      width: "150px",

    },
    "@media(max-width:820px)": {
      width: '135px',

    },
    "@media(max-width:600px)": {
      width: '100px',
      height: '36px',
      flex: '3 1 100px',
      fontSize: '14px',
      lineHegiht: '20px'
    },
    "@media(max-width:320px)": {
      width: '80px',
    },
    "@media(max-width:280px)": {
      width: '65px', 
      },
  },

  '& .time_menu': {
    borderBottom: "1px solid #D1D1D1",
    "@media(max-width:600px)": {
      fontSize: '14px'
    },
  },

  "& .second": {
    marginLeft: '20px',
    "@media(max-width:600px)": {
      marginLeft: '0'
    },
  },

  '& .add_more_icon_wrapper': {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft:'10px',
    "@media(max-width:600px)": {
      padding: '0 0px 0px 10px'
    },
  },

  '& .icon_wrapper': {
    marginRight: '10px',
    "@media(max-width:600px)": {
      marginRight: '0px'
    },
  },

  '& .time_dropdown_icon': {
    width: '18px',
    height: '9px',
    marginRight: '12px',
    "@media(max-width:600px)": {
      width: '13px',
      height: '8px'
    },
  },

  '& .select_time_icon': {
    width: '22px',
    height: '22px',
    "@media(max-width:600px)": {
      width: '20px',
      height: '20px'
    },
  },
  "& .end_text": {
    marginTop: '20px',
    fontWeight: 600,
    color: "black",
    fontFamily: "Open sans",
    display: 'inline-block'
  },

  '& .end_date': {
    margin: "10px 0px 75px 0px",
    display: "flex",
    alignItems: "end",
    "@media(max-width:600px)": {
      margin: '0px 16px 28px 16px',
      justifyContent: 'space-between'
    },
    "@media(max-width:280px)": {
      flexWrap: 'wrap',
    },
  },

  '& .end_date_wrapper': {
    "@media(max-width:600px)": {
      flex: '2 1 auto'
    },
  }


})
const StyledRadio = styled(Radio)(({ theme }) => ({
  color: "#D4D4D4", // Set the desired color here
  "&.Mui-checked": {
    color: "#6D5B96", // Set the color for the checked state
  },
  "&.Mui-checked:hover": {
    backgroundColor: "transparent", // Set the background color for the checked state on hover
  },
  "& .MuiSvgIcon-root": {
    fontSize: "30px", // Increase the size of the checkbox icon
    "@media(max-width:600px)": {
      fontSize: '24px'
    },
  },
  
}));

const MainContainer = styled(Box)({
  display:'flex',
  "@media(max-width:1024px)":{
    flexWrap:'wrap'
  }

})

const LeftContainer = styled(Box)({

})

const RightContainer = styled(Box)({
  "& .calendarIcon":{
    
      width: "20px",
      height: "20px",
      right: "10px",
      position: "relative"
    
  }
})

const StyledButton = styled(Button)({
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  textTransform: "capitalize",
  padding: "15px 55px",
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
});
const StyledDateButton = styled(Button)({
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  textTransform: "capitalize",
  padding: "10px 15px",
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
  "&:disabled": {
    backgroundColor: "#D1D1D1",
    color: "white",
  },

  "@media(max-width:600px)": {
    flex: '3 1 auto',
  },
});
const webStyles = {
  weeks: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "66px",
    cursor: "pointer",
    fontFamily: "Open sans",
    fontWeight: 600,
  } as React.CSSProperties,
};

export default withTranslation()(withStyles(styles)(UpdateAvailability))
// Customizable Area End
