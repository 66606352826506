import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkToken, sendAPIRequest } from "../../../components/src/utils";
import { isBefore } from "date-fns";
import moment from "moment";
import i18n from "i18next";
import {DateTime} from "luxon";

interface LocalTimeZone{
  attributes:{
    time_zone:string;
  };
}

interface Slot {
  start_time: string;
  end_time: string;
}
interface DaySlots {
  day: string;
  slots: Slot[];
  date?: string;
}
interface Body {
  availability_date: string;
  time_slots: DaySlots[];
  end_date?: string;
}
interface ConvertedSlot extends Slot {
  day: string;
  date: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  handleCalendarDateWithDay: any;
  calendarFunction: any
  calendarHighlightedDays: any
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isMonth: boolean;
  openModalForClassBooking: boolean;
  lessons: any[];
  weeks: any[];
  slots: any[];
  selectedDob: any;
  languageTaught: any;
  selectedDaysFromWeek: any[];
  initiallyBookClassesState: boolean;
  dateSelectedByTeacher: any;
  openUpdateAvailModal: boolean;
  selectedCheckboxes: any[];
  createCourseError: boolean;
  arr1: any[];
  arr2: any[];
  arr3: any[];
  arr4: any[];
  arr5: any[];
  arr6: any[];
  arr7: any[];
  dayStr: any;
  prefilledLanguage: any;
  userData: any;
  languagesArr: any;
  language: any;
  isStartTimeClicked: boolean;
  selectedTimes: any[];
  dayId: any;
  startDate: any;
  isError: boolean;
  dropFour: any;
  fromTime: any;
  toTime: any;
  toggle: boolean;
  errorForStartDate: string;
  errorMsg:string;
  dateFormate: string;
  open: boolean;
  timeZoneData: string;
  loading1:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpdateAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  createNewClassApiCallId: any;
  createavailablityCallId: string = "";
  showTimeSlotCallId: any;
  fromArr = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00",
  "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", 
  "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", 
  "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00",
   "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00",
    "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"]
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      open:false,
      isMonth: true,
      openModalForClassBooking: false,
      lessons: [],
      weeks: [],
      slots: [],
      selectedDob: "",
      languageTaught: "",
      selectedDaysFromWeek: [],
      initiallyBookClassesState: false,
      dateSelectedByTeacher: "",
      openUpdateAvailModal: false,
      selectedCheckboxes: [],
      createCourseError: false,
      arr1: [],
      arr2: [],
      arr3: [],
      arr4: [],
      arr5: [],
      arr6: [],
      arr7: [],
      dayStr: "",
      prefilledLanguage: "",
      userData: "",
      languagesArr: [],
      language: "",
      isStartTimeClicked: false,

      selectedTimes: [],
      dayId: "",
      startDate: "",
      isError: false,
      dropFour: { isOpen: false, type: "", index: "", pIndex: "" },
      fromTime: "",
      toTime: "",
      toggle: false,
      errorForStartDate: "",
      errorMsg:'',
      dateFormate: "dd MMM yyyy",
      timeZoneData: "",
      loading1:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.createavailablityCallId) {
        if(responseJson.message){
          this.setState({ openUpdateAvailModal: true,errorMsg:'' });

        }
        else if (responseJson.errors) {
          this.setState({ openUpdateAvailModal: false });
        }else if(responseJson.error){
          this.setState({errorMsg:responseJson.error})
        }
        else {
          this.getTokenError(responseJson, "")
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  redirectToLoginIfLoggedOut = () => {
    const getToken = localStorage.getItem('authToken');
    const parsedToken = getToken ? JSON.parse(getToken) : "";
    if (parsedToken) {
      this.setState({ loading1: false });
    }
  }
  // Customizable Area End
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (localStorage.getItem("dayCalendarSelected")) {
      this.setState({
        dateSelectedByTeacher: new Date(
          JSON.parse(localStorage.getItem("dayCalendarSelected") ?? "")
        ),
      });
    }else{
      this.setState({
        dateSelectedByTeacher: new Date(
        ),
      });
    }

    const userData = JSON.parse(localStorage.getItem("user") || "");


    this.setState({
      userData: userData,
      languageTaught: userData?.attributes?.language_taught,
    });

    const tempSlots = [
      { isSelected: false },
      { isSelected: false },
      { isSelected: false },
      { isSelected: false },
      { isSelected: false },
      { isSelected: false },
      { isSelected: false },
    ];

    this.setState({
      slots: tempSlots,
      selectedDaysFromWeek: [],
      initiallyBookClassesState: false,
      languagesArr: [],
    });

    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)

    if (localStorage.getItem("selectedDateFormat")) {
      const storDateFormat = JSON.parse(localStorage.getItem("selectedDateFormat") || '')
      this.getDateFormat(storDateFormat)
  }

  let localTimeZone: LocalTimeZone ;
  let localStorageUser =  localStorage.getItem("user");
  if(localStorageUser){
  localTimeZone = JSON.parse(localStorageUser)
  this.setState({ timeZoneData: localTimeZone.attributes.time_zone});
  }
  this.redirectToLoginIfLoggedOut();
    // Customizable Area End
  }

  closeModal = () => {
    this.setState({ openUpdateAvailModal: false });
  };

  handleSelect1 = (day: any, values: any, setFieldValue: any) => {
    const tempArr = values.weeks.map((week: any) => {
      if (week.isSelected && week.week == day.week) {
        return { ...week, isSelected: false };
      } else if (week.isSelected) {
        return { ...week, isSelected: false };
      } else {
        if (week.week === day.week) {
          return { ...week, isSelected: true };
        } else {
          return week;
        }
      }
    });
    setFieldValue("weeks", tempArr);
  };

  isSaveButtonDisabled = (values: any) => {
    if (values.ends == "Never" || values.ends == "On") {
      return true;
    }

    const selectedDay = values.weeks.reduce((acc: any, item: any) => {
      const filteredFromToArr = item.fromToArr.filter(
        (detail: any) => detail.from !== ""
      );
      if (filteredFromToArr && filteredFromToArr.length > 0) {
        acc.push({
          day: item.week,
          fromToArr: filteredFromToArr,
        });
      }
      return acc;
    }, []);
    if (!selectedDay.length) {
      return true;
    }
    for (let day of selectedDay) {
      for (let timeArr of day.fromToArr) {
        if (!timeArr.from || !timeArr.to) {
          return true;
        }
      }
    }
    return false;
  };

  onSubmit = (values: any, { resetForm }: any) => {

    const selectedDay = values.weeks.reduce((acc: any, item: any) => {
      const filteredFromToArr = item.fromToArr?.filter(
        (detail: any) => detail.from !== ""
      );
      if (filteredFromToArr && filteredFromToArr.length > 0) {
        acc.push({
          day: item.week,
          fromToArr: filteredFromToArr,
        });
      }
      return acc;
    }, []);

    const selectedDaysData = selectedDay.map((item: any) => {
      const fromToArr = item.fromToArr.map((items: any) => ({
        start_time: items.from,
        end_time: items.to,
      }));
      return {
        day: item.day,
        slots: fromToArr,
      };
    });

    const { ends } = values;

    const formatDate = (date: any) => {
      const formattedDate = new Date(date);
      const year = formattedDate.getFullYear();
      const month = formattedDate.getMonth() + 1;
      const day = formattedDate.getDate();
      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    };
    
    const formattedDates = ends === "Never" ? "Never" : formatDate(ends);
    const formattedDate = formatDate(
      this.state.dateSelectedByTeacher
        ? this.state.dateSelectedByTeacher
        : new Date()
    );

    let body: any = {
      availability_date: formattedDate,
      time_slots: selectedDaysData,
    };

    if (!formattedDates.includes("NaN")) {
      body.end_date = formattedDates
    }
    
    let timeZone = localStorage.getItem("timeZone");
    let timeZoneValue = this.state.timeZoneData === "" ? "" + timeZone : this.state.timeZoneData ;
   
    body = this.convertSlotsToUTC(body, timeZoneValue);

    const tokenlocal =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newTokens = tokenlocal ? JSON.parse(tokenlocal) : "";
    this.createavailablityCallId = sendAPIRequest(
      configJSON.createAvailabilityEndPoint,
      {
        method: "POST",
        body,
        headers: { "Content-Type": "application/json", token: newTokens },
      }
    );
    resetForm();
  };
 
  convertLocalTimeZoneToUTC = (
    localTime: string,
    localDate: string,
    timeZone: string
  ): { time: string, day: string , date: string | null} => {
    const [day, month, year] = localDate.split("/").map(Number);
    const [hours, minutes] = localTime.split(":").map(Number);
    const localDateTime = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
      },
      { zone: timeZone }
    );
    const utcDateTime = localDateTime.toUTC();
    const utcHours = ("0" + utcDateTime.hour).slice(-2);
    const utcMinutes = ("0" + utcDateTime.minute).slice(-2);
    return {
      time: `${utcHours}:${utcMinutes}`,
      day: utcDateTime.toFormat("cccc").toLowerCase(),
      date: utcDateTime.toISODate(),
    };
  };
  
  convertSlotsToUTC = (body: Body, localTimeZone: string): Body => {
    const { availability_date, time_slots, end_date } = body;
    const convertedTimeSlots: DaySlots[] = [];
    let minDate = DateTime.fromFormat(availability_date, "dd/MM/yyyy");
    let maxDate = end_date ? DateTime.fromFormat(end_date, "dd/MM/yyyy") : null;
    
    if (time_slots.length === 0) {
      return {
        ...body,
        time_slots: [],
      };
    }
  
    time_slots.forEach((daySlot) => {
      const baseDate = DateTime.fromFormat(availability_date, "dd/MM/yyyy");
      const dayOffset =
        (DateTime.fromFormat(daySlot.day, "cccc").weekday - baseDate.weekday + 7) % 7;
      const targetDate = baseDate.plus({ days: dayOffset });
  
      if (!maxDate || (targetDate >= minDate && targetDate <= maxDate)) {
        daySlot.slots.forEach((slot) => {
          const convertedStart = this.convertLocalTimeZoneToUTC(
            slot.start_time,
            targetDate.toFormat("dd/MM/yyyy"),
            localTimeZone
          );
          const convertedEnd = this.convertLocalTimeZoneToUTC(
            slot.end_time,
            targetDate.toFormat("dd/MM/yyyy"),
            localTimeZone
          );
  
          const startDate = DateTime.fromISO(`${convertedStart.date}`);
          const endDate = DateTime.fromISO(`${convertedEnd.date}`);
  
          if (startDate < minDate) minDate = startDate;
          if (maxDate && endDate > maxDate) maxDate = endDate;
  
          if (startDate.toISODate() === endDate.toISODate()) {
            convertedTimeSlots.push({
              day: convertedStart.day,
              date: `${startDate.toISODate()}`,
              slots: [
                {
                  start_time: convertedStart.time,
                  end_time: convertedEnd.time,
                },
              ],
            });
          } else {
            convertedTimeSlots.push({
              day: convertedStart.day,
              date: `${startDate.toISODate()}`,
              slots: [
                {
                  start_time: convertedStart.time,
                  end_time: "23:59",
                },
              ],
            });
            convertedTimeSlots.push({
              day: convertedEnd.day,
              date: `${endDate.toISODate()}`,
              slots: [
                {
                  start_time: "00:00",
                  end_time: convertedEnd.time,
                },
              ],
            });
          }
        });
      }
    });
  
    const groupedSlots: { [key: string]: DaySlots } = {};
    convertedTimeSlots.forEach((slot) => {
      const key = `${slot.day}-${slot.date}`;
      if (!groupedSlots[key]) {
        groupedSlots[key] = {
          day: slot.day,
          date: `${slot.date}`,
          slots: [],
        };
      }
      groupedSlots[key].slots.push(...slot.slots);
    });
  
    const finalTimeSlots = Object.values(groupedSlots).map((daySlot) => {
      const sortedSlots = daySlot.slots.sort((a, b) => a.start_time.localeCompare(b.start_time));
      
      return {
        day: daySlot.day,
        date: daySlot.date,
        slots: sortedSlots,
      };
    });
    
    const mergedTimeSlots: DaySlots[] = [];
    finalTimeSlots.forEach((currentDaySlot, i) => {
      const nextDaySlot = finalTimeSlots[i + 1];
      if (nextDaySlot && currentDaySlot.date === nextDaySlot.date) {
        currentDaySlot.slots.push(...nextDaySlot.slots);
        currentDaySlot.slots.sort((a, b) => a.start_time.localeCompare(b.start_time));
        finalTimeSlots.splice(i + 1, 1);
      } else {
        mergedTimeSlots.push(currentDaySlot);
      }
    });
  
    const result: Body = {
      ...body,
      availability_date: minDate.toFormat("dd/MM/yyyy"),
      time_slots: mergedTimeSlots,
    };
  
    if (body.end_date) {
      const providedEndDate = DateTime.fromFormat(body.end_date, "dd/MM/yyyy");
      if (maxDate && maxDate > providedEndDate) {
        result.end_date = maxDate.toFormat("dd/MM/yyyy");
      } else {
        result.end_date = body.end_date;
      }
    }
  
    return result;
  };
  
  getTokenError = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunction = {
      toggleToaster: this.context?.toggleToaster,
      handleToaster: this.context?.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userData?.type)
  }

  showTimeSlot(date: any) {
    const tokenlocal =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newTokens = tokenlocal ? JSON.parse(tokenlocal) : "";

    this.showTimeSlotCallId = sendAPIRequest(
      `bx_block_appointment_management/show_timeslot?availability_date=${moment(date).format('DD/MM/YYYY')}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json", token: newTokens },
      }
    );
  }
   formatDate = (date:any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
  handleUpdateAVilaibilityDateSelection = (value: any) => {
    let dateFormate = this.formatDate(value)
    
    this.showTimeSlot(value);
    this.setState({ dateSelectedByTeacher: value });
  };

  closeCalendarModal = () => {
    this.setState({ isMonth: true });
  };

  handleLanguageResponse(responseJson: any) {
    this.setState({ languagesArr: responseJson.data });
  }

  getNextDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  }

  customBorderRadius(drop: any) {
    return drop ? "10px 10px 0px 0px" : "10px";
  }
  handleFunctionForTimeDisabled = (time: any) => {
    if (
      time.disabled &&
      !(this.state.fromTime == "11:00 PM" && time.time == "12:00 AM")
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleFunctionForOpenToTime = (index: any, idx: any = 1) => {
    const newData = (this.getSelectedArray(index) || []).map((item, i) => {
      if (i === idx) {
        return { ...item, to: "" };
      }
      return item;
    });
    return newData;
  };
  checkIndex1 = (index: any) => {
    if (index == 0) {
      return "10px 10px 0px 0px";
    } else if (index == 6) {
      return "0px 0px 10px 10px";
    } else {
      return "0px";
    }
  };
  conditionForBorderLeft1 = (index: any, day: any) => {
    if (index == 0 && day.isSelected) {
      return "1px solid #6D5B96";
    } else if (index == 0) {
      return "1px solid #D1D1D1";
    } else if (day.isSelected) {
      return "1px solid #6D5B96";
    } else {
      return "1px solid #D1D1D1";
    }
  };

  handleMoreTime = (day: any, push: any, values: any, index: any, childIndex: any) => {
    push({
      id: new Date().getTime().toString(),
      from: "",
      to: "",
      isOpen: false,
      // tempTimes: this.getFromTimeList(index,childIndex),
    });
  };

  handleUpdateAVilaibilityDateSelectionCommon = (
    value: any,
    values: any,
    setFieldValue: any
  ) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    let dayStr = value.toLocaleDateString(undefined, options);
    localStorage.setItem("dayCalendar", JSON.stringify(dayStr));
    this.commonFunctionForDateWithDay(values, setFieldValue);

    this.setState({ dateSelectedByTeacher: value });
    if (values.ends != "One year" && values.ends != "On") {
      !isBefore(value, values.ends) && setFieldValue("ends", "On");
    }
  };

  handleCalendarDateWithDay = (values: any, setFieldValue: any) => {
    this.commonFunctionForDateWithDay(values, setFieldValue);
    console.log(values,"hjhhdhdjgjfdgjdfjg");
    
  };
  handleClassName = (isSelected: any) => {
    return isSelected ? "selectedClass" : "nonSelectedClass";
  };
  commonFunctionForDateWithDay = (values: any, setFieldValue: any) => {
    let dayCalendar = JSON.parse(localStorage.getItem("dayCalendar") ?? "");

    let day = dayCalendar.slice(0, 3);

    const tempArr = values.weeks.map((elem: any) => {
      if (elem.isSelected && elem.initials !== day) {
        return { ...elem, isSelected: false };
      } else if (!elem.isSelected && elem.initials === day) {
        return { ...elem, isSelected: true };
      }
      return elem;
    });

    setFieldValue("weeks", tempArr);
  };


  getDateFormat = (format:any)=>{
    let dateFormate;
    switch(format){
      case "%d/%m/%Y":
        dateFormate = "dd/MM/yyyy"
        break
      case "%m/%d/%Y":
        dateFormate = "MM/dd/yyyy"
        break
      case "%Y/%d/%m":
        dateFormate = "yyyy/dd/MM"
        break
      case "%d %b %Y":
        dateFormate = "dd MMM yyyy"
        break
      case "%b %Y %d":
        dateFormate = "MMM yyyy dd"
        break
      case "%b %d %Y":
        dateFormate = "MMM dd yyyy"
        break
      case "%d.%m.%Y":
        dateFormate = "dd.MM.yyyy"
        break
      case "%m.%d.%Y":
        dateFormate = "MM.dd.yyyy"
        break
      case "%Y.%d.%m":
        dateFormate = "yyyy.dd.MM"
        break
      default:
        dateFormate = "dd MMM yyyy"
    }
    this.setState({dateFormate})
  }

  getValueForEndsOn(values: any) {
    if (values.ends == "One year" || values.ends == "On") {
      return "Select Date";
    } else {
       const newDate = new Date(values.ends)
      const formattedDate = moment(newDate).format(this.state.dateFormate.toUpperCase())
      return formattedDate;
    }
  }

  isButtonDisabled = (values: any) => {
    if (values.ends == "One year") {
      return true;
    } else {
      return false;
    }
  };

  convertToMinutes(timeStr: any) {
    if (timeStr === "12:00 AM" || timeStr === "12:29 AM") {
      return timeStr === "12:00 AM" ? 0 : 30;
    }
    const [hours, minutes] = timeStr.split(":");
    let totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

    // Adjust for PM times
    if (timeStr.includes("PM") && hours !== "12") {
      totalMinutes += 12 * 60;
    }

    return totalMinutes;
  }

  convertToPreviousMinutes(selectedTime1: any) {
    let [time, ampm] = selectedTime1.split(" ");
    const [hourString, minuteString] = time.split(":");
    let hours = parseInt(hourString, 10);
    let minutes = parseInt(minuteString, 10);

    if (minutes === 0) {
      if (hours === 12) {
        ampm = ampm === "AM" ? "PM" : "AM";
      } else {
        hours -= 1;
      }
      minutes = 59;
    } else {
      minutes -= 1;
    }

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  }

  isTimeDisable(time: any, ranges: any) {

    if (
      new Date(this.state.dateSelectedByTeacher).getMonth() == new Date().getMonth() && new Date(this.state.dateSelectedByTeacher).getFullYear() == new Date().getFullYear() && new Date(this.state.dateSelectedByTeacher).getDate() == new Date().getDate()
    ) {
      const [, meridian] = time.split(" ");

      let [hours] = time.split(":");
      if (meridian == "PM" && hours != '12') {
        hours = parseInt(hours) + 12;
      }
      return hours <= new Date().getHours();

    } else {

      const timeMinutes = this.convertToMinutes(time);
      return ranges.some((range: any) => {
        if (range.from && range.to) {
          const fromMinutes = this.convertToMinutes(range.from);
          const toMinutes = this.convertToMinutes(
            this.convertToPreviousMinutes(range.to)
          );
          return timeMinutes >= fromMinutes && timeMinutes <= toMinutes;
        } else {
          return false;
        }
      });
    }
  }

  getFromTimeList(index: any, childIndex: any) {
    const arr1 = [];
    let hours = 0;
    let minutes = 0;

    //From Time Change Start
    const currentDate = new Date();
    const selectedDate = this.state.dateSelectedByTeacher ? new Date(this.state.dateSelectedByTeacher) : currentDate;
    const isToday = selectedDate.getDate() === currentDate.getDate() &&
    selectedDate.getMonth() === currentDate.getMonth() &&
    selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getDay()===Number(index+1);
    //From Time Change End

    for (let i = 0; i < 47; i++) {
      const minuteStr = String(minutes).padStart(2, "0");

      const targetTime = this.convertToMinutes1(this.state.fromTime);
      const time = `${String(hours).padStart(2, "0")}:${minuteStr}`
      // ${ampm}`;
      const timeInMinutes = this.convertToMinutes1(time);

      if (!isToday || (hours > currentDate.getHours() || (hours === currentDate.getHours() && minutes > currentDate.getMinutes()))) {
        arr1.push({
          time: time,
          disabled: childIndex == 0 ? this.isTimeDisable(time, this.getSelectedArray(index)) : timeInMinutes <= targetTime + 30 || this.isTimeDisable(time, this.getSelectedArray(index)),
        });

      }

      minutes += 30;
      if (minutes === 60) {
        hours++;
        minutes = 0;
      }
    }
    return arr1;
  }

  handleDateChage = (value:any,setFieldValue:any,values:any)=>{
    setFieldValue("startDate", value);
    this.setState({ open: false })
    this.setState({toggle:!this.state.toggle,errorForStartDate:''})
    localStorage.setItem('datePickerDate',JSON.stringify(value));
    localStorage.setItem('dayCalendarSelected', JSON.stringify(value));
    this.handleUpdateAVilaibilityDateSelectionCommon(
      value,
      values,
      setFieldValue
    );
  }

  calenderClose = () => {
    this.setState({ open: false })
  }
  calenderOpen = () => {
    this.setState({ open: true })
  }

  convertToMinutes1(timeStr: any) {

    const [hours, minutes] = timeStr.split(":").map(Number);
    let totalMinutes = (hours % 12) * 60 + minutes;
    if (hours >= 12) {
      totalMinutes += 12 * 60;
    }
    return totalMinutes;
  }

  getToTimeList(index: any) {
    const arr1 = [];
    let hours = 0;
    let minutes = 0;

    for (let i = 0; i < 48; i++) {

      const minuteStr1 = String(minutes).padStart(2, "0");

      const targetTime1 = this.convertToMinutes1(this.state.fromTime);

      const time1 = `${String(hours).padStart(2, "0")}:${minuteStr1}`;
      const timeInMinutes1 = this.convertToMinutes1(time1);

      arr1.push({
        time: time1,
        disabled:
          timeInMinutes1 <= targetTime1 + 30 ||
          this.isTimeDisable(time1, this.getSelectedArray(index)),
      });

      minutes += 30;
      if (minutes === 60) {
        hours++;
        minutes = 0;
      }
    }

    return arr1;
  }

  getSelectedArray(index: any) {
    switch (index) {
      case 0:
        return this.state.arr1;
      case 1:
        return this.state.arr2;
      case 2:
        return this.state.arr3;
      case 3:
        return this.state.arr4;
      case 4:
        return this.state.arr5;
      case 5:
        return this.state.arr6;
      case 6:
        return this.state.arr7;
    }
  }

  handleFromToTime(index: any, obj: any) {
    switch (index) {
      case 0:
        this.setState({ arr1: this.state.arr1.concat(obj) });

        break;
      case 1:
        this.setState({ arr2: this.state.arr2.concat(obj) });

        break;
      case 2:
        this.setState({ arr3: this.state.arr3.concat(obj) });

        break;
      case 3:
        this.setState({ arr4: this.state.arr4.concat(obj) });

        break;
      case 4:
        this.setState({ arr5: this.state.arr5.concat(obj) });

        break;
      case 5:
        this.setState({ arr6: this.state.arr6.concat(obj) });

        break;
      case 6:
        this.setState({ arr7: this.state.arr7.concat(obj) });

        break;

      default:
        break;
    }
  }
  handleToTime(index: any, updatedArr: any) {
    switch (index) {
      case 0:
        this.setState({ arr1: updatedArr });

        break;
      case 1:
        this.setState({ arr2: updatedArr });

        break;
      case 2:
        this.setState({ arr3: updatedArr });

        break;
      case 3:
        this.setState({ arr4: updatedArr });

        break;
      case 4:
        this.setState({ arr5: updatedArr });

        break;
      case 5:
        this.setState({ arr6: updatedArr });

        break;
      case 6:
        this.setState({ arr7: updatedArr });

        break;

      default:
        break;
    }
  }

  // Customizable Area End
}
