import React, { Component } from "react";
// Customizable Area Start
import { Box, Typography, Button, Modal, OutlinedInput } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { TeacherDashboardModalProps } from '../../../framework/src/Interfaces/TeacherDashboardModal.web';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomLoader from "../../../components/src/CustomLoader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
  },
  },
});
export const configJSON = require("./config");
// Customizable Area End

export default class TeacherDashboardModal extends Component<TeacherDashboardModalProps> {

  // Customizable Area Start

  studentListModal = () => {
    const { studentData } = this.props;
    return (
      <StudentList>
        {studentData.length > 0 && studentData.map((item: any, index: number) => {
          const { student_name, student_id, profile_image } = item;
          return (
            <Box className="item_wrapper">
              <img className="student_profile" src={profile_image ? profile_image : "/static/media/standardProfileIcon.789fcfef.png"} alt="profile" />
              <Typography data-test-id="student_profile" onClick={() => this.props.stuProfile(student_id)}
                className="student_name">{student_name}</Typography>
            </Box>
          )
        })}

      </StudentList>
    )
  }

  classAcceptRejectModal = (modalName: string) => {
    const { t } = this.props
    return (
      <AcceptRejectModal>
        <Typography className="request_title">{t("Hey")} {this.props.name},</Typography>
        {modalName === 'accept' ?
          <>
            <Typography className="request_text">{t(configJSON.acceptClassDescription)}</Typography>
          </> :
          <Typography className="request_text">{t(configJSON.rejectClassDescription)}</Typography>
        }
        <Typography className="error_text">{this.props.errorText}</Typography>
        <Box className="action_button">
          <Button data-test-id="close_modal" className="cancel_button"
            onClick={this.props.closeModal}>{t(configJSON.backButtonText)}</Button>
          <Button data-test-id='modal_button'
            className={`common_button ${modalName === 'accept' ? "accept_button" : "reject_button"}`}
            onClick={modalName === 'accept' ? () => { this.props.acceptClass(this.props.dataId) } :
              () => this.props.rejectClass(this.props.dataId)}>{modalName === 'accept' ?
                t(configJSON.acceptButtonText) : t(configJSON.rejectButtonText)}</Button>
        </Box>
      </AcceptRejectModal>
    )
  }

  cancelClass = () => {
    const { t } = this.props
    return (
      <AcceptRejectModal>
        <Typography className="request_title">{t("Hey")} {this.props.name},</Typography>
        <Typography className="request_text">{!this.props.isReject ?
          t(configJSON.cancelClassDescription) : t(configJSON.cancelClassRejectDescription)}
        </Typography>
        <Typography className="request_text">{!this.props.isReject ?
          t(configJSON.cancelClassDescription2) : t(configJSON.rejectClassDescription2)}
        </Typography>
        <CustomTextField multiline placeholder={t('Enter reason (visible to Lingoamo team only)')}
          rows={4} type='text' value={this.props.cancelClassDes}
          onChange={(e) => this.props.handleCancelClass(e.target.value)}
          inputProps={{ "data-test-id": 'reject_reason' }} />
        <Box className="action_button">
          <Button className="cancel_button" onClick={this.props.closeModal}>{t(configJSON.backButtonText)}</Button>
          <Button data-test-id="cancel_button" disabled={this.props.lodding}
            className={`common_button ${this.props.cancelClassDes.length > 0 ? "reject_button" : 'disable_button'}`}
            onClick={!this.props.isReject ?
              () => this.props.cancelClass(this.props.dataId) : () => this.props.rejectClass(this.props.dataId)}>
            {this.props.lodding ?
              (<CircularProgress color="inherit" />)
              : (!this.props.isReject ? t(configJSON.cancelClassText) : t(configJSON.cancelClassRejctText))}
          </Button>
        </Box>
      </AcceptRejectModal>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Modal open={this.props.open} closeAfterTransition onClose={this.props.closeModal} style={{zIndex: 1}}>
          <ModalContainer>
            <CustomLoader isLoading={this.props.openLoader} />
            {this.props.currentModal == "studentList" && this.studentListModal()}
            {this.props.currentModal == "rejectClass" && this.classAcceptRejectModal('reject')}
            {this.props.currentModal == "acceptClass" && this.classAcceptRejectModal('accept')}
            {this.props.currentModal == "cancelClass" && this.cancelClass()}
          </ModalContainer>
        </Modal>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'linear-gradient(180deg, #F0E9FF 0%, #FFFFFF 100%)',
  boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',
  borderRadius: '20px',
  boxSizing: 'border-box',

  '& .error_text': {
    color: "#d53e5f",
    marginTop: '16px'
  },

  "@media(max-width:600px)": {
    width: '90%'
  },
})

const StudentList = styled(Box)({
  display: 'flex',
  alignItems: 'start',
  rowGap: '16px',
  flexDirection: 'column',
  width: '454px',
  maxHeight: '383px',
  overflowY: 'auto',
  margin: '40px 28px',

  "@media(max-width:600px)": {
    width: 'auto',
    rowGap: '8px',
    margin: '20px 14px'
  },

  '& .item_wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '13px',
  },

  '& .student_profile': {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'cover',

    "@media(max-width:600px)": {
      width: '30px',
      height: '30px'
    },
  },

  '& .student_name': {
    color: '#122BFF',
    fontSize: '20px',
    letterSpacing: "-0.2px",
    lineHeight: '27px',
    fontFamily: "'Open sans', normal",
    cursor: 'pointer',

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  }
})

const AcceptRejectModal = styled(Box)({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  width: '480px',
  maxHeight: '383px',
  // overflowY: 'auto',
  margin: '80px',

  "@media(max-width:600px)": {
    margin: '20px',
    width: 'auto'
  },

  '& .request_title': {
    color: "#434343",
    fontSize: "28px",
    fontFamily: "'Open sans', bold",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.28px",
    marginBottom: '16px',

    "@media(max-width:600px)": {
      fontSize: '20px',
      marginBottom: '8px',
      lineHeight: '24px'

    },
  },

  '& .request_text': {
    color: "#434343",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "-0.2px",
    fontFamily: "'Open sans', normal",

    "@media(max-width:600px)": {
      fontSize: '14px',
      lineHeight: '24px'
    },
  },

  '& .action_button': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '28px',
    width: '100%',

    "@media(max-width:600px)": {
      marginTop: '16px',
      columnGap: '20px'
    },
  },

  '& .cancel_button': {
    width: "223px",
    height: "64px",
    color: '#242424',
    border: '1px solid #979797',
    borderRadius: '8px',
    fontSize: '20px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    textTransform: 'none',

    "@media(max-width:600px)": {
      fontSize: '14px',
      width: '50%',
      height: '40px'
    },
  },

  '& .common_button': {
    width: "223px",
    height: "64px",
    fontSize: '20px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '8px',
    color: 'white',

    "@media(max-width:600px)": {
      fontSize: '14px',
      width: '50%',
      height: '40px'
    },
  },

  '& .accept_button': {
    backgroundColor: '#6D5B96',
    border: '1px solid #6D5B96',
  },

  '& .reject_button': {
    backgroundColor: '#D73E60',
    border: '1px solid #D73E60',
  },

  '& .disable_button': {
    backgroundColor: '#D1D1D1',
    border: '1px solid #D1D1D1',
    pointerEvents: 'none'
  },

})

const CustomTextField = styled(OutlinedInput)({
  width: '100%',
  marginTop: '28px',
  borderRadius: '10px',

  "@media(max-width:600px)": {
    marginTop: '10px',
  },

  '& textarea': {
    fontSize: '16px',
    fontFamily: 'Open sans, regular',
    color: '#242424',

    "@media(max-width:600px)": {
      fontSize: '14px'
    },
  },

  '& fieldset': {
    outline: 'none !important',
    borderColor: '#d1d1d1 !important',
  },
})



// Customizable Area End
