// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Divider,
  Modal,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import DatePicker from "react-date-picker";
import CustomDate from "../../../components/src/CustomDate.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import {
  BackArrowImg,
  EditBlue,
  DeleteBlue,
  EditGrey,
  DeleteGrey,
  CalenderIcon,
  BluePlusIcon,
  DropDownIcon
} from "./assets";
// Customizable Area End

import SavedAvailabilityCotroller, {
  Props,
  configJSON,
} from "./SavedAvailabilityController.web";
import { getFromLocal } from "../../../components/src/utils";
import { withTranslation } from "react-i18next";

export class SavedAvailability extends SavedAvailabilityCotroller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  IconComponent = ({ type, index, pIndex }: any, { ...rest }) => (
    <img
      onClick={() => this.setState({ dropFrom: { isOpen: true, index: index, type: type, pIndex } })}
      data-test-id="dropdown-icon"
      className="dropdown-icon"
      {...rest}
      src={DropDownIcon}
      width={"18px"}
      height={"9px"}
      style={{ marginRight: "14px", cursor: 'pointer', paddingTop: '8px' }}

    />
  );

  renderTable = (isAvailability: boolean) => {
    const { t } = this.props
    return (
      <Box>
        <TableContent
          style={{
            marginTop: this.handleTableMarginTop(isAvailability),
            marginRight: this.handleTableMarginRight(isAvailability),
            width: "auto",
          }}
        >
          <Table aria-label="simple table" data-test-id="tableTestId">
            <TableHeadContainer>
              <TableRow>
                <TableHeader>{t(configJSON.StartDateAndDayLabel)}</TableHeader>
                <TableHeader>{t(configJSON.EndDateAndDayLabel)}</TableHeader>
                <TableHeader>{t(configJSON.FromToTimeLabel)}</TableHeader>
                {isAvailability && (
                  <TableHeader>{t(configJSON.LanuageTaughtLabel)}</TableHeader>
                )}
                <TableHeader>
                  {this.getTableHeaderText(isAvailability)}
                </TableHeader>
                <TableHeader>
                  {this.getDeleteText(isAvailability)}
                </TableHeader>
              </TableRow>
            </TableHeadContainer>
            <TableBodyContainer>
              {isAvailability && this.state.availabilityData.map((tableRow: any, index: number) => (
                <TableRowContent className="" key={`${index + 1}`}>
                  <TableCellContent>{this.convertDateSetting(tableRow.startDateAndDayLabel)}</TableCellContent>
                  <TableCellContent>{this.convertDateSetting(tableRow.endDateAndDayLabel)}</TableCellContent>
                  <TableCellContent>{this.convertTimeSetting(tableRow.fromToTimeLabel)}</TableCellContent>
                  {isAvailability && (
                    <TableCellContent>{tableRow.lanuageTaughtLabel?.replace("_", " ")}</TableCellContent>
                  )}
                  <TableCellContent>
                    <ImageContainer
                      style={{ cursor: this.handleTablePointer(tableRow.bookedStatus) }}
                      data-test-id="blockerEditButtonId"
                      onClick={() => this.handleTableEditButton(tableRow, isAvailability , tableRow, index)}
                      color={this.handleTableActionColor(tableRow.bookedStatus)}
                    >
                      <img src={tableRow.bookedStatus ? EditGrey : EditBlue} />
                      {this.props.t("Edit")}
                    </ImageContainer>
                  </TableCellContent>{" "}
                  {/*  edit_availability_success */}
                  <TableCellContent>
                    <ImageContainer
                      style={{ cursor: this.handleTablePointer(tableRow.bookedStatus) }}
                      data-test-id="delete_button_blocker"
                      onClick={() => {
                        this.handleTableDeleteAction(tableRow.bookedStatus, isAvailability , isAvailability.toString())
                        this.setState((prevState) => ({
                          deleteTempDate: {
                            ...prevState.deleteTempDate,
                            id: tableRow.id,
                            sr_no: tableRow.sr_no
                          }
                        }));
                      }}
                      color={this.handleTableActionColor(tableRow.bookedStatus)}
                    >
                      <img src={tableRow.bookedStatus ? DeleteGrey : DeleteBlue} />
                      {t("Delete")}
                    </ImageContainer>
                  </TableCellContent>
                </TableRowContent>
              ))}
              {!isAvailability && this.state.blockerData.length > 0 && this.state.blockerData.map((row: any, index: number) => {
                return row.attributes.timeslots.map((timeSlot: any, timeSlotIndex: number) => {
                  return <TableRowContent className='' key={`${index + 1}`}>
                    <TableCellContent>{this.convertDateSetting(row.attributes.start_date)}</TableCellContent>
                    <TableCellContent>{this.convertDateSetting(row.attributes.end_date)}</TableCellContent>
                    <TableCellContent>{this.convertTimeBlockerSetting(timeSlot.start_time)}-{this.convertTimeBlockerSetting(timeSlot.end_time)}</TableCellContent>
                    <TableCellContent>
                      <ImageContainer
                        style={{ cursor: this.handleTablePointer(row.bookedStatus) }}
                        data-test-id="edit_button"
                        onClick={() => this.handleTableEditButton(row, isAvailability, timeSlot,index)}
                        color={this.handleTableActionColor(row.bookedStatus)}
                      >
                        <img src={EditBlue} />
                        {t("Edit")}
                      </ImageContainer>
                    </TableCellContent>{" "}
                    {/*  edit_availability_success */}
                    <TableCellContent>
                      <ImageContainer
                        style={{ cursor: this.handleTablePointer(row.bookedStatus) }}
                        data-test-id="delete_button"
                        onClick={() => {
                          this.handleTableDeleteAction(row.bookedStatus, isAvailability,timeSlot.id)
                          this.setState({
                            deleteTempDate: {
                              id: row.id,
                              sr_no: timeSlot.sno
                            }
                          })
                        }}
                        color={this.handleTableActionColor(row.bookedStatus)}
                      >
                        <img src={DeleteBlue} />
                        {t("Delete")}
                      </ImageContainer>
                    </TableCellContent>
                  </TableRowContent>
                })

              })

              }
            </TableBodyContainer>
          </Table>
        </TableContent>
        <Box textAlign="center" marginBottom="50px">
          <AddButton
            data-test-id="add-footer-button"
            onClick={() => this.handleFooterButton(isAvailability)}
          >
            {this.getFooterButtonText(isAvailability)}
          </AddButton>
        </Box>
      </Box>
    )
  };

  getModalHeaderContent = () => this.state.modalType.includes("menu") ? (
    <ModalMainTitle>{this.getModalTitle()}</ModalMainTitle>
  ) : (
    <ModalTitle className="userTitle">
      {this.props.t(configJSON.HeyLabel)}{" "}
      {getFromLocal("user") && getFromLocal("user").attributes?.first_name},
    </ModalTitle>
  )

  getModalDescriptionText = () => this.state.modalType === "add_blocker_menu" && (
    <DescriptionText>
      {this.props.t(configJSON.AddBlockerModalDescription)}
    </DescriptionText>
  )

  getErrorText = () => (
    <DescriptionError>
      {this.state.errorMessage}
    </DescriptionError>
  )

  getModalDescriptionContent = () => (<ModalDescription >
    {this.state.modalType.includes("success") && (
      <span>
        {this.getEditLabelText()}{" "}
        {this.props.t(configJSON.savedSuccessfullyLabel)}
      </span>
    )}
    {this.state.modalType.includes("confirmation") && (
      <span className="confirmationMsg">
        {this.props.t(configJSON.deleteConfirmationLabel)}{" "}
        {this.getDeleteLabelText()}
      </span>
    )}
  </ModalDescription>)

  getModalFooterContent = () => {
    return (<Box className="footer" textAlign={"center"}>
      {this.state.modalType.includes("success") && (
        <AddButton
          data-test-id="modal-footer"
          className="modal-footer"
          onClick={() => this.handleModalClose()}
          style={{
            background: this.getTableAddButtonBackground(),
            color: this.getTableAddButtonColor(),
          }}
        >
          {this.props.t(configJSON.BackText)}
        </AddButton>
      )}
      {(this.state.modalType.includes("confirmation") ||
        this.state.modalType.includes("menu")) && (
          <ModalFooterContainer>
            <Button
              className="back-button"
              style={{ width: this.getModalFooterButtonWidth() }}
              onClick={() => this.handleModalClose(true)}
            >
              {this.state.modalType.includes("menu") ? this.props.t("Cancel") : this.props.t(configJSON.BackText)}
            </Button>
            <Button
              className={this.getFooterRightClassName()}
              style={{ width: this.getModalFooterButtonWidth() }}
              onClick={this.handleFooterRightButton}
              data-test-id="footerright"
            >
              {this.getFooterRightLabel()}
            </Button>
          </ModalFooterContainer>
        )}
    </Box>)
  }



  getModelErrorText = () => this.state.isError && this.getErrorText()

  getFROMLabel = (data: any, type: string, index: number) =>
    (!data.date.from && !(this.state.isFocus.type == type && this.state.isFocus.index == index)) && (
      <Typography className="date_label">{this.props.t("From")}</Typography>
    )
  getTOLabel = (data: any, type: string, index: number) => (!data.date.to && !(this.state.isFocus.type == type && this.state.isFocus.index == index)) && (
    <Typography className="date_label">{this.props.t("To")}</Typography>
  )

  getMargin = (dataIndex: number) => dataIndex > 0 ? '20px' : '0px'
  getAllDayLabel = (data: any, dataIndex: any) => this.state.modalType.includes("blocker_menu") &&
    <div className={dataIndex > 0 ? "allDayField" : ""}><AllDayContainer style={{ marginTop: this.getMargin(dataIndex) }}>

      <StyledCheckbox checked={data.date?.checked} className="blocker_menus"
        onChange={(event: any) => this.handleAllDayCheck(event.target.checked, dataIndex)} />
      <AllDayLabel>{this.props.t(configJSON.AllDayLabel)}</AllDayLabel>


    </AllDayContainer></div>

  getModalContent = () => this.state.editAvailabilityTempData.length > 0 &&
    this.state.editAvailabilityTempData.map((data: any, dataIndex: number) => {
      const { t } = this.props

      return (
        <Grid container spacing={0} style={{ maxWidth: '640px' }}>
          <div className="innerContainer" style={{ maxWidth: this.getWidth(this.state.editAvailabilityTempData), width: this.getWidth(this.state.editAvailabilityTempData) }}>
            <DateField>
              <Box className="date_container" style={{ marginTop: dataIndex === 0 ? '0' : '20px' }} >
                <Typography style={{ paddingTop: '2px' }} className="date_filter_title">{this.props.t("Dates")}</Typography>
                <Box
                  className={`${this.getDateHideClass(data.date.from)}`}
                  style={{ position: "relative", marginTop: '1px' }}
                >
                  <DateFieldContainer>

                    <CustomDate
                      defaultValue={this.getValue(data.date.from)}
                      value={this.getValue(data.date.from)}
                      disabled={(this.state.modalType.includes('edit_availability_menu'))}
                      name='From' selctDate={(name: string, value: any) => {
                        this.handleSelectDate("from", value, dataIndex)
                      }}
                      t={t} open={this.state.open1} openState={this.calenderClose1} calenderOpen={this.calenderOpen1}
                    />
                  </DateFieldContainer>

                </Box>
                <Box
                  style={{ position: "relative" }}
                >
                  <DateFieldContainer>
                    <CustomDate
                      disabled={(this.state.modalType.includes('edit_availability_menu'))}
                      value={this.getValue(data.date.to)} minDate={this.getValue(data.date.from)}
                      defaultValue={this.getValue(data.date.to)}
                      name='To' selctDate={(name: string, value: any) => {
                        this.handleSelectDate("to", value, dataIndex)
                      }}
                      t={t} open={this.state.open} openState={this.calenderClose} calenderOpen={this.calenderOpen}
                    />

                  </DateFieldContainer>
                </Box>

              </Box>
              <Box>
                <GetAllLabel>
                  {this.getAllDayLabel(data, dataIndex)}

                </GetAllLabel>
              </Box>
            </DateField>


            <Box className="times_container">
              <Typography className="time_filter_title">{this.props.t("Times")}</Typography>
              <Box className="time_container">
                {data.time && data.time.map((availabilityData: any, availabilityDataIndex: number) => {
                  function adjustTime(data: any) {
                    let [hours, minutes] = data.to.split(':').map(Number);

                  if (minutes === "59") {
                        let [hours] = minutes?.split(":").map(Number);
                        hours = (hours + 1) % 24;
                        minutes = `${hours.toString().padStart(2, "0")}:00`;
                  } else if (minutes === 29) {
                      minutes = 30;
                    } else {
                      minutes = minutes;
                    }
                    data.to = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                  }
                  adjustTime(availabilityData);
                  return (
                    <TimeFields>
                      <Box className="main_Box">
                        <SelectTime
                          variant="outlined"
                          data-test-id="from_time"
                          open={this.state.dropFrom.isOpen && this.state.dropFrom.type == "from_time" && this.state.dropFrom.index == availabilityDataIndex && this.state.dropFrom.pIndex == dataIndex}
                          IconComponent={({ ...rest }) => this.IconComponent({ type: 'from_time', index: availabilityDataIndex, pIndex: dataIndex }, { ...rest })}
                          onOpen={() => this.setState({ dropFrom: { isOpen: true, index: availabilityDataIndex, type: 'from_time', pIndex: dataIndex } })}
                          onClose={() => this.setState({ dropFrom: { isOpen: false, index: "", type: '', pIndex: '' } })}
                          className={availabilityData && this.getDefaultLabel(!availabilityData.from)}
                          style={{
                            borderRadius: this.getDropwFormBorderRadius(),
                            position: 'relative'
                          }}
                          value={availabilityData.from}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                height: "192px",
                                width: "154px",
                                border: "1px solid #D1D1D1",
                                boxShadow: "none",
                                top: "333px !important",
                                marginTop: "-4px",
                                borderRadius: "0px 0px 10px 10px",
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          displayEmpty
                          disabled={data.date?.checked}
                          onChange={(event: any) => this.handleTimeChange(event.target.value, availabilityDataIndex, 'from', dataIndex)}
                          id="timechange"

                        >
                          <MenuItem value="" disabled>{this.props.t("From")}</MenuItem>
                          {this.getFromTimeList().map((time: any, index: any) => {
                            return (
                              <MenuItem
                                key={`${time.time}_${index + 0}`}
                                style={{
                                  borderBottom: this.getTableItemsBottomBorderColor(index)
                                }}
                                value={time.time}
                                disabled={this.isDisabled(data.time, index, true, availabilityDataIndex)}
                              >
                                {time.time}
                              </MenuItem>
                            );
                          })}
                        </SelectTime>
                        <SelectTime
                          variant="outlined"
                          data-test-id="to_time"
                          open={this.state.dropFrom.isOpen && this.state.dropFrom.type == "to_time" && this.state.dropFrom.index == availabilityDataIndex && this.state.dropFrom.pIndex == dataIndex}
                          IconComponent={({ ...rest }) => this.IconComponent({ type: 'to_time', index: availabilityDataIndex, pIndex: dataIndex }, { ...rest })}
                          className={availabilityData && this.getDefaultLabel(availabilityData.to === "00:undefined" || availabilityData.to ===  "00:NaN" ? false : !availabilityData.to)}
                          onOpen={() => this.setState({ dropFrom: { isOpen: true, index: availabilityDataIndex, type: 'to_time', pIndex: dataIndex } })}
                          onClose={() => this.setState({ dropFrom: { isOpen: false, index: '', type: '', pIndex: '' } })}
                          onChange={(event: any) => this.handleTimeChange(event.target.value, availabilityDataIndex, 'to', dataIndex)}
                          style={{
                            borderRadius: this.getDropwFormBorderRadius(),
                            marginRight: '0px',
                            position: 'relative'
                          }}
                          disabled={data.date?.checked}
                          value={availabilityData.to === "00:undefined" || availabilityData.to ===  "00:NaN" ?"":availabilityData.to}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                height: "192px",
                                width: "154px",
                                border: "1px solid #D1D1D1",
                                boxShadow: "none",
                                top: "333px !important",
                                marginTop: "-4px",
                                borderRadius: "0px 0px 10px 10px",
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          displayEmpty
                        >
                          <MenuItem value={""} disabled>{this.props.t("To")}</MenuItem>
                          {this.getToTimeList().map((time: any, index: any) => {
                            return (
                              <MenuItem
                                key={`${time.time}_${index + 0}`}
                                style={{
                                  borderBottom: this.getTableItemsBottomBorderColor(index)
                                }}
                                disabled={index <= this.fromArr.indexOf(availabilityData.from) + 1 ? true : false}
                                value={time.time}
                              >
                                {time.time} 
                              </MenuItem>
                            );
                          })}
                        </SelectTime>
                      </Box>
                      <Box>
                        {!data.date?.checked && this.isLargeWidth(this.state.editAvailabilityTempData) && <MoreTimesContainer className="addMoreTimeBtn" style={{ marginLeft: '27px' }}>
                          <Box className="data_checked" onClick={() => this.addMoreTime(dataIndex)}>
                            <img src={BluePlusIcon} />
                            <MoreTimesLabel>{this.props.t(configJSON.AddMoreTimeText)}</MoreTimesLabel>
                          </Box>
                        </MoreTimesContainer>}
                        {data.time && data.time.length > 1 && <ImageContainer data-test-id="delete-time"
                          onClick={() => this.deleteTime(dataIndex, availabilityDataIndex)}
                        >
                          <img src={DeleteBlue} />
                          {this.props.t("Delete Time")}
                        </ImageContainer>
                        }
                      </Box>



                    </TimeFields>
                  )
                })}
              </Box>

            </Box>

          </div>
          <div className="addDateAndTimecontainer" >
            <DeleteDateAndTimeField style={{ marginTop: this.getMargin(dataIndex) }}>

              {!(data.date?.checked) && !this.isLargeWidth(this.state.editAvailabilityTempData) &&
                <MoreTimesContainer style={{ marginTop: '10px' }}>
                  <Box className="data_checked" onClick={() => this.addMoreTime(dataIndex)}>
                    <img src={BluePlusIcon} />
                    <MoreTimesLabel>{this.props.t(configJSON.AddMoreTimeText)}</MoreTimesLabel>
                  </Box>
                </MoreTimesContainer>
              }
              {this.state.editAvailabilityTempData.length > 1 &&
                <ImageContainer style={{ marginLeft: '0' }} data-test-id="delete-date" onClick={() => this.deleteDate(dataIndex)}>
                  <img src={DeleteBlue} />
                  {this.props.t("Delete Date")}
                </ImageContainer>}
            </DeleteDateAndTimeField>

          </div>

        </Grid>
      )
    })


  renderModalContent = () => (
    <ModalContainer className={this.getModalClassName(this.state.modalType)}>
      {this.state.loader && <Box className="loader">
        <CircularProgress size={80} /></Box>}
      {this.getModalHeaderContent()}
      {this.getModalDescriptionText()}
      {this.getModelErrorText()}
      {this.state.errorMessageCheck && <p style={{color: "red"}}>{this.state.messageError}</p>}
      {this.state.modalType.includes("_menu") && (
        <FieldsContainer className={this.state.modalType.includes('blocker_menu') ? 'blocker_overflow' : ''}>
          {this.getModalContent()}

          {this.state.modalType.includes("add_blocker_menu") && (
            <MoreDatesContainer>
              <Box onClick={this.addMoreDate}>
                <img src={BluePlusIcon} />
                <MoreTimesLabel>{this.props.t(configJSON.AddMoreDateText)}</MoreTimesLabel>
              </Box>
            </MoreDatesContainer>)
          }
        </FieldsContainer>
      )}
      {this.getModalDescriptionContent()}
      {this.getModalFooterContent()}

    </ModalContainer>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <CustomTeacherNavigationLayout
          data-test-id="teacher-navigation"
          profileUrl={""}
          screenName={"UpdateAvailability"}
          params={""}
          navigation={this.props.navigation}
        />
        <HeadingContent>
          <Box>
            <BackArrowContent
              id="back-arrow-btn"
              data-test-id="back-arrow-btn-id"
              onClick={this.handleBackButton}
            >
              <img src={BackArrowImg} height={22} />
              {this.props.t(configJSON.BackText)}
            </BackArrowContent>
            <HeadingText>{this.props.t(configJSON.SavedAvailabilityHeading)}</HeadingText>
          </Box>
        </HeadingContent>
        <ModalWrap
          open={this.state.isModalOpen}
          className="modal_closed"
          onClose={() => this.handleModalClose()}
        >
          {this.renderModalContent()}
        </ModalWrap>
        {this.renderTable(true)}
        <Divider style={{ background: "#d1d1d1", margin: "0 106px" }} />
        <HeadingText style={{ position: "inherit", marginTop: "50px" }}>
          {this.props.t(configJSON.SavedBlockersHeading)}
        </HeadingText>
        {this.renderTable(false)}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export default withTranslation()(SavedAvailability)

const HeadingContent = styled("div")({
  fontFamily: "Open sans, regular",
  color: "#242424",
  padding: "50px",
  "& >div": {
    position: "relative",
    width: "100%",

  },
});

const DateFieldContainer = styled(Box)({
  width: '150px',
  "& input": {
    background: "transparent !important",
    padding: '10px',
    borderRadius: '5px !important'
  },
  "& img": {
    top: '3px !important'
  },
  //@ts-ignore
  "& .react-datepicker-popper": {
    position: 'fixed !important'
  },
  "& .react-datepicker__triangle": {
    display: 'none !important'
  },
  "@media only screen and (max-width: 500px)": {
    fontSize: '13px',
    width: "130px"

  },
  "& .date_picker_class": {
    "& span": {
      "& .react-date-picker__calendar": {
        "& .react-calendar": {
          position: 'fixed',
          top: '20%'
        }
      }
    },


    "& .react-date-picker__wrapper": {
      width: '150px',
      height: '40px',
      borderRadius: '5px',


      "@media only screen and (max-width: 500px)": {
        fontSize: '13px',
        width: "130px"

      },
      "@media only screen and(min-width:501px) and (max-width: 1024px)": {
        width: '150px',
        height: '40px',
        borderRadius: '5px'

      },
      // "& input":{
      //   display:'none'
      // },
      "& span": {
        display: 'none'
      }

    }
  },
  "& .date_picker_valued": {

    "& span": {
      "& .react-date-picker__calendar": {
        "& .react-calendar": {
          position: 'fixed',
          top: '20%'
        }
      }
    },

    "& .react-date-picker__wrapper": {
      width: '150px',
      height: '40px',
      borderRadius: '5px',

      "@media only screen and (max-width: 500px)": {
        fontSize: '13px',
        width: "144px"

      },
      "@media only screen and(min-width:501px) and (max-width: 1024px)": {
        width: '150px',
        height: '40px',
        borderRadius: '5px'

      }
    }
  }

})

const DateField = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  "@media only screen and (max-width: 768px)": {
    flexWrap: 'wrap',
    flexDirection: 'column'
  }

})

const TimeFields = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  "@media only screen and (max-width: 768px)": {
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  "& .main_Box": {
    display: 'flex',
    gap: '14px',
    "@media only screen and (max-width: 500px)": {
      gap: '0px'
    },
  },
})

const GetAllLabel = styled(Box)({
  "@media only screen and (max-width: 500px)": {
    marginLeft: '82px',
    marginTop: '10px'
  },
  "@media only screen and (min-width: 501px) and (max-width:768px)": {
    marginLeft: '70px',
    marginTop: '10px',

    "& .allDayField": {
      marginTop: '20px'
    }

  }
})

const DeleteDateAndTimeField = styled(Box)({
  "@media only screen and (max-width:1575px)": {
    display: 'flex',
    gap: '30px',
  },
  "@media only screen and (min-width:1575px)": {
    display: 'flex',
    gap: '30px',
    marginTop: '16px !important'
  },
  "@media only screen and (max-width: 500px)": {
    marginLeft: '88px',
    flexDirection: 'column',
    gap: '0px'
  },
  "@media only screen and (min-width:501px) and (max-width: 1279px)": {
    marginTop: '20px !important'
  }
})

const AllDayContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '20px',
  "@media only screen and (max-width: 500px)": {
    marginLeft: '0px',

  },
  '& .MuiIconButton-label>input, .MuiButtonBase-root': {
    padding: 0,
    margin: 0
  },

})

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#D4D4D4',
  '&.Mui-checked': {
    color: '#2C8701',
    margin: '0',
    padding: '0'
  },
  '&.Mui-checked:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '25px'
  },
}));

const MoreTimesContainer = styled(Box)({
  display: "flex",
  alignItems: 'end',
  "& >div": {
    display: 'flex',
    gap: "9px",
    cursor: "pointer",
    marginBottom: '10px',
    "& >img": {
      width: "20px",
      height: "20px",
    },
  }
});

const MoreDatesContainer = styled(Box)({
  margin: "13px 0 0 92px",
  "@media only screen and (max-width: 500px)": {
    marginLeft: "83px",

  },
  "@media only screen and (min-width:501px) and (max-width: 960px)": {

    margin: "13px 0 0 92px",
  },
  "& >div": {
    display: 'flex',
    gap: "9px",
    cursor: "pointer",
    marginBottom: '10px',
    width: 'fit-content',
    "& >img": {
      width: "22px",
      height: "22px",
    },
  },

})

const MoreTimesLabel = styled(Box)({
  color: "#0078a7",
  // fontSize: "14px",
  fontFamily: "Open sans, regular",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  whiteSpace: 'nowrap',
  "@media only screen and (max-width: 500px)": {
    fontSize: "14px",

  }
});

const AllDayLabel = styled(Box)({
  color: '#434343',
  fontFamily: 'Open sans, regular',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.16px',
  marginLeft: '8px',
  "@media only screen and (max-width: 500px)": {
    fontSize: '14px'
  }
})

const SelectTime = styled(Select)({
  margin: 0,
  padding: 0,
  width: "156px",
  height: "37px",
  border: "0px solid #D1D1D1",
  "& .MuiOutlinedInput-input": {
    borderRadius: "5px",
    border: "none !important",
    padding: "9px 15px !important",
    width: "125px",
    height: "20px",
  },
  "@media only screen and (max-width: 500px)": {
    width: "128px",
    marginRight: '4px',
    marginLeft: '3px',
    "& .MuiOutlinedInput-input": {
      letterSpacing: '1px !important'
    }

  },

  "@media only screen and (min-width: 501px)": {
    width: "150px",
  }

});

const ModalFooterContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "31px",
  "@media only screen and (max-width: 500px)": {
    gap: '10px'
  },
  "& >button": {
    height: "64px",
    borderRadius: "10px",
    padding: "24px 16px",
    "@media only screen and (max-width: 500px)": {
      height: "50px",
      fontSize: '14px'
    },
    "& >span": {
      fontSize: "16px",
      lineHeight: "21px",
      textAlign: "center",
      fontFamily: "Open sans, semibold",
      textTransform: "capitalize",
    },
  },
  "& .delete-button": {
    background: "#D73E60",
    "& >span": {
      color: "#FFFFFF",
    },
    "&:hover": {
      background: "#D73E60",
    },
  },
  "& .save-button": {
    background: "linear-gradient(0deg, #6D5B96 0%, #6D5B96 100%), #FE575A",
    "& >span": {
      color: "#FFFFFF",
    },
    "&:hover": {
      background: "linear-gradient(0deg, #6D5B96 0%, #6D5B96 100%), #FE575A",
    },
  },
  "& .back-button": {
    background: "#FFFFFF",
    border: "1px solid #979797",
    "& >span": {
      color: "#000000",
    },
    "&:hover": {
      background: "#FFFFFF",
    },
  },
});

const ModalTitle = styled(Box)({
  color: "#434343",
  fontSize: "28px",
  fontFamily: "Open sans, regular",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  letterSpacing: "-0.28px",
});

const FieldsContainer = styled(Box)({
  marginTop: '20px',
  maxHeight: '200px',
  overflowY: 'auto',
  "@media only screen and (max-width: 500px)": {
    maxWidth: '335px'
  },

  "& div": {
    "& .calenderIconClass": {
      "@media only screen and (max-width: 500px)": {
        width: '10px !important',
        height: '11px !important'

      }
    }
  },
  "& .MuiOutlinedInput-input": {
    borderRadius: "5px",
    padding: "9px 13px",
    width: "130px",
    height: "20px",
    // textAlign:'center',

    "@media only screen and (max-width: 500px)": {
      fontSize: '13px',
      letterSpacing: '-1px',
      padding: "9px 5px",
      width: '101px'

    },
    "@media only screen and(min-width:501px) and (max-width: 1024px)": {
      fontSize: 'small',
      width: '115px',
      padding: "9px 5px",

    }
  },
});

const ModalMainTitle = styled(Box)({
  color: "#242424",
  fontSize: "34px",
  fontFamily: "Open sans, regular",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-0.34px",
  textAlign: "center",
  "@media only screen and (max-width: 500px)": {
    fontSize: '14px'
  }
});

const ModalDescription = styled(Box)({
  color: "#434343",
  fontSize: "20px",
  fontFamily: "Open sans, regular",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  letterSpacing: "-0.2px",
  margin: "8px 0 28px",
});

const DescriptionText = styled(Box)({
  color: "#434343",
  fontSize: "16px",
  fontFamily: "Open sans, regular",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  letterSpacing: "-0.16px",
  margin: "16px 0 20px",
  textAlign: "center",
  "@media only screen and (max-width: 500px)": {
    fontSize: '12px'
  }
});
const DescriptionError = styled('div')({
  marginTop: "16px",
  color: "#d53e5f",
})
const ModalContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  top: "50% !important",
  left: "50% !important",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  borderRadius: "20px",
  background: "linear-gradient(180deg, #F0E9FF 0%, #FFF 100%)",
  boxShadow: "0px 2px 4px 0px rgba(190, 190, 190, 0.50)",
  padding: "50px",
  // minWidth: "80%",
  "@media only screen and (max-width: 500px)": {
    padding: "25px 12px",
    minWidth: '250px'
  },
  "@media only screen and (min-width:501px) (max-width: 768px)": {
    padding: "25px 20px",
    minWidth: '250px'
  },
  "@media only screen and (min-width:1025px)": {
    // minWidth:'750px'
  },
  "& .blocker_overflow": {
    maxHeight: '200px',
    overflow: 'auto'
  },
  "& .date_container": {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    columnGap: "14px",
    "& .default_label": {
      color: "#898989",
      fontSize: "16px",
      fontFamily: "Open sans, regular",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "-0.16px",
    },
    "@media only screen and (max-width: 500px)": {
      columnGap: "8px",
    }
  },
  "& .times_container": {
    display: "flex",
    justifyContent: "start",
    columnGap: "14px",
    margin: "12px 0 0",
    "& .default_label": {
      color: "#898989",
      fontSize: "16px",
      fontFamily: "Open sans, regular",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "-0.16px",
    },
    "@media only screen and (max-width: 500px)": {
      columnGap: "5px",
    }
  },
  "& input::-webkit-calendar-picker-indicator": {
    color: "rgba(0,0,0,0)",
    background: `url(${CalenderIcon}) no-repeat`,
    opacity: 1,
    marginTop: "0",
    cursor: "pointer",
    width: "20px",
    height: "20px",
    marginRight: '-6px'
  },
  "& .time_container": {
    display: "flex",
    flexWrap: 'wrap',
    gap: '12px',
    "@media only screen and (max-width: 500px)": {
      width: '410px',

    },
    "@media only screen and (min-width:501px) and (max-width: 1024px)": {
      width: '100%',

    },
    "& .time_label": {
      position: "absolute",
      top: "46%",
      fontSize: "16px",
      left: "67px",
      fontFamily: "Open sans, regular",
      fontWeight: 600,
      letterSpacing: "-0.16px",
      color: "#898989",
      zIndex: -1
    },

    "& .time_label_to": {
      left: "218px",
    }
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #D1D1D1",
  },

  "& .hidden": {
    position: "relative",
  },
  "& .date_filter_title, .time_filter_title": {
    fontSize: "20px",
    fontFamily: "Open sans, bold",
    fontWeight: 600,
    letterSpacing: "-0.2px",
    minWidth: '80px',
    "@media only screen and (max-width: 500px)": {
      fontSize: "12px",
    }
  },
  "& .time_filter_title": {
    paddingTop: '8px'
  },
  "& .date_label": {
    position: "absolute",
    top: "22%",
    fontSize: "16px",
    left: "13px",
    fontFamily: "Open sans, regular",
    fontWeight: 600,
    letterSpacing: "-0.16px",
    color: "#898989",
    zIndex: -1,
    "@media only screen and (max-width: 500px)": {
      fontSize: "13px",

    }



  },
  "& .hidden input::-webkit-datetime-edit-day-field, .hidden input::-webkit-datetime-edit-year-field, .hidden input::-webkit-datetime-edit-text, .hidden input::-webkit-datetime-edit-month-field": {
    display: "none",
    "-webkit-appearance": "none",
  },
  "& .hidden input::-moz-datetime-edit-day-field, .hidden input::-moz-datetime-edit-year-field, .hidden input::-moz-datetime-edit-text, .hidden input::-moz-datetime-edit-month-field": {
    display: "none !important",
    "-moz-appearance": "none !important",
  },
});

const TableHeadContainer = styled(TableHead)({
  "& >tr>th": {
    border: "0.5px solid #979797",
    padding: "22px 19px 9px 29px",
  },
});

const TableBodyContainer = styled(TableBody)({
  '& .grey_text>td': {
    color: '#898989'
  }
})

const ImageContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  gap: "9px",
  cursor: "pointer",
  width: "fit-content",
  marginLeft: '14px',
  color: "#0078a7",
  whiteSpace: 'nowrap',
  "@media only screen and (max-width: 500px)": {
    marginTop: '10px',
    fontSize: '14px',
    marginLeft: '5px'

  },
  "@media only screen and (min-width:501px) and (max-width: 768px)": {
    marginTop: '10px',
    fontSize: '14px',
    marginLeft: '0px'

  }
});

const ModalWrap = styled(Modal)({

  "& .confirmationModal": {
    padding: '70px !important',
    minWidth: '500px !important',
    "@media only screen and (max-width:500px)": {
      padding: '30px !important',
      minWidth: '300px !important',
      "& .userTitle": {
        fontSize: '20px'
      },

      "& .confirmationMsg": {
        fontSize: '16px'

      }


    },
    "@media only screen and (min-width:501px) and (max-width:768px)": {
      padding: '30px !important',
      minWidth: '400px !important',


    },
    "& .footer": {
      marginTop: '15px'
    }

  },
  "& .success_modal": {
    minWidth: '500px !important',
    padding: '70px !important',
    "@media only screen and (max-width:500px)": {
      minWidth: '300px !important',
      padding: '30px !important',
      "& .confirmationMsg": {
        fontSize: '16px'

      },
      "& .userTitle": {
        fontSize: '20px'
      },

    },
    "& .footer": {
      marginTop: '15px'
    },
    "@media only screen and (min-width:501px) and (max-width:768px)": {
      minWidth: '400px !important',
      padding: '30px !important',


    },


  },

  "& .add_edit_modal": {
    "@media only screen and (max-width: 1024px)": {
      minWidth: '550px !important',

    },
    "@media only screen and (max-width: 500px)": {
      padding: "25px 12px",
      minWidth: '250px !important'
    },
    "@media only screen and (min-width:501px)": {
      "& .addDateAndTimecontainer": {
        marginLeft: '90px !important'
      },
    },
    "@media only screen and (min-width:501px) and (max-width: 768px)": {
      "& .innerContainer": {
        width: '430px !important'
      },

      padding: "25px 20px",
      minWidth: '250px !important'
    },

    "& .addMoreTimeBtn": {
      "@media only screen and (max-width: 768px)": {
        marginLeft: '0 !important',
        marginTop: '10px'

      }
    }

  },
  "& .loader": {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
  }
});

const TableContent = styled(TableContainer)({
  margin: "46px 80px 60px 106px",
  boxShadow: "none",
  height: "237px",
  borderRadius: "0",
  overflowY: "auto",
  "@media only screen and (max-width: 1024px)": {
    marginRight: '2% !important',
    marginLeft: '2% !important',

  }
});


const TableCellContent = styled(TableCell)({
  color: "#1B1B1B",
  fontSize: "16px",
  fontFamily: "Open sans, regular",
  fontWeight: 700,
  padding: "18px 52px 18px 30px",
  whiteSpace: 'nowrap',
  "@media only screen and (max-width: 768px)": {
    fontSize: 'smaller',
    padding: '18px 30px 18px 30px'
  },
  "@media only screen and (max-width: 1024px)": {
    padding: '18px 30px 18px 30px'
  }

});

const AddButton = styled(Button)({
  background: "linear-gradient(0deg, #6D5B96 0%, #6D5B96 100%), #FE575A",
  width: "235px",
  height: "64px",
  color: "#FFF",
  textAlign: "center",
  fontSize: "20px",
  fontFamily: "Open sans",
  lineHeight: "21px",
  textTransform: "capitalize",
  borderRadius: "8px",

});

const TableHeader = styled(TableCell)({
  color: "#1B1B1B",
  fontSize: "20px",
  fontFamily: "Open sans, regular",
  fontWeight: 700,
  padding: "9px 52px 9px 15px",
  whiteSpace: 'nowrap',
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",

  }
});

const TableRowContent = styled(TableRow)({
  "& >td": {
    border: "0.5px solid #979797",
  },
});

const HeadingText = styled("div")({
  color: "rgba(36, 36, 36, 1)",
  textAlign: "center",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  fontFamily: "Open sans, bold",
  fontSize: "34px",
  fontWeight: "bold",
  "@media only screen and (max-width: 768px)": {
    fontSize: '20px',
    marginTop: '45px',
    textAlign: 'start',
    paddingLeft: '50px'

  },
  "@media only screen and (min-width:768px) and (max-width: 1024px)": {
    marginTop: '10px',
    position: 'unset',

  }
});

const BackArrowContent = styled("div")({
  color: "rgba(109, 91, 150, 1)",
  fontFamily: "Open sans, semibold",
  fontSize: "22px",
  paddingLeft: "5px",
  display: "flex",
  left: 0,
  top: 0,
  zIndex: 10,
  marginLeft: "8px",
  height: "22px",
  cursor: "pointer",
  fontWeight: "bold",
  position: "absolute",
  lineHeight: "22px",
  "& >img": {
    marginRight: "8px",
  },
  "@media only screen and (max-width: 1024px)": {
    position: 'unset'
  }

});

const InputField = styled(TextField)({
  "@media only screen and (max-width: 1024px)": {
    fontSize: 'small'
  }
})



// Customizable Area End
