import React from 'react'

import {
    Box,
    Typography,
    styled,
    FormControlLabel,
    Checkbox,
    Button,
    InputAdornment,
    OutlinedInput,
    IconButton
} from "@material-ui/core";
import SettingController from "./SettingController.web";
import CustomUserProfile from '../../../components/src/CustomUserProfiles.web';
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { eyeClose, eyeOpen } from './assets';
import { withTranslation } from "react-i18next";

const checkBoxtheme = () => ({
    borderRadius: 4,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.31), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:disabled ~ &': {

        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
})
const BpCheckIcon = styled('span')(checkBoxtheme);

const CheckedIcon = styled(BpCheckIcon)({
    backgroundColor: "green",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    }
});


export class ChangePasswordSetting extends SettingController {

    showSuccessPopUp = () => {
        return (
            <>
                {this.state.showSuccessBar &&
                    <Box style={{
                        background: "rgba(220, 251, 207, 1)",
                        padding: 9,
                        textAlign: "center",
                        width: "70%",
                        borderRadius: 7,
                        margin: "0 auto 30px",
                    }}
                    >
                        <Typography style={{
                            color: "rgba(40, 135, 0, 1)",
                            fontSize: 16,
                            fontFamily: "Open sans",
                            fontWeight: 600
                        }}>{this.props.t("Your password have been changed successfully")}</Typography>
                    </Box>
                }
            </>
        )
    }

    render() {
        const {
            showNewPassword,
            showOldPassword,
            showConfirmPassword,
            confirmPassword, oldPassword,
            newPassword, passwordErrors, errorMessage, showSuccessBar, userType, userData, initialCheckBoxRender } = this.state

        const { t } = this.props

        return (
            <>
                {userType == "teacher" ?
                    <CustomTeacherNavigationLayout data-test-id="teacher-navigation"
                        profileUrl={this.state.userData?.attributes?.image_link} screenName={""} params={""} navigation={this.props.navigation} /> :
                    <CustomStudentNavigationLayout data-test-id="student_navigation" screenName={""} params={""}
                        profileUrl={userData?.attributes?.image_link} navigation={this.props.navigation} />}
                <CustomUserProfile navigation={this.props.navigation} screenName={"ChangePassword"}
                    params={""} id={''} getAvatarURl={this.getProfileUrl} handleEditData={() => { }} name={this.userName}
                    bio={userType == "teacher" ? '' : `${t("Language learning")}: ${userData?.attributes?.language_option}`}
                    editBio={false} imageUrl={userData?.attributes?.image_link} imageSelector={() => { }} error={''}
                    imageChange={() => { }} userType={userType}>
                    <FormBlock>
                        <Box className='formHeading'>{t("Change Password")}</Box>
                        {this.showSuccessPopUp()}
                        <Box className='fieldContainer'>
                            <Typography className='formLabel' variant="subtitle1">{t("Old Password")}</Typography>
                            <div className='formField'>
                                <OutlinedInput
                                    data-test-id="oldPassword"
                                    type={showOldPassword ? "text" : "password"}
                                    name="oldPassword"
                                    value={oldPassword}
                                    onChange={this.handlePassword}
                                    inputProps={{ maxLength: 30 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.onShowOldPassword}
                                                edge="end"
                                            >
                                                {showOldPassword ? <img style={{ width: '20px' }}
                                                    src={eyeOpen} /> : <img style={{ width: '20px' }} src={eyeClose} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {passwordErrors?.oldPassword && (
                                    <Typography variant="subtitle1" component="div" style={{ margin: 0 }}>
                                        <ErrorBlock>
                                            <TextError>{errorMessage}</TextError>
                                        </ErrorBlock>
                                    </Typography>
                                )}
                            </div>
                        </Box>

                        <Box className='fieldContainer'>
                            <Typography className='formLabel' variant="subtitle1">{t("New Password")}</Typography>
                            <Box className='formField'>
                                <OutlinedInput
                                    data-test-id="newPassword"
                                    type={showNewPassword ? "text" : "password"}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={this.handlePassword}
                                    inputProps={{ maxLength: 30 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.onShowNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <img style={{ width: '20px' }} src={eyeOpen} />
                                                    : <img style={{ width: '20px' }} src={eyeClose} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Box>
                        <Box>
                            {initialCheckBoxRender &&
                                <div className='fieldContainer'>
                                    <Typography className='formLabel formLableHide' variant="subtitle1"></Typography>

                                    <div className='checkBoxConatiner formfield'>
                                        <FormControlLabel
                                            data-test-id="minimumChar"
                                            control={
                                                <Checkbox
                                                    disabled
                                                    style={{ pointerEvents: "none" }}
                                                    checkedIcon={<CheckedIcon />} icon={<BpCheckIcon />}
                                                    checked={this.handlePasswordChecked("minimumChar")}
                                                    value="white"
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        color: passwordErrors?.password?.minimumChar === "true" ? "#cd4871" : "#8b8b8b",
                                                        fontSize: "13px",
                                                        letterSpacing: "initial",
                                                        marginLeft: "-5px"
                                                    }}
                                                >
                                                    {t("Minimum 8 characters")}
                                                </Typography>
                                            }
                                        />

                                        <FormControlLabel
                                            data-test-id="caplitalChar"
                                            control={
                                                <Checkbox
                                                    disabled
                                                    style={{ pointerEvents: "none" }}
                                                    checked={this.handlePasswordChecked("caplitalChar")}
                                                    checkedIcon={<CheckedIcon />} icon={<BpCheckIcon />}
                                                    value="white"
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        color: (this.state.passwordErrors?.password?.caplitalChar) === "true" ? "#cd4871" : "#8b8b8b",
                                                        fontSize: "13px",
                                                        letterSpacing: "initial",
                                                        marginLeft: "-5px"
                                                    }}
                                                >
                                                    {t("One capital letter")}
                                                </Typography>
                                            }
                                        />

                                        <FormControlLabel
                                            data-test-id="specialSign"
                                            control={
                                                <Checkbox
                                                    disabled
                                                    style={{ pointerEvents: "none" }}
                                                    checked={this.handlePasswordChecked("specialSign")}
                                                    checkedIcon={<CheckedIcon />} icon={<BpCheckIcon />}
                                                    value="white"
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        color: (this.state.passwordErrors?.password?.specialSign) === "true" ? "#cd4871" : "#8b8b8b",
                                                        fontSize: "13px",
                                                        letterSpacing: "initial",
                                                        marginLeft: "-5px"
                                                    }}
                                                >
                                                    {t("Special sign")}
                                                </Typography>
                                            }
                                        />

                                        <FormControlLabel
                                            data-test-id="number"
                                            control={
                                                <Checkbox
                                                    disabled
                                                    style={{ pointerEvents: "none" }}
                                                    checked={this.handlePasswordChecked("number")}
                                                    checkedIcon={<CheckedIcon />} icon={<BpCheckIcon />}
                                                    value="white"
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        color: (this.state.passwordErrors?.password?.number) === "true" ? "#cd4871" : "#8b8b8b",
                                                        fontSize: "13px",
                                                        letterSpacing: "initial",
                                                        marginLeft: "-5px"
                                                    }}
                                                >
                                                    {t("One number")}
                                                </Typography>
                                            }
                                        />
                                    </div></div>
                            }
                        </Box>

                        <Box className='fieldContainer'>
                            <Typography className='formLabel' variant="subtitle1">{t("Re-enter Password")}</Typography>
                            <div className='formfield'>
                                <OutlinedInput
                                    data-test-id="confirmPassword"
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={this.handlePassword}
                                    inputProps={{ maxLength: 30 }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.onShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ?
                                                    <img style={{ width: '20px' }} src={eyeOpen} /> :
                                                    <img style={{ width: '20px' }} src={eyeClose} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {passwordErrors?.confirmPassword && (
                                    <Typography variant="subtitle1" component="div" style={{ margin: 0 }}>
                                        <ErrorBlock>
                                            <TextError>{t("Password does not match")}</TextError>
                                        </ErrorBlock>
                                    </Typography>
                                )}
                            </div>
                        </Box>
                        <Box style={{ textAlign: "center", marginTop: 40 }}>
                            <Button
                                data-test-id='on-change-password'
                                id='on-change-password'
                                className='buttonContainer'
                                onClick={() => this.onSavePassword()}
                            >
                                {t("Save Changes")}
                            </Button>
                        </Box>
                    </FormBlock>
                </CustomUserProfile>
            </>
        )
    }
}

export default withTranslation()(ChangePasswordSetting)

const FormBlock = styled("div")({
    "@media(max-width:1024px)": {
        border: "1px solid #80808036",
        padding: 20
    },
    "& .MuiOutlinedInput-root": {
        width: "100%",
        "@media(min-width: 601px) and (max-width:900px)": {
            width: "90%"
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: "14px 14px",
        width: "100%",
        display: "flex",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.16)",
    },
    "& .PrivateNotchedOutline-root-8": {
        borderRadius: 10,
    },
    "& .MuiIconButton-root": {
        borderRadius: 13
    },
    "& .formfield": {
        width: "70%",
        "@media(max-width:600px)": {
            width: "100%"
        }
    },
    "& .fieldContainer": {
        display: "flex",
        marginBottom: 20,
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '25px',
        "@media(max-width:600px)": {
            display: "block"
        }
    },
    '& .formLabel': {
        color: "black",
        fontSize: 20,
        width: '250px',
        fontWeight: 600,
        // whiteSpace:'nowrap',
        "@media(max-width:600px)": {
            width: '100%',
        }
    },
    '& .formLableHide':{
        "@media(max-width:600px)": {
            display:'none'
        }
    },
    '& .checkBoxConatiner': {
        width: '70%',
        marginTop: "-1%",
        "@media (min-width: 200px) and (max-width:600px)": {
            width: '100%',
        },
        "@media (min-width: 601px) and (max-width:900px)": {
            marginLeft: '4.5%',
        },
        "@media (min-width: 900px) and (max-width:1100px)": {
            marginLeft: '3.55%',
        },
    },
    '& .formHeading': {
        display: "none",
        "@media(max-width:600px)": {
            color: "black",
            fontSize: 25,
            fontWeight: 700,
            textAlign: "center",
            marginTop: "-10px",
            marginBottom: "30px",
            display: "block",
        },
        "@media(min-width: 601px) and (max-width:900px)": {
            color: "black",
            display: "block",
            fontSize: 25,
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "30px",
            fontWeight: 700,
        }
    },
    "& .buttonContainer": {
        background: "rgba(108, 90, 151, 1)",
        width: "100%",
        textAlign: "center",
        color: "white",
        height: 55,
        textTransform: "inherit",
        fontSize: 18,
        borderRadius: "8px",
        "@media(min-width: 601px) and (max-width:900px)": {
            width: "75%",
        }
    }
});

const TextError = styled("span")({
    fontSize: "14px",
    color: "#d23d69"
});

const ErrorBlock = styled("div")({
    display: "flex",
    gap: "6px",
    alignItems: "center"
});
