import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest, checkToken } from "../../../components/src/utils";
import i18n from "i18next"

// Customizable Area Start
import moment from 'moment-timezone'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  teacherClassList: any;
  open: boolean;
  open1: boolean;
  startDate: any;
  endDate: any;
  currentTab: string;
  openModal: boolean;
  currentOpenModal: string;
  cancelClassDes: string;
  requestClassList: any;
  previousClassList: any;
  modalDataId: string | number;
  isRejected: boolean;
  teacherName: string;
  openToaster: boolean;
  studentData: any;
  isFilterData: boolean;
  errorText: string;
  slider: number;
  smallScreen: boolean;
  isLoading: boolean;
  linkToaster: boolean;
  isUpdateAvailability: boolean;
  loading: boolean;
  DateTime: any,
  timezoneInfo: string,
  globalTimeZone:null | string 
  defaultTimezone: string,
  cuurentUserTimezone: string
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TeacherDashboardWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getRequestClass: string = ''
  acceptClass: string = ''
  rejectClass: string = ''
  cancelClassApi: string = ''
  getUpcomingClass: string = ''
  getPreviousClass: string = ''
  joinMeetingLinkApiId: string = ''
  getTeacherProfileDataApiId: string = ''
  getTimeSettingRequestId: string = ''
  setTeacherTimeApiId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    const currentTab = (typeof localStorage !== "undefined" && localStorage.getItem('DashboardTab')) || undefined;

    this.state = {
      // Customizable Area Start
      teacherClassList: [],
      startDate: '',
      endDate: '',
      currentTab: 'upcoming',
      open: false,
      open1: false,
      openModal: false,
      currentOpenModal: '',
      cancelClassDes: '',
      requestClassList: [],
      previousClassList: [],
      modalDataId: '',
      isRejected: false,
      teacherName: '',
      openToaster: false,
      studentData: [],
      isFilterData: false,
      errorText: '',
      slider: 0,
      smallScreen: window.innerWidth < 600,
      isLoading: false,
      linkToaster: false,
      isUpdateAvailability: false,
      loading: false,
      DateTime: {},
      timezoneInfo: "",
      defaultTimezone: "",
      globalTimeZone:"",
      cuurentUserTimezone : ""
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const user = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
    const data = user ? JSON.parse(user) : '';
    this.initialHandler()
    this.dashboardScreenResizer()
    this.setState({ teacherName: data?.attributes?.first_name, isUpdateAvailability: data?.attributes?.availability_created })
    const language = data.attributes?.display_language
    if (language) {
      localStorage.setItem('appLanguage', JSON.stringify(language))
      i18n.changeLanguage(language)
    } else {
      const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
      i18n.changeLanguage(appLanguage)
    }

    this.getTeacherProfileData()
    let localTimeZone: {
      attributes : {
        time_zone :string
      }
    } ;
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
    localTimeZone = JSON.parse(localStorageUser)
    this.setState({ cuurentUserTimezone: localTimeZone.attributes.time_zone});
    }
    
    // Customizable Area End
  }


  initialHandler = () => {
    this.getRequestClassAPI()
    this.getUpcomingClassAPI()
    this.previousClassAPI()
    this.getTimeZoneFormat()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.callAfterApicall(apiRequestCallId, responseJson)
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.checkSession('', AlertBodyMessage)
    }
    // Customizable Area End
  };

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.endDate != this.state.endDate) {
      if(this.state.currentTab === "previous"){
        this.previousClassAPI()
      }
      else{
      this.getUpcomingClassAPI()
      }
    }
    if (prevState.teacherClassList !== this.state.teacherClassList) {
      const session = sessionStorage.getItem("listId")
      const listItem = document.getElementById(`classlist-${session}`)
      if (listItem) {
        listItem.scrollIntoView({ block: "center", behavior: "smooth" });
        sessionStorage.removeItem('listId')
      }

    }
  }

  checkSession = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunc = {
      toggleToaster: this.context.toggleToaster,
      handleToaster: this.context.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunc, AlertBodyMessage, "teacher")
  }

  callAfterApicall = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getRequestClass:
        this.getRequestApiHandler(responseJson)
        break;

      case this.acceptClass:
        this.acceptClassApiHandler(responseJson)
        break;

      case this.rejectClass:
        this.rejectClassApiHandler(responseJson)
        break;

      case this.cancelClassApi:
        this.cancelClassApiHandler(responseJson)
        break;
      case this.getUpcomingClass:
        this.upcomingClassApiHandler(responseJson)
        break;
      case this.getPreviousClass:
        if (!responseJson.errors) {
          // Handle success for cancelClassApi
          this.handlePreviousClassApiResponse(responseJson)
        } else {
          // Handle error for cancelClassApi
          this.checkSession(responseJson, "")
        }
        this.setState({ isLoading: false })
        break;
      case this.joinMeetingLinkApiId:
        this.handleJoinMeetinglinkResponse(responseJson)
        break;
      case this.getTeacherProfileDataApiId:
        this.handleGetTeacherProfileResponse(responseJson)
        break;
      case this.getTimeSettingRequestId:
        this.getTeacherResponse(responseJson)
        break
      default:
        break;
    }

  }

  getTeacherResponse = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.error) {
      //success
      localStorage.setItem("selectedDateFormat", JSON.stringify(responseJson.date_format))
    }
    else { this.parseApiErrorResponse(responseJson) }
  }

  getRequestApiHandler = (responseJson: any) => {
    if (!responseJson.errors) {
      this.handleGetRequestClassApiResponse(responseJson);
    } else {
      // Handle error for getRequestClass
      this.checkSession(responseJson, "")
    }
  }

  acceptClassApiHandler = (responseJson: any) => {
    if (!responseJson.errors) {
      this.handleAccpetClassApiResponse(responseJson);
    } else {
      // Handle error for acceptClass
      this.setState({ isLoading: false });
      if (responseJson.errors[0].message) {
        this.setState({ errorText: responseJson.errors[0].message })
      }

      this.checkSession(responseJson, "")
    }
  }

  rejectClassApiHandler = (responseJson: any) => {
    if (!responseJson.errors) {
      // Handle success for rejectClass
      this.setState({ loading: false })
      this.handleRejectClassApiResponse(responseJson)
    } else {
      // Handle error for rejectClass
      this.checkSession(responseJson, "")
    }
  }

  cancelClassApiHandler = (responseJson: any) => {
    if (!responseJson.errors) {
      // Handle success for cancelClassApi
      this.setState({ loading: false })
      this.handleCancelClassApiResponse(responseJson)
    } else {
      // Handle error for cancelClassApi
      this.checkSession(responseJson, "")
    }
  }

  upcomingClassApiHandler = (responseJson: any) => {
    if (!responseJson.errors && responseJson.data && responseJson.data.length > 0) {
      // Handle success for cancelClassApi
      this.handleGetUpcomingClassApiResponse(responseJson)
    } else if (responseJson.data && responseJson.data.length === 0) {
      // Handle empty data array
      this.setState({ teacherClassList: [] })
    } else {
      // Handle error for cancelClassApi
      this.checkSession(responseJson, "")
    }
    this.setState({ isLoading: false })
  }


  handleJoinMeetinglinkResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson?.join_link) {
      window.open(responseJson?.join_link)
    } else if (responseJson && responseJson.error == "Meeting cant be started before meeting start time") {
      this.setState({ openToaster: true })
    }
    else {
      // Handle error for cancelClassApi
      this.checkSession(responseJson, "")
    }
  }

  handleGetTeacherProfileResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson?.data) {
      const appLanguage = responseJson.data?.attributes?.display_language
      const isUpdateAvailability = responseJson.data?.attributes?.availability_created
      localStorage.setItem('user', JSON.stringify(responseJson.data))
      localStorage.setItem('userGlobalZone', JSON.stringify(responseJson.data.attributes.time_zone))
      localStorage.setItem('appLanguage', JSON.stringify(appLanguage))
      i18n.changeLanguage(appLanguage)
      this.setState({ isUpdateAvailability })
      this.setState({defaultTimezone:responseJson.data.attributes.time_zone},()=>
      this.updateDateTime1())
    } else {
      this.checkSession(responseJson, "")
    }
  }

  getNoClassTextHeading = () => {
    return this.props.t(configJSON.createNewClassText)
  }
  handleSlider = (previousSlide: number, { currentSlide }: { currentSlide: number }) => {
    this.setState({ slider: currentSlide })
  }

  requestClassSliderSettings = {
    slidesToSlide: 1,
    renderButtonGroupOutside: true,
    afterChange: this.handleSlider,
    showDots: false,
    responsive: {
      superLargeDesktopStyle: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5.5
      },
      desktopStyle: {
        breakpoint: { max: 3000, min: 1500 },
        items: 3.5
      },
      laptopStyle: {
        breakpoint: { max: 1500, min: 1300 },
        items: 3.5
      },
      tabletStyle: {
        breakpoint: { max: 1300, min: 900 },
        items: 2.5
      },
      mobileStyle: {
        breakpoint: { max: 900, min: 0 },
        items: 1
      }
    },
    arrows: false,
  }

  //handle disable and enable state of slider button
  returnSliderItemIndex = () => {
    const displayWidth = window.innerWidth
    if (displayWidth <= 4000 && displayWidth >= 3000) {
      return this.checkListlength(-5.5, 5)
    } else if (displayWidth <= 3000 && displayWidth >= 1500) {
      return this.checkListlength(-3.5, 3)
    } else if (displayWidth <= 1500 && displayWidth >= 1300) {
      return this.checkListlength(-3.5, 3)
    } else if (displayWidth <= 1300 && displayWidth >= 900) {
      return this.checkListlength(-2.5, 2)
    } else {
      return -1;
    }
  }

  checkListlength = (value: number, listLength: number) => {
    if (listLength == 5) {
      return this.state.requestClassList.length > 5 ? value : -this.state.requestClassList.length
    } else if (listLength == 3) {
      return this.state.requestClassList.length > 3 ? value : -this.state.requestClassList.length
    } else if (listLength == 2) {
      return this.state.requestClassList.length > 2 ? value : -this.state.requestClassList.length
    } else {
      return this.state.requestClassList.length > 3 ? value : -this.state.requestClassList.length
    }
  }

  chagneTab = (tabName: string) => {
    localStorage.setItem('DashboardTab', tabName)
    if(this.state.currentTab !==   tabName){
      this.setState({startDate:""})
      this.setState({endDate:""})
    }
    this.setState({ currentTab: tabName })
    if (tabName == "availability" || tabName == "blocker") {
      this.props.navigation.navigate('SavedAvailability')
    }
  }

  addActiveTabClass = (tabName: string) => {
    if (tabName == this.state.currentTab) {
      return 'active'
    }
  }

  disablePreviousButton = () => {
    if (this.state.previousClassList.length <= 0) {
      return 'disable_tab'
    }
  }

  redirectToPrevioustab = () => {
    if (this.state.previousClassList?.length > 0) {
      this.chagneTab("previous")
    }
  }

  handleSelectDate = (fieldName: string, value: string) => {
    if (fieldName === 'From') {
      this.setState({ startDate: value, endDate: "" }, () =>
        this.setState({ open: false, open1: false }))
    } else {
      this.setState({ endDate: value }, () =>
        this.setState({ open: false, open1: false }))
    }
  }

  calenderClose = () => {
    this.setState({ open: false })
  }
  calenderOpen = () => {
    this.setState({ open: true })
  }

  calenderClose1 = () => {
    this.setState({ open1: false })
  }
  calenderOpen1 = () => {
    this.setState({ open1: true })
  }

  redirectToUpdateAvailability = () => {
    this.props.navigation.navigate('UpdateAvailability')
  }

  redirectToStudentProfile = (stuId: string | number, index?: number) => {
    if (this.state.currentOpenModal != 'studentList' && index) {
      sessionStorage.setItem('listId', index.toString())
    }
    this.props.navigation.history.push('Profile', { state: { dataId: stuId } })
  }

  redirectToEditClass = (classID: string | number) => {
    this.props.navigation.history.push('CreateClass', { state: { classId: classID } })
  }

  openTeacherDashboardModal = (modalName: string, dataId?: string | number | undefined, list?: any) => {
    this.setState({
      openModal: true, currentOpenModal: modalName,
      modalDataId: dataId || ''
    })

    if (modalName == "studentList" && dataId) {
      sessionStorage.setItem('listId', dataId.toString())
    }

    if (modalName == "studentList" && list && list.length > 0) {
      this.setState({ studentData: [...list] })
    }
  }

  closeModal = () => {
    this.setState({ openModal: false, cancelClassDes: '', modalDataId: '', isRejected: false, errorText: '' })
  }

  handleCancelClass = (value: string) => {
    this.setState({ cancelClassDes: value })
  }

  clearDateFilter = () => {
    this.setState({ startDate: '', endDate: '', isFilterData: false })
  }

  closeToaster = () => {
    this.setState({ openToaster: false })
  }



  //API handler
   convertToTimezone = (utcDate :string, utcTime:string, targetTimezone:string | null) => {
    const utcDateTime = moment.tz(`${utcDate} ${utcTime}`, 'DD/MM/YYYY hh:mm A', 'UTC');
    const targetDateTime = utcDateTime.clone().tz(targetTimezone ||"");
    return {
      date: targetDateTime.format('DD/MM/YYYY'),
      time: targetDateTime.format('hh:mm A'),
    };
  };
  

  getRequestClassAPI = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';

    this.getRequestClass = sendAPIRequest(configJSON.requestClassAPI, {
      headers: { 'token': newToken },
      method: 'GET',
    })
  }

  handleGetRequestClassApiResponse = (responseJson: any) => {
    const data = Object.keys(responseJson).length > 0 ? responseJson.data : [];
            const convertedData = data.map((item: any) => {
        const { class_date, start_time } = item.attributes;
       const newDate =  this.handleReplaceFormat(class_date);
        const { date, time } = this.convertToTimezone(newDate, start_time, this.state.cuurentUserTimezone);
        return {
          ...item,
          attributes: {
            ...item.attributes,
            class_date: this.handleDateReplaceFormat(date),
            start_time: time,
          },
        };
      });
      
      this.setState({ requestClassList: convertedData });
    };
  

  rejectClassAPI = (stuId: number | string) => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';
    if (!this.state.isRejected) {
      this.setState({ openModal: true, currentOpenModal: 'cancelClass', isRejected: true })
    } else {
      this.setState({ loading: true })
      this.rejectClass = sendAPIRequest(configJSON.rejectClassAPI + stuId + "&reject_description=" + JSON.stringify(this.state.cancelClassDes), {
        headers: { 'token': newToken, "Content-Type": "application/json" },
        method: 'POST',
      })
    }

  }

  handleRejectClassApiResponse = (responseJson: any) => {
    if (responseJson.message == configJSON.rejectClassSuccessMsg) {
      this.closeModal()
      this.getRequestClassAPI()
      this.previousClassAPI()
    }
  }

  cancelClassAPI = (cancelClassId: number | string) => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';
    this.setState({ loading: true })
    this.cancelClassApi = sendAPIRequest(configJSON.cancelClassAPI + cancelClassId + "&cancel_description=" + JSON.stringify(this.state.cancelClassDes), {
      headers: { 'token': newToken, "Content-Type": "application/json" },
      method: 'POST',
    })
  }

  handleCancelClassApiResponse = (responseJson: any) => {
    this.closeModal()
    if (responseJson.message == configJSON.cancelClassSuccessMsg) {
      this.getUpcomingClassAPI()
      this.previousClassAPI()
    }
  }

  acceptClassAPI = (stuId: number | string) => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';
    this.acceptClass = sendAPIRequest(configJSON.acceptClassAPI + stuId, {
      headers: { 'token': newToken, "Content-Type": "application/json" },
      method: 'POST',
    })
    this.setState({ isLoading: true });
  }

  handleAccpetClassApiResponse = (responseJson: any) => {
    if (responseJson.message == configJSON.acceptClassSuccessMsg) {
      this.closeModal()
      this.setState({ isLoading: false });
      this.getUpcomingClassAPI()
      this.getRequestClassAPI()
    }
  }

  getTimeZoneFormat = () => {


    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATEtimeSettingGetEndPoin
    this.getTimeSettingRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeSettingGetEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getClasName = (value: any) => value ? "start_field" : "start_empty"

  getFormatedDate = (date: any) => {
    if (date) {
      const newDate: any = new Date(date)
      if (!isNaN(newDate)) {
        return newDate.toISOString().split('T')[0]

      } else {
        return ""
      }

    } else {
      return ""
    }
  }

  getUpcomingClassAPI = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';
    this.setState({ isLoading: true })
    this.getUpcomingClass = sendAPIRequest(configJSON.upcomingClassAPI + "startdate=" + this.getFormatedDate(this.state.startDate) + "&enddate=" + this.getFormatedDate(this.state.endDate), {
      headers: { 'token': newToken, "Content-Type": "application/json" },
      method: 'GET',
    })
  }
  convertToTimezoneforUpcomingClass = (utcDate :string, utcTime:string, targetTimezone:string) => {
    const utcDateTime = moment.tz(`${utcDate} ${utcTime}`, 'DD/MM/YYYY hh:mm A', 'UTC');
    const targetDateTime = utcDateTime.clone().tz(targetTimezone || "UTC");
    return {
      date: targetDateTime.format('DD/MM/YYYY'),
      time: targetDateTime.format('hh:mm A'),
      day: targetDateTime.format('dddd')  
    };
  };

  convertClassList = (classList: any[], timezone: string) => {
    let localSelectedDateFormat = localStorage.getItem("selectedDateFormat")
    let selectedDateFormat = ""
    if (localSelectedDateFormat) {
      selectedDateFormat = JSON.parse(localSelectedDateFormat).replace('%d', 'DD')
        .replace('%m', 'MM')
        .replace('%Y', 'YYYY').replace("%b", "MMM").replace("%y", "YYYY").replace("%D", "DD").replace("%M", "MM").replace("%B", "MMM")
    }

  const newClassList = classList.map((item) => {
    const { class_date } = item.attributes;
    const newConvertDate = moment(class_date, selectedDateFormat).format('DD/MM/YYYY');
    return {
      ...item,
      attributes: {
        ...item.attributes,
        class_date: newConvertDate,
      }
    };
  });
    return newClassList.map((item: any) => {
      const { class_date, class_time } = item.attributes;
      const dateWithoutDay = class_date?.split(', ')[0];
      const convertedDateTime = this.convertToTimezoneforUpcomingClass(dateWithoutDay, class_time, timezone);
      const newClassDate = `${convertedDateTime.date}, ${convertedDateTime.day}`;
  
      return {
        ...item,
        attributes: {
          ...item.attributes,
          class_date: newClassDate,
          class_time: convertedDateTime.time
        }
      };
    }) || [];
  };
  handleGetUpcomingClassApiResponse = (responseJson: any) => {
    const classList = responseJson.data
    if (this.state.endDate) {
      if (classList.length === 0) {
        this.setState({ openToaster: true, isFilterData: true, teacherClassList: classList })
      } else {
        this.setState({ teacherClassList: classList })
      }
    } else {
      this.setState({ isFilterData: true, teacherClassList: classList })
    }
  }

  handleReplaceFormat = (date: string) => {
    const localFormat = localStorage.getItem("selectedDateFormat")
    let selectedDateFormat = ""
    if (localFormat) {
      selectedDateFormat = JSON.parse(localFormat).replace("%b %Y %d","YYYY MMM DD").replace('%d', 'DD')
        .replace('%m', 'MM')
        .replace('%Y', 'YYYY').replace("%b", "MMM").replace("%y", "YYYY").replace("%D", "DD").replace("%M", "MM").replace("%B", "MMM")
    }
    const newConvertDate = moment(date, selectedDateFormat).format('DD/MM/YYYY');
    return newConvertDate;
  };

  handleDateReplaceFormat = (date: string) => {
    const formate = localStorage.getItem("selectedDateFormat")
    let selectedFormated = ""
    if (formate) {
      selectedFormated = JSON.parse(formate).replace("%b %Y %d","YYYY MMM DD").replace('%d', 'DD')
        .replace('%m', 'MM')
        .replace('%Y', 'YYYY').replace("%b", "MMM").replace("%y", "YYYY").replace("%D", "DD").replace("%M", "MM").replace("%B", "MMM")
    }
    const newConvertDate = moment(date, 'DD/MM/YYYY').format( selectedFormated);
    return newConvertDate;
  };

  previousClassAPI = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';
    this.setState({ isLoading: true })
    this.getPreviousClass = sendAPIRequest(configJSON.previousClassAPI + "?startdate=" + this.getFormatedDate(this.state.startDate) + "&enddate=" + this.getFormatedDate(this.state.endDate), {
      headers: { 'token': newToken, "Content-Type": "application/json" },
      method: 'GET',
    })
  }

  handlePreviousClassApiResponse = (responseJson: any) => {
    const classList = responseJson.data
    const updatedClassList = this.convertClassList(classList, this.state.cuurentUserTimezone);
    this.setState({ previousClassList: classList })
  }

  dashboardScreenResizer = () => {
    window.addEventListener('resize', () => {
      const isSmall = window.innerWidth < 600
      this.setState({ smallScreen: isSmall })
    });
  }


  getTeacherProfileData = () => {
    const token = JSON.parse(localStorage.getItem('authToken') || '')
    const userData = JSON.parse(localStorage.getItem('user') || '')

    this.getTeacherProfileDataApiId = sendAPIRequest(configJSON.getTeacherProfileEndPoint + userData?.id, {
      headers: { 'token': token, "Content-Type": "application/json" },
      method: 'GET',
    })

  }

  onjoinMeetingLink = (id: string, studyFormat: string) => {
    const token = JSON.parse(localStorage.getItem('authToken') || "")
    const header = {
      "Content-Type": "application/json",
      "token": token
    };

    const user = JSON.parse(localStorage.getItem('user') || '')
    const data = {
      id: id,
      study_format: studyFormat,
      user: "Teacher"
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.joinMeetingLinkApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.joinLinkEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.joinLinkMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleStudentFormatCursor(studentCount: any) {
    return studentCount > 0
      ? { cursor: "pointer", color: "#0078a7" }
      : {
        cursor: "not-allowed",
        color: "#898989",
      }
  }
  handlePaddingTop(index: any) {
    return index != 0 ? '20px' : ''
  }

  updateDateTime1 = () => {
    const currentDate11 = new Date();
    const options: any = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const formatter1 = new Intl.DateTimeFormat(undefined, options);
    this.setState({ DateTime: formatter1.format(currentDate11) });
    const currentDate1 = moment();
    const timezoneName1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezoneInfo: `${timezoneName1}` }, () =>
      this.setTeacherTimeSetting1()
    )
  };

  setTeacherTimeSetting1 = () => {
    const { timezoneInfo, defaultTimezone } = this.state;
    let timeZoneString11 = timezoneInfo;
    let cleanedString11 = timeZoneString11?.replace(/\s?\(UTC\+00:00\)/, '');
    const data = {
      time_zone: defaultTimezone === "" ? cleanedString11 : defaultTimezone,//timezoneInfo,//currentTimezone?.value,
      date_format: "%d/%m/%y",
      time_format: "%I:%M %p"
    }
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.setTeacherTimeApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/students/set_timezone_date_time_formats"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  convertTime = (inputClassTime:string) => {
    //customize code start here

    // console.log(localStorage.getItem("userGlobalZone"))
    let userZone = localStorage.getItem("userGlobalZone")||"";
    userZone = userZone[0] === '"'?userZone.substring(1, userZone.length - 1):userZone
    // console.log(userZone)
    //Customize code ends here
    // let zone = localStorage.getItem("user");
    let userTimeZone = userZone  || Intl.DateTimeFormat().resolvedOptions().timeZone
    let timeFormat = "HH:mm";
    if (inputClassTime?.includes("AM") || inputClassTime?.includes("PM")) {
        timeFormat = "hh:mm A"; 
    }
    let utcTime = moment.utc(inputClassTime, timeFormat);
    let convertedTime = utcTime.tz(userTimeZone);
    let outputFormat = timeFormat.includes('A') ? "hh:mm A" : "HH:mm";
    return convertedTime.format(outputFormat);
  };

  convertTimeBlockerSetting = (time: string) => {
    let format = "";
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
    format = JSON.parse(localStorageUser).attributes.time_format
    }
    const newTime = moment(time, "h:mm A").format(format === "%I:%M %p" ? "h:mm A" : "HH:mm")
    return newTime
  };

  convertFormatClassDate = (date:String) => {
    let dateFormat = "";
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
      let dateFormatFromUser = JSON.parse(localStorageUser).attributes.date_format
      if(dateFormatFromUser === "%b %Y %d"){
        dateFormat = "YYYY MMM DD"
        const newConvertDate = moment(`${date}`,"MMM YYYY DD").format(dateFormat)
        return newConvertDate
      }
      else{
        return date
      }
    }
  };
  // Customizable Area End
}
