import React from "react";

// Customizable Area Start

import { Link } from 'react-router-dom';
import {
    Typography,
    InputAdornment,
    IconButton,
    OutlinedInput,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { imgLingoamo, imgFemale, imgStudent, eyeClose, eyeOpen } from "./assets";
import { Alert } from '@material-ui/lab';
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";
// Customizable Area End

import TeacherLoginController, {
    Props,
    configJSON,
} from "./TeacherLoginController";

export class TeacherLoginBlock extends TeacherLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t, i18n} = this.props
        return (
            <>
            <CustomLoader isLoading={this.state.isLoading}/>
                <MainWrapperDivision>
                    <LeftMainWrapperDivision>
                        <LogoWrapperDivision>
                            <LogoImgDivision src={imgLingoamo} alt="lingoamo_logo" />
                        </LogoWrapperDivision>

                        {this.state.accountNotActivated ?
                            <FormBodyDivisionWrapper>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', height: '300px', justifyContent: 'center', width: '600px', textAlign: 'center' }}>
                                    <FormHeaderTextDivision>{t("You are almost there")}!</FormHeaderTextDivision>
                                    <FormNormalTextDivision>{t("Please check your email to verify and complete your sign up. If you don't see it, you may need to check your spam folder. Still can't find the email")}? <span onClick={this.handleResendEmailCall} style={{ color: '#122BFF', fontSize: '20px', textDecoration: 'none', fontFamily: 'Open sans, regular', fontWeight: 500, cursor: 'pointer' }}>Resend Email</span></FormNormalTextDivision>
                                </div>
                            </FormBodyDivisionWrapper>
                            :
                            <FormWrapperDivision>
                                <FormHeaderWrapperDivision>
                                    <FormHeaderTextDivision>{t("Login")}</FormHeaderTextDivision>
                                    <FormNormalTextDivision>{t("Login to your account")} {this.state.isTeacherPage ? this.props.t("as a teacher") : ""}</FormNormalTextDivision>
                                </FormHeaderWrapperDivision>
                                <FormBodyDivision>
                                    <FormFieldsDivision>

                                        {this.state?.errorMessage &&
                                            <AlertBoxDivision icon={false} severity="error">
                                                {this.state.isDeactivated ? t('Your account is deactivated') : t('Sorry, unrecognised email or password')}
                                            </AlertBoxDivision>
                                        }

                                        {this.state?.isSuccess &&
                                            <SuccessBoxDivision icon={false} severity="error">
                                                {t("Your password have been changed successfully")}
                                            </SuccessBoxDivision>
                                        }

                                        <InputFieldDivision>
                                            <FieldLabel style={{ color: this.handleErrorColor(this.state?.formErrorData?.email), fontFamily: 'Open sans, regular' }} variant="subtitle1" >
                                                {t("Email")}
                                            </FieldLabel>
                                            <OutlinedInput
                                                style={{ borderRadius: '8px' }}
                                                type='email'
                                                name='email'
                                                data-test-id='email'
                                                placeholder={t('Enter email')}
                                                onChange={this.handleFormData}
                                                value={this.state?.formData?.email}
                                            />
                                        </InputFieldDivision>

                                        <InputFieldDivision>
                                            <FieldLabel style={{ color: this.handleErrorColor(this.state?.formErrorData?.password), fontFamily: 'Open sans, regular' }} variant="subtitle1">
                                                {t("Password")}
                                            </FieldLabel>
                                            <OutlinedInput
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                style={{ borderRadius: '8px' }}
                                                placeholder={t('Enter password')}
                                                name='password'
                                                data-test-id='password'
                                                value={this.state?.formData?.password}
                                                onChange={this.handleFormData}
                                                inputProps={{ maxLength: 30 }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => { this.setState({ ...this.state, showPassword: !this.state.showPassword }) }}
                                                            onMouseDown={() => { }}
                                                            edge="end"
                                                        >
                                                            {this.state.showPassword ? <img style={{ width: '20px' }} src={eyeOpen} /> : <img style={{ width: '20px' }} src={eyeClose} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <Typography style={{ textAlign: 'right' }} variant="subtitle1" component="div">
                                                <Link to={this.handleForgotPasswordPage()} style={{ fontFamily: 'Open sans, regular !important', color: '#242424', textDecoration: 'none' }}>{t("Forgot password")}?</Link>
                                            </Typography>
                                        </InputFieldDivision>

                                        <InputFieldDivision>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.formData.rememberPassword}
                                                        style={{ color: this.state.formData.rememberPassword ? '#1976d2' : 'rgba(0, 0, 0, 0.54)' }}
                                                        onChange={(e: any) => this.setState({ ...this.state, formData: { ...this.state.formData, rememberPassword: !this.state.formData.rememberPassword } })}
                                                    />
                                                }
                                                label={<Typography style={{ fontFamily: 'Open sans, regular !important', color: '#242424' }} variant="subtitle1" component="div">
                                                    {t("Remember me on this device")}
                                                </Typography>}
                                            />


                                        </InputFieldDivision>

                                        <InputFieldDivision>
                                            <SignUpButtonDivision data-test-id='logInButton' name='logInButton' onClick={this.handleSubmit}>{t("Login")}</SignUpButtonDivision>

                                            <Typography style={{ color: '#242424', fontFamily: 'Open sans, regular', textAlign: 'center', fontWeight: 'normal' }} variant="subtitle1" component="div">
                                                <span>{t("Don't have an account")} {this.state?.isTeacherPage}</span> &nbsp;
                                                <Link onClick={() => this.removeLocalStorageDataWhenClickOnSignUpBtn()} to={this.handleSignupLink(this.state.isTeacherPage)} style={{ color: '#122BFF', textDecoration: 'none', fontFamily: 'Open sans, regular', fontWeight: 'bold' }}>{t("Register here")}</Link>
                                            </Typography>
                                        </InputFieldDivision>

                                    </FormFieldsDivision>

                                </FormBodyDivision>
                            </FormWrapperDivision>
                        }
                    </LeftMainWrapperDivision>
                    <RightMainWrapperDivision>
                        <FemaleImgDivision src={this.state?.isTeacherPage ? imgFemale : imgStudent} style={{ filter: this.state?.isTeacherPage ? 'brightness(85%)' : 'brightness(120%)' }} alt="male_image" />
                        <RightTextWrapperDivision>
                            {this.hanldeRightText(this.state.isTeacherPage)}
                        </RightTextWrapperDivision>
                    </RightMainWrapperDivision>
                    <SmallScreenLogo>
                        <img src={imgLingoamo} alt="lingoamo_logo" />
                    </SmallScreenLogo>
                </MainWrapperDivision>

            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(TeacherLoginBlock)

const MainWrapperDivision = styled('div')({

    display: 'flex',
    width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    flexWrap:'wrap',
    "@media only screen and (max-width: 1024px)":{
        display:'flex',
        flexDirection:'column-reverse',
        width: '100%',
        fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    },
})

const FieldLabel = styled(Typography)({
    "@media only screen and (max-width: 1024px)":{
        display:'none'
    }
})

const LeftMainWrapperDivision = styled('div')({
    width: "50%",
    fontSize:'20px',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
        fontSize:'large'
    }
})

const RightMainWrapperDivision = styled('div')({
    width: "50%",
    position: 'relative',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
        display:'flex',
        justifyContent:'center'
    }
})

const LogoWrapperDivision = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: "50px 0px",
})

const SignUpButtonDivision = styled('button')({
    fontFamily: "Open sans, semibold",
    width: '100%',
    backgroundColor: '#6d5996',
    color: '#FFFFFF',
    height: '50px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer'
})

const LogoImgDivision = styled('img')({
    '@media only screen and (max-width: 1024px)':{
        display:'none'
    },
    width: "180px",
})

const SmallScreenLogo = styled('div')({
    display:'none',
    '@media only screen and (max-width: 1024px)':{
        display:'block',
        "& img":{
            height:'auto',
            width:'12%',
            marginLeft:'5%',
            margin: 20
        }
    }
})

const FemaleImgDivision = styled('img')({
    objectFit: "cover",
    width: 'calc(50% - 3px)',
    height: 'calc(100% - 2px)',
    position: 'fixed',
    top: 0,
    left: '50%',
    border: '2px solid #b7b7b7',
    objectPosition:'top',
    '@media only screen and (max-width: 768px)':{
        position: 'unset',
        width:'90%',
        height:'450px',
        maxHeight:'70vh',
        borderRadius:'10px',
        border:'none',
        objectFit:'cover',
        objectPosition:'top'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)':{
        position: 'unset',
        width:'90%',
        height:'calc(100vw * 1/2)',
        // maxHeight:'70vh',
        borderRadius:'10px',
        border:'none',
        objectFit:'cover',
        objectPosition:'top'
    },
})

const FormWrapperDivision = styled('div')({
    margin: '0 10% 80px',
    border: '1px solid #d5d5d5',
    borderRadius: '24px',
    padding: '7%',
    '@media only screen and (min-width: 768px) and (max-width: 1024px)':{
        // border:'none',
        padding:'50px',
        margin:'30px 10%'
    },
    '@media only screen and (max-width: 767px)':{
        border:'none',
        padding:'0px',
        margin:'10px 10%'
    }
})

const InputFieldDivision = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
})

const FormFieldsDivision = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
})

const RightTextWrapperDivision = styled('div')({
    top: '130px',
  left: '50%',
  color: 'white',
  width: '50%',
  position: 'fixed',
  fontSize: '40px',
  textAlign: 'center',
  fontFamily: 'Bangla MN',
  fontWeight: 'bold',
  display: "flex",
  justifyContent: "center",
  '@media only screen and (max-width: 1024px)':{
    top: '40px',
  left: '14%',
  color: 'white',
  width: '70%',
  position: 'absolute',
  fontSize: '30px',
  textAlign: 'center',
  fontFamily: 'Bangla MN',
  fontWeight: 'bold',
  display: "flex",
  justifyContent: "center",
  alignItems:'center',
  }
})

const FormHeaderWrapperDivision = styled('div')({
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '30px'
})

const FormHeaderTextDivision = styled('div')({
    fontSize: "28px",
    color: "#242424",
    fontFamily: 'Open sans, Bold',
    fontWeight: 'bold'
})

const FormNormalTextDivision = styled('div')({
    fontSize: '20px',
    color: '#242424',
    fontFamily: 'Open sans, regular'
})

const FormBodyDivision = styled('div')({
    '& .MuiOutlinedInput-input': {
        padding: '14px 14px',
        width: '100%',
        borderRadius: '8px'
    }
})

const FormBodyDivisionWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '150px'
})

const AlertBoxDivision = styled(Alert)({
    backgroundColor: '#ffbdcb',
    borderRadius: '8px',
    color: '#d1486a',
    "& >div": {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Open sans, semibold',
        fontSize: '18px',
        fontWeight: 'bold'
    }
})

const SuccessBoxDivision = styled(Alert)({
    backgroundColor: '#dcfbcf',
    color: '#288700',
    borderRadius: '8px',
    "& >div": {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Open sans, semibold',
        fontSize: '18px',
        fontWeight: 'bold'
    }
})
// Customizable Area End