// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  TextField,
  styled,
  Checkbox,
  Select,
  MenuItem,
  Box,
  PopoverOrigin,
  withStyles,
  Typography,
} from "@material-ui/core";
import { addLessonIcon, dob, downArrow2 } from "./assets";
import CustomDate from "../../../components/src/CustomDate.web";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "react-date-picker";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import { DeleteBlue } from "../../appointmentmanagement/src/assets";
import CreateClassController from "./CreateClassController.web";
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";

export const configJSON = require("./config");



interface Time {
  id: string;
  start_time: string;
  end_time: string;
  checkBoxes: any;
  occurs_on: any;
  fromTime: any;
  isSelectedTimeDisabled: any;
  checkBoxError: any
}

interface FormValues {
  language: string;
  title: string;
  category: string;
  courseDuration: string;
  languageType: string;
  startDate: string;
  time: Time[];
  languageLevel: string;
  description: string;
  learningResults: string;
}

const validationSchema = (t: any) => (Yup.object().shape({
  language: Yup.string().required(t("Required")),
  title: Yup.string().required(t("Required")),
  category: Yup.string().required(t("Required")),
  courseDuration: Yup.string().required(t("Required")),
  languageType: Yup.string().required(t("Required")),
  startDate: Yup.string().required(t("Required")).nullable(),
  time: Yup.array().of(
    Yup.object().shape({
      start_time: Yup.string().required(t("Start time is required")),
      end_time: Yup.string().required(t("End time is required")),
      occurs_on: Yup.array().min(1, t("At least one day must be selected")),
    })
  ),
  languageLevel: Yup.string().required(t("Required")),
  description: Yup.string().required(t("Required")),
  learningResults: Yup.string().required(t("Required")),
}));

export class CreateClass extends CreateClassController {
  weeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  IconComponent = (index: any, { ...rest }) => (
    <img
      data-test-id='img1'
      onClick={() => {
        index == 0 ? this.setState({ dropSix: true }) : this.setState({ dropSeven: true })
      }}
      {...rest}
      src={downArrow2}
      width={"18px"}
      height={"9px"}
      style={{ marginRight: "12px", cursor: "pointer", paddingTop: '8px' }}
    />
  );
  IconComponent1 = ({ ...rest }) => (
    <img
      data-test-id='img2'
      onClick={() => {
        this.setState({ dropTwo: true });
      }}
      {...rest}
      src={downArrow2}
      width={"18px"}
      height={"9px"}
      style={{ marginRight: "12px", cursor: "pointer", paddingTop: '8px' }}
    />
  );
  IconComponent2 = ({ ...rest }) => (
    <img
      data-test-id='img3'
      onClick={() => {
        this.setState({ dropThree: true });
      }}
      {...rest}
      src={downArrow2}
      width={"18px"}
      height={"9px"}
      style={{ marginRight: "12px", cursor: "pointer", paddingTop: '8px' }}
    />
  );
  IconComponent3 = () => (
    <img
      data-test-id='img4'
      onClick={() => {
        this.setState({ dropFour: true });
      }}
      src={downArrow2}
      width={"18px"}
      height={"9px"}
      style={{ marginRight: "12px", cursor: "pointer" }}
    />
  );
  IconComponent4 = ({ ...rest }) => (
    <img
      data-test-id='img5'
      onClick={() => {
        this.setState({ dropFive: true });
      }}
      {...rest}
      src={downArrow2}
      width={"18px"}
      height={"9px"}
      style={{ marginRight: "12px", cursor: "pointer", paddingTop: '8px' }}
    />
  );
  selectedCheckboxes: any = [];

  onSubmit = (values: any) => {
    if (!this.state.isError && !this.state.isErrorFor12h) {
      this.createNewClassCourse(values);
    }
  };

  handleCourseDurationFromSelect(e: any, handleChange: any, setFieldValue: any) {
    this.selectedCheckboxes = [];
    this.setState({ selectedCheckboxes: [] });
    handleChange(e);
    this.handleStartError(e.target.value);
    setFieldValue('time', [
      { ...this.initialTime }
    ])
  }

  render() {
    const { classes, t } = this.props;
    if (this.state.loading1) {
      return <div></div>;
    }
    return (
      <FormWrapper>
        <CustomLoader isLoading={this.state.loading} />
        <CustomTeacherNavigationLayout
          profileUrl={""}
          screenName={"CreateClass"}
          params={""}
          navigation={this.props.navigation}
        />
        <div style={{ textAlign: 'center' }}>
          <div className="form_title">{this.checkEditModeIsOpen(t("Create New Classes"), t(configJSON.editClassTitle))}</div>
        </div>
        <div className="form_subtitle">
          {this.checkEditModeIsOpen(t("Create and add descriptions for group class or courses"), t(configJSON.editClassSubTitle))}
        </div>
        <Container>
          <div style={{ marginTop: "25px" }}>
            <Formik
              enableReinitialize
              initialValues={this.state.defaultValues}
              validationSchema={validationSchema(t)}
              onSubmit={this.onSubmit}
              data-test-id="formik"
            >
              {({ values, handleChange, setFieldValue, setValues, errors, handleSubmit }) => (

                <form onSubmit={(event) => this.Submit(event, handleSubmit)}>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "170px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Language")}
                      </span>
                    </div>
                    <div className="text_field">
                      <CustomTextFieldLanguage
                        data-test-id="language"
                        variant="outlined"
                        name="language"
                        value={this.state.languageTaught?.replace("_", " ")}
                        disabled={true}
                      />

                      <ErrorMessage
                        name="language"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "170px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Title")}
                      </span>
                    </div>
                    <div className="text_field">
                      <CustomTextField
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                        name="title"
                        variant="outlined"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "170px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Category")}
                      </span>
                    </div>
                    <div className="text_field">
                      <CustomTextField
                        id="category"
                        disabled={true}
                        value={values.category}
                        data-test-id="category"
                        onChange={handleChange}
                        name="category"
                        variant="outlined"
                      />
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "175px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Course Duration")}
                      </span>
                    </div>
                    <div className="text_field">
                      <Select
                        id="courseDuration"
                        value={values.courseDuration ?? ''}
                        data-test-id="courseDuration"
                        variant="outlined"
                        name="courseDuration"
                        IconComponent={this.IconComponent1}
                        open={this.state.dropTwo}
                        onChange={(e: any) => {
                          this.handleCourseDurationFromSelect(e, handleChange, setFieldValue)
                        }
                        }
                        onOpen={() => this.setState({ dropTwo: true })}
                        onClose={() => this.setState({ dropTwo: false })}
                        style={{
                          "--radius": this.customBorderRadius(
                            this.state.dropTwo
                          ),
                        } as React.CSSProperties}
                        className="select_field"
                        MenuProps={{
                          classes: {
                            paper: classes.selectField
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },

                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={"6 hours"}>{t("6 hours")}</MenuItem>
                        <MenuItem
                          style={{
                            borderTop: "1px solid #D1D1D1",
                            borderBottom: "1px solid #D1D1D1",
                          }}
                          value={"12 hours"}
                        >
                          {t("12 hours")}
                        </MenuItem>
                        <MenuItem
                          style={{
                            borderTop: "1px solid #D1D1D1",
                            borderBottom: "1px solid #D1D1D1",
                          }}
                          value={"18 hours"}
                        >
                          {t("18 hours")}
                        </MenuItem>
                        <MenuItem value={"36 hours"}>{t("36 hours")}</MenuItem>
                      </Select>

                      <ErrorMessage
                        name="courseDuration"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "170px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Language Type")}
                      </span>
                    </div>
                    <div className="text_field">
                      <Select
                        id="languageType"
                        value={values.languageType}
                        data-test-id="languageType"
                        variant="outlined"
                        name="languageType"
                        IconComponent={this.IconComponent2}
                        onChange={handleChange}
                        open={this.state.dropThree}
                        onOpen={() => this.setState({ dropThree: true })}
                        onClose={() => this.setState({ dropThree: false })}
                        style={{
                          "--radius": this.customBorderRadius(
                            this.state.dropThree
                          ),
                        } as React.CSSProperties}
                        className="select_field"
                        MenuProps={{
                          classes: {
                            paper: classes.selectField
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },

                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem
                          style={{ borderBottom: "1px solid #D1D1D1" }}
                          value={"Everyday"}
                        >
                          {t("Everyday")}
                        </MenuItem>
                        <MenuItem value={"Business"}>{t("Business")}</MenuItem>
                      </Select>
                      <ErrorMessage
                        name="languageType"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "170px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Start Date")}
                      </span>
                    </div>

                    <div className="text_field">
                      <div className="select_field">
                        <CustomDate data-test-id='CustomDate' wrapperClass="hide_date_icon" value={values.startDate}
                          // minDate={new Date()} 
                          minDate={new Date().setDate(new Date().getDate() + 1)}
                          name='Start Date' open={this.state.open} openState={() => this.calenderClose()} calenderOpen={this.calenderOpen} selctDate={(name: string, value: any) => this.handleDateChange(value, setFieldValue)}
                          t={t} />

                       <ErrorMessage
                          name="startDate"
                          component="div"
                          className="createNewClassesError"
                        />
                       </div>

                    </div>
                  </div>
                  <FieldArray name="time">
                    {({
                      push,
                      remove,
                    }: {
                      push: (value: any) => void;
                      remove: (value: any) => void;
                    }) => (
                      <>
                        {values.time.map((time: any, index: any) => {
                          const isLastElement =
                            index === values.time.length - 1;
                          return (
                            <div key={index}>
                              <div
                                className="field_wrapper"
                              >
                                <div className="timeLable">
                                  <span
                                    className="filed_lable"
                                  >
                                    {t("Time")}
                                  </span>
                                </div>
                                <div className="time_text_field">
                                  <div className="time_field_wrapper">
                                    <Select
                                      id={`time[${index}].start_time`}
                                      variant="outlined"
                                      data-test-id="startTime"
                                      value={values.time[index].start_time}
                                      name={`time[${index}].start_time`}
                                      // open={index==0 ? this.state.dropSix: this.state.dropSeven}
                                      IconComponent={({ ...rest }) => this.IconComponent(index, { ...rest })}
                                      onChange={(e: any) => {
                                        handleChange(e);
                                        this.addTime(e, index, {
                                          setFieldValue,
                                        });
                                      }}
                                      onOpen={() => {

                                        index == 0 ? this.setState({ dropSix: true }) : this.setState({ dropSeven: true })
                                      }
                                      }
                                      onClose={() =>
                                        this.closeTimeDropdown(index)
                                      }
                                      style={{
                                        borderRadius: this.customBorderRadius(this.state.dropFour),
                                        fontSize: "20px",
                                        border: this.handleTimeBorder(time.isSelectedTimeDisabled),
                                      }}
                                      className="startTimeForCreateClass"
                                      MenuProps={{
                                        classes: {
                                          paper: classes.selectTimeField
                                        },
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },

                                        getContentAnchorEl: null,
                                      }}

                                    >
                                      {time?.fromTime?.map((time: any, localIndex: any) => {
                                        return (
                                          <MenuItem key={localIndex}
                                            data-test-id='fromTimeMenu'
                                            disabled={time.style}
                                            onClick={() => {
                                              this.setState({ isError: false });
                                              this.handleClearError(
                                                values,
                                                setFieldValue,
                                                index,
                                                time
                                              );
                                            }}
                                            style={{
                                              borderBottom: "1px solid #D1D1D1",
                                            }}
                                            value={time.time}
                                          >
                                            {time.time}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    {this.state.startTimeError && <Typography className="createNewClassesError timeError">{configJSON.selectTimeError}</Typography>}
                                    <ErrorMessage
                                      name={`time[${index}].start_time`}
                                      component="div"
                                      className="createNewClassesError"
                                    />
                                    
                                  </div>
                                  <div
                                    className="time_to_label"
                                  >
                                    <span className="to_date_label">{t("to")}</span>
                                  </div>
                                  <div className="time_field_wrapper">
                                    <CustomTextFieldEndTime
                                      id={`time[${index}].end_time`}
                                      data-test-id="endTime"
                                      value={values.time[index].end_time}
                                      name={`time[${index}].end_time`}
                                      disabled={true}
                                      onChange={handleChange}
                                      variant="outlined"
                                    />
                                    <ErrorMessage
                                      name={`time[${index}].end_time`}
                                      component="div"
                                      className="createNewClassesError"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="field_wrapper"
                              >
                                <div style={{ width: "170px" }}>
                                  <span
                                    className="filed_lable"
                                  >
                                    {t("Occurs on")}
                                  </span>
                                </div>
                                <div className="text_field">
                                  <div
                                    className="dat_select"
                                  >
                                    {time.checkBoxes
                                      .map((day: any, indx: any) => {
                                        const count = this.handleGetHoursCount(values)
                                        return (
                                          <div
                                            key={indx}
                                            className="dat_item"
                                          >
                                            <StyledCheckbox
                                              id={`time[${index}].occurs_on`}
                                              name={`time[${index}].occurs_on`}
                                              checked={day.checked}
                                              disabled={this.handleDisbale(time,count,day)}
                                              data-test-id="checkbox"
                                              onChange={(e: any) => {
                                                // this.logicFunction(day.id)

                                                this.setState({
                                                  dayId: day.id,
                                                });
                                                this.handleFieldChange(
                                                  index,
                                                  indx,
                                                  values,
                                                  day.id,
                                                  day.day,
                                                  e,

                                                  {
                                                    setFieldValue
                                                  }
                                                );
                                                // this.handleDateDisable(index, indx, values, { setFieldValue })
                                              }}
                                              value={day.day}
                                              size="medium"
                                            />
                                            <div>
                                              <span
                                                className="day_name"
                                              >
                                                {t(day.day)}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    <div className="add_lesson">{values.courseDuration !== "6 hours" && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        {index !== 0 && (
                                          <Button
                                            onClick={() => { remove(index), this.ErrorMsgHandle1 }}
                                            style={{
                                              color: "#0078a7",
                                              textTransform: "capitalize",
                                              marginRight: "10px",
                                              fontSize: "16px",
                                            }}
                                            startIcon={
                                              <img
                                                src={DeleteBlue}
                                                width={"22px"}
                                                height={"22px"}
                                              />
                                            }
                                          >
                                            {t("Delete")}
                                          </Button>
                                        )}
                                        {this.handleRemoveAddLessonsButton(
                                          isLastElement,
                                          values,
                                          time,

                                        ) && (
                                            <Button
                                              data-test-id="addLessons"
                                              onClick={() => {
                                                this.setState({ isErrorFor12h: false })
                                                setFieldValue(`time[${0}].checkBoxError`, false)
                                                push({
                                                  id: values.time.length + 1,
                                                  start_time: "",
                                                  end_time: "",
                                                  checkBoxes: [
                                                    {
                                                      id: "1",
                                                      day: "Mon",
                                                      checked: false,
                                                    },
                                                    {
                                                      id: "2",
                                                      day: "Tue",
                                                      checked: false,
                                                    },
                                                    {
                                                      id: "3",
                                                      day: "Wed",
                                                      checked: false,
                                                    },
                                                    {
                                                      id: "4",
                                                      day: "Thu",
                                                      checked: false,
                                                    },
                                                    {
                                                      id: "5",
                                                      day: "Fri",
                                                      checked: false,
                                                    },
                                                    {
                                                      id: "6",
                                                      day: "Sat",
                                                      checked: false,
                                                    },
                                                    {
                                                      id: "0",
                                                      day: "Sun",
                                                      checked: false,
                                                    },
                                                  ],
                                                  occurs_on: [],
                                                  fromTime:
                                                    this.getFromTimeList(),
                                                });
                                              }}
                                              startIcon={
                                                <img
                                                  src={addLessonIcon}
                                                  width={"22px"}
                                                  height={"22px"}
                                                />
                                              }
                                              style={{
                                                textTransform: "capitalize",
                                                color: "#0078a7",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {t("Add lessons")}
                                            </Button>
                                          )}
                                      </div>
                                    )}</div>
                                  </div>

                                  <ErrorMessage
                                    name={`time[${index}].occurs_on`}
                                    component="div"
                                    className="createNewClassesError"
                                  />
                                  {time.checkBoxError &&
                                    <div className="createNewClassesError">
                                      {this.ErrorMsgHandle(values)}
                                    </div>}
                                  {time.checkBoxError ? "" :
                                    <div className="createNewClassesError">
                                      {this.ErrorMsgHandle1(values)}
                                    </div>}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </FieldArray>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "170px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Language Level")}
                      </span>
                    </div>
                    <div className="text_field">
                      <Select
                        id="languageLevel"
                        value={values.languageLevel}
                        data-test-id="languageLevel"
                        onChange={handleChange}
                        open={this.state.dropFive}
                        onOpen={() => this.setState({ dropFive: true })}
                        onClose={() => this.setState({ dropFive: false })}
                        style={{
                          "--radius": this.customBorderRadius(
                            this.state.dropFive
                          ),
                        } as React.CSSProperties}
                        className="select_field"
                        variant="outlined"
                        name="languageLevel"
                        IconComponent={this.IconComponent4}
                        MenuProps={{
                          classes: {
                            paper: classes.selectField
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },

                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={"A1"}>A1</MenuItem>
                        <MenuItem
                          style={{
                            borderTop: "1px solid #D1D1D1",
                            borderBottom: "1px solid #D1D1D1",
                          }}
                          value={"A2"}
                        >
                          A2
                        </MenuItem>
                        <MenuItem value={"B1"}>B1</MenuItem>
                        <MenuItem
                          style={{
                            borderTop: "1px solid #D1D1D1",
                            borderBottom: "1px solid #D1D1D1",
                          }}
                          value={"B2"}
                        >
                          B2
                        </MenuItem>
                        <MenuItem value={"C1"}>C1</MenuItem>
                        <MenuItem
                          style={{
                            borderTop: "1px solid #D1D1D1",
                            borderBottom: "1px solid #D1D1D1",
                          }}
                          value={"C2"}
                        >
                          C2
                        </MenuItem>
                      </Select>
                      <ErrorMessage
                        name="languageLevel"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "176px" }}>
                      <span
                        className="filed_lable"
                      >
                        {t("Description")}
                      </span>
                    </div>
                    <div className="text_field">
                      <DescriptionTextField
                        id="description"
                        data-test-id="description"
                        name="description"
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        minRows={5}
                        value={values.description}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  <div
                    className="field_wrapper"
                  >
                    <div style={{ width: "176px" }}>
                      <span
                        className="filed_lable"
                      >
                        {this.props.t("Learning Results")}
                      </span>
                    </div>
                    <div className="text_field">
                      <CustomTextField
                        id="learningResults"
                        data-test-id="learningResults"
                        name="learningResults"
                        onChange={handleChange}
                        variant="outlined"
                        value={values.learningResults}
                      />
                      <ErrorMessage
                        name="learningResults"
                        component="div"
                        className="createNewClassesError"
                      />
                    </div>
                  </div>
                  {this.state.createCourseError && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "15px 0px 15px 128px",
                      }}
                    >
                      <div style={{ color: "red", fontSize: "22px" }}>
                        {this.state.createCourseError}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "30px 0px",
                    }}
                  >
                    <StyledButton3 data-test-id="saveForm" type="submit">
                      {t("Save")}
                    </StyledButton3>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Container>
      </FormWrapper>
    );
  }
}

const FormWrapper = styled(Box)({

  '& .form_title': {
    fontSize: '34px',
    fontWeight: 700,
    color: '#242424',
    margin: "35px 0px 25px 0px",
    "@media (max-width:1024px)": {
      fontSize: '26px',
      margin: '28px 0px 20px 0px'
    },

    "@media (max-width:600px)": {
      fontSize: '20px',
      margin: '28px 0px 20px 0px'
    }
  },

  '& .form_subtitle': {
    fontSize: "22px",
    textAlign: "center",
    fontWeight: 600,
    "@media (max-width:1024px)": {
      fontSize: '18px',
    },

    "@media (max-width:600px)": {
      fontSize: '16px',
      marginRight: '18%',
      marginLeft: '18%'
    }
  },

  '& .field_wrapper': {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "15px",
    alignItems: "center",

    "@media (max-width:1024px)": {
      flexDirection: 'column',
      alignItems: 'start',
      rowGap: '4px',
      marginRight: '35px',
      marginLeft: '35px',
      marginBottom: '16px'
    },

    "@media (max-width:600px)": {
      flexDirection: 'column',
      alignItems: 'start',
      rowGap: '4px',
      marginRight: '24px',
      marginLeft: '0px',
      marginBottom: '16px',
    }
  },

  '& .filed_lable': {
    fontSize: "22px",
    color: "#242424",
    fontWeight: 600,
    "@media (max-width:1024px)": {
      fontSize: '18px',
    },

    "@media (max-width:600px)": {
      fontSize: '16px',
    }
  },
  '& .text_field': {
    marginTop: "15px",
    alignItems: "flex-start",
    "@media (max-width:1024px)": {
      width: '100%',
      marginTop: "0px !important"
    }
  },
  "& .time_text_field": {
    display: "flex",
    marginTop: "30px",
    alignItems: "flex-start",
    "@media (max-width:1024px)": {
      marginTop: "0px !important",
      width: '100%'
    }
  },
  "& .timeLable": {
    width: "170px",
    "@media (max-width:1024px)": {
      width: "100%",
      marginTop: "15px"
    }
  },
  '& .to_date_label': {
    fontSize: "20px",
    marginTop: "10px",
    "@media (max-width:1024px)": {
      fontSize: '18px'
    },
    "@media (max-width:600px)": {
      fontSize: '14px'
    },
  },

  '& .select_field': {
    width: "539px",
    height: "48px",
    border: "0px solid #D1D1D1",
    borderRadius: 'var(--radius)',

    "& input": {
      fontSize: "20px !important",
      padding: '10px !important',
      fontWeight: 500

    },

    "& .calendarIcon": {
      right: '1% !important'
    },

    "@media (max-width:1024px)": {
      width: '100%'
    }
  },

  '& .dat_select': {
    display: "flex",
    justifyContent: "start",
    width: "546px",
    position: 'relative',
    flexWrap: 'wrap',
    "@media (max-width:1024px)": {
      width: 'auto',
      flexWrap: 'wrap',
      rowGap: '15px',
      columnGap: '12px'
    }
  },

  '& .dat_item': {
    display: "flex",
    alignItems: "center",
    "@media (max-width:1024px)": {
      width: '80px',
      columnGap: '5px'
    },

    "@media (max-width:600px)": {
      width: '65px',
      columnGap: '5px'
    }
  },

  '& .day_name': {
    color: "#434343",
    fontSize: "20px",
    "@media (max-width:600px)": {
      fontSize: '14px'
    }
  },

  '& .time_field_wrapper': {
    "& .timeError":{
      marginTop: "4px"
    },
    "@media (max-width:1024px)": {
      width: '47%'
    },
  },

  '& .time_to_label': {
    display: "flex",
    alignItems: "center",
    margin: "0px 16px",
    "@media (max-width:600px)": {
      width: '6%',
      textAlign: 'center'
    },
  },

  '& .startTimeForCreateClass': {
    "@media (max-width:1024px)": {
      width: '100%'
    },
  },

  '& .smallDate': {
    cursor: 'pointer',
    "@media (max-width:1024px)": {
      width: '100%',

      '& .react-date-picker__wrapper': {
        width: 'auto'
      }
    },
  },

  '& .add_lesson': {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: "#465FEB",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "41px",
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width:600px)": {
      fontSize: "14px",
      lineHeight: '21px',
      height: '30px',
      columnGap: '10px',
      position: 'relative',
      marginLeft: 'auto'
    },
  }
})

const CustomTextField = styled(TextField)({
  "@media (max-width:1024px)": {
    width: '100%'
  },
  "& .MuiInputBase-root": {
    width: "537px",
    height: "48px",
    border: "0px solid #D1D1D1",
    borderRadius: "10px",
    fontSize: "20px",
    color: "#434343",
    "@media (max-width:1024px)": {
      width: 'auto'
    },
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #D1D1D1",
  },
});
const selectStyles = {
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #D1D1D1",
  },
};
const CustomTextFieldLanguage = styled(TextField)({
  "@media (max-width:1024px)": {
    width: '100%'
  },
  "& .MuiInputBase-root": {
    width: "537px",
    height: "48px",
    border: "0px solid #D1D1D1",
    borderRadius: "10px",
    fontSize: "20px",
    color: "#434343",

    "@media (max-width:1024px)": {
      width: 'auto'
    },
  },
});
const CustomTextFieldEndTime = styled(TextField)({
  "@media (max-width:1024px)": {
    width: '100%'
  },
  "& .MuiInputBase-root": {
    width: "244px",
    height: "48px",
    border: "0px solid #D1D1D1",
    borderRadius: "10px",
    fontSize: "20px",
    "@media (max-width:1024px)": {
      width: 'auto'
    },
  },
});

const DescriptionTextField = styled(TextField)({
  "@media (max-width:1024px)": {
    width: '100%'
  },
  "& .MuiInputBase-root": {
    width: "537px",
    border: "0px solid #D1D1D1",
    borderRadius: "10px",
    fontSize: "20px",

    "@media (max-width:1024px)": {
      width: 'auto'
    },
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #D1D1D1",
  },
});
const StyledButton3 = styled(Button)({
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  textTransform: "capitalize",
  padding: "10px 65px",
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
});
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#D4D4D4", // Set the desired color here
  zIndex: 0,
  "@media (max-width:600px)": {
    padding: '0'
  },
  "&.Mui-checked": {
    color: "#2C8701", // Set the color for the checked state
  },
  "&.Mui-disabled": {
    cursor: "not-allowed",
  },
  "&.Mui-checked:hover": {
    backgroundColor: "transparent", // Set the background color for the checked state on hover
  },
  "& .MuiSvgIcon-root": {
    fontSize: "30px", // Increase the size of the checkbox icon
  },
}));
const menuProps = {
  menuStyle: {
    width: "242px",
    border: "1px solid #D1D1D1",
    boxShadow: "none",
    top: "284px",
    marginTop: "-4px",
    borderRadius: "0px 0px 10px 10px",
  },
};
const anchorStyle: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const styles = () => ({
  selectField: {
    width: '537px',
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    marginTop: '-6px',
    borderRadius: '0px 0px 10px 10px',
  },
  selectTimeField: {
    maxHeight: '200px',
    width: "242px",
    border: "1px solid #D1D1D1",
    boxShadow: "none",
    top: "284px",
    marginTop: "-6px",
    borderRadius: "0px 0px 10px 10px",
  },
  '@media (max-width: 1024px)': {
    selectField: {
      width: 'calc(100% - 120px) !important',
      minWidth: 'auto !important'
    },
    selectTimeField: {
      width: "calc( 50% - 86px)",
    }
  },
  '@media (max-width: 600px)': {
    selectField: {
      width: 'calc(100% - 59px) !important',
    },
    selectTimeField: {
      width: "calc( 50% - 56px)",
    }
  },
});

export default withTranslation()(withStyles(styles)(CreateClass))
// Customizable Area End
